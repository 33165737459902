import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as H from 'history';

import { ProjectModel } from 'models';
import * as Store from 'store';
import * as Projects from 'store/Projects';

import { PivotItem } from '@fluentui/react/lib/Pivot';
import { AppBackground } from 'common/Colors';
import TaskList from 'components/TaskList';
import TaskKanban from 'components/TaskKanban';
import StyledPageTitle from 'components/common/StyledPageTitle';
import { StyledPivot, StyledScrollablePivotContent } from 'components/common/StyledPivot';
import { StyledPage } from 'components/common/StyledPage';
import { StyledContent } from 'components/common/StyledContent';
import TasksPageCommands from 'components/tasks/TasksPageCommands';
import ProjectDescription from 'components/projects/ProjectDescription';
import ProjectStatus from 'components/projects/ProjectStatus';

interface Props {
    project: ProjectModel | null | undefined;
    tab: string | undefined;
}

enum DisplayType {
    List, Timeline, Kanban
}

interface State {
    displayType: DisplayType;
}

const handleLinkClick = (project: ProjectModel, item: PivotItem | undefined, history: H.History<any>) => {
    if (item) {
        history.replace(`/projects/${project.id}/${item.props.itemKey}`);
    }
}

const ProjectPage = (props: Readonly<Props & RouteComponentProps<{}>>) => {
    let [state, setState] = React.useState<State>({
        displayType: DisplayType.List
    })

    let project = props.project;
    if (project === undefined) return null;

    if (project === null) {
        // All tasks
        return <StyledPage backgroundColor={null}>
            <StyledPageTitle>All Tasks</StyledPageTitle>
            <TaskList />
        </StyledPage>
    }

    if (!project.id) {
        // Tasks in No Project
        return <StyledPage backgroundColor={null}>
            <StyledPageTitle>No Project</StyledPageTitle>
            <TaskList projectId={null} />
        </StyledPage>
    }

    return (
        <StyledPage backgroundColor={AppBackground}>
            <StyledPageTitle>{project.title}</StyledPageTitle>
            <StyledContent className="pivot-wrapper">
                <StyledPivot defaultSelectedKey={props.tab} onLinkClick={(item) => project && handleLinkClick(project, item, props.history)}>
                    <PivotItem headerText="DETAILS" className="page-tab" itemKey="details">
                        <StyledScrollablePivotContent>
                            <div className="ms-Grid">
                                <ProjectDescription icon={project.icon} color={project.color} description={project.title} onDescriptionChange={() => { }} />
                                <ProjectStatus status={project.status} onStatusChange={() => { }} />
                            </div>
                        </StyledScrollablePivotContent>
                    </PivotItem>
                    <PivotItem headerText="TASKS" className="page-tab" itemKey="tasks">
                        <TasksPageCommands
                            projectId={project.id}
                            onShowList={() => setState({ displayType: DisplayType.List })}
                            onShowTimeline={() => setState({ displayType: DisplayType.Timeline })}
                            onShowKanban={() => setState({ displayType: DisplayType.Kanban })} 
                        />
                        {state.displayType === DisplayType.List && <TaskList projectId={project.id} />}
                        {state.displayType === DisplayType.Kanban && <TaskKanban projectId={project.id} />}
                    </PivotItem>
                </StyledPivot>
            </StyledContent>
        </StyledPage>
    )
}

interface RouteProps {
    projectId: string | undefined;
    tab: string | undefined;
}

export default connect((state: Store.ApplicationState, props: RouteComponentProps<RouteProps>) => {
    if (!props.match.params.projectId) {
        return { project: null };
    }
    if (props.match.params.projectId === '~') {
        return { project: {} as ProjectModel }
    }
    let projects = Projects.getProjects(state.projects);
    return {
        project: projects ? projects.find(o => o.id === props.match.params.projectId) : undefined,
        tab: props.match.params.tab
    }
})(ProjectPage);