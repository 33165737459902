import * as React from 'react';
import styled from 'styled-components';
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

interface Props {
    label: string;
}

export default (props: Readonly<Props>) => (
    <StyledPage>
        <StyledContent>
            <Spinner size={SpinnerSize.large} label={props.label} />
        </StyledContent>
    </StyledPage>
)

const StyledPage = styled.div`
width: 100%;
height: 100%;
text-align: center;
`;

const StyledContent = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translateX(-50%) translateY(-50%);
`