import { TaskModel, TaskStage, TaskStageModel, ProjectModel, TaskGroupModel, MilestoneModel } from 'models';
import { Order } from 'store/Tasks';
import { UserInfoState } from 'store/Login';

const compareOptionalDates = (a: Date | undefined, b: Date | undefined, order: 1 | -1) => {
    if (!a && !!b) return order;
    if (!!a && !b) return -order;
    if (!!a && !!b) return compareDates(a, b, order);
    return 0;
}

const compareDates = (a: Date, b: Date, order: number) => {
    return a === b ? 0 : a < b ? -order : order;
}

export const compareTasks =
    (a: TaskModel, b: TaskModel, orders: { [prop: string]: Order },
        userInfo: UserInfoState,
        customStages: TaskStageModel[] | null,
        projects: { [id: string]: ProjectModel } | null,
        taskGroups: { [id: string]: TaskGroupModel } | null,
        milestones: { [id: string]: MilestoneModel } | null
    ) => {
        for (let prop of Object.keys(orders)) {
            let order: 1 | -1 = orders[prop] === "ASC" ? 1 : -1;
            switch (prop) {
                case 'me':
                    let meInA = !!a.assignees && !!a.assignees.find(o => o.userId === userInfo.id);
                    let meInB = !!b.assignees && !!b.assignees.find(o => o.userId === userInfo.id);
                    if (meInA && !meInB) return -order;
                    if (!meInA && meInB) return order;
                    if (meInA && meInB && a.assignees && b.assignees) {
                        if (a.assignees.length < b.assignees.length) return -order;
                        if (a.assignees.length > b.assignees.length) return order;
                    }
                    break;

                case 'endTime':
                    {
                        let result = compareOptionalDates(a.endTime, b.endTime, order);
                        if (result !== 0) return result;
                    }
                    break;

                case 'startTime':
                    {
                        let result = compareOptionalDates(a.startTime, b.startTime, order);
                        if (result !== 0) return result;
                    }
                    break;

                case 'stage':
                    if (a.taskStage === TaskStage.Custom && b.taskStage === TaskStage.Custom) {
                        if (customStages) {
                            let aStage = customStages.find(o => o.id === a.customStageId);
                            let bStage = customStages.find(o => o.id === b.customStageId);
                            if (aStage && bStage) {
                                if (aStage.id < bStage.id) return -order;
                                if (aStage.id > bStage.id) return order;
                            }
                        }
                    } else {
                        if (a.taskStage < b.taskStage) return -order;
                        if (a.taskStage > b.taskStage) return order;
                    }
                    break;

                case 'assignee':
                    break;

                case 'project':
                    if (!a.projectId && !!b.projectId) return -order;
                    if (!!a.projectId && !b.projectId) return order;
                    if (a.projectId && b.projectId &&
                        projects && projects[a.projectId] && projects[b.projectId]) {
                        if (projects[a.projectId].title < projects[b.projectId].title) return -order;
                        if (projects[a.projectId].title > projects[b.projectId].title) return order;
                    }
                    if (a.projectId && b.projectId && a.projectId === b.projectId) {
                        if (!a.taskGroupId && !!b.taskGroupId) return -order;
                        if (!!a.taskGroupId && !b.taskGroupId) return order;
                        if (a.taskGroupId && b.taskGroupId &&
                            taskGroups && taskGroups[a.taskGroupId] && taskGroups[b.taskGroupId]) {
                            if (taskGroups[a.taskGroupId].name < taskGroups[b.taskGroupId].name) return -order;
                            if (taskGroups[a.taskGroupId].name > taskGroups[b.taskGroupId].name) return order;
                        }
                    }
                    break;

                case 'milestone':
                    if (!a.milestoneId && !!b.milestoneId) return -order;
                    if (!!a.milestoneId && !b.milestoneId) return order;
                    if (a.milestoneId && b.milestoneId &&
                        milestones && milestones[a.milestoneId] && milestones[b.milestoneId]) {
                        if (milestones[a.milestoneId].name < milestones[b.milestoneId].name) return -order;
                        if (milestones[a.milestoneId].name > milestones[b.milestoneId].name) return order;
                    }
                    break;

                case 'priority':
                    if (a.priority < b.priority) return -order;
                    if (a.priority > b.priority) return order;
                    break;

                case 'description':
                    if (a.description < b.description) return -order;
                    if (a.description > b.description) return order;
                    break;
            }
        }

        return compareDates(a.addedDateTime, b.addedDateTime, 1);
    }