import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as uuid from 'uuid';

import TaskListItem from './TaskListItem';
import LoadingPageContent from './common/LoadingPageContent';

import { TaskModel, TaskStage, TaskPriority } from 'models';
import * as Store from 'store';
import * as Tasks from 'store/Tasks';
import * as TaskGroups from 'store/TaskGroups';
import * as Milestones from 'store/Milestones';
import { IconButton } from '@fluentui/react/lib/Button';

interface State {
    newTaskDescription: string;
}

interface OwnProps {
    tasks: TaskModel[] | null;
}

type Props = OwnProps
    & typeof Tasks.actionCreators
    & typeof TaskGroups.actionCreators
    & typeof Milestones.actionCreators
    & ComponentProps;

class TaskList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newTaskDescription: ''
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.projectId !== prevProps.projectId)
            this.fetchData();
    }

    fetchData() {
        this.props.requestSortOrders(this.props.projectId);
        this.props.requestTaskList(this.props.projectId, false);
        this.props.projectId && this.props.requestTaskGroups(this.props.projectId, false);
        this.props.projectId && this.props.requestMilestones(this.props.projectId, false);
    }

    handleAddNewTask() {
        if (this.state.newTaskDescription) {
            this.props.addTaskModel({
                id: uuid.v4(),
                description: this.state.newTaskDescription,
                taskStage: TaskStage.Todo,
                priority: TaskPriority.Mid,
                isDeleted: false,
                addedDateTime: new Date(),
                updatedDateTime: new Date(),
                projectId: this.props.projectId || undefined
            });
            this.setState({ newTaskDescription: '' });
        }
    }

    public render() {
        if (!this.props.tasks) {
            return <LoadingPageContent label="Loading your tasks..." />;
        }

        let tasks = this.props.tasks;

        return (
            <StyledWrapper>
                <StyledNewTask>
                    <form onSubmit={(e) => { e.preventDefault(); this.handleAddNewTask(); }}>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Create a new task"
                                value={this.state.newTaskDescription}
                                onChange={e => this.setState({ newTaskDescription: e.target.value })} />
                            <span className="input-group-btn">
                                <StyledNewTaskButton iconProps={{ iconName: 'Add' }} onClick={() => this.handleAddNewTask()} />
                            </span>
                        </div>
                    </form>
                </StyledNewTask>
                <StyledTaskListWrapper>
                    <AutoSizer>
                        {({ height, width }) => (
                            <StyledTaskList itemCount={tasks.length} itemSize={47} height={height} width={width}>
                                {({ index, style }) => {
                                    let task = tasks[index];
                                    return <TaskListItem key={task.id} taskId={task.id} index={index} style={style} />;
                                }}
                            </StyledTaskList>
                        )}
                    </AutoSizer>
                </StyledTaskListWrapper>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled.div`
display: flex;
flex-direction: column;
flex: 1 1 auto;
`

const StyledNewTask = styled.div`
padding: 0 15px 4px 15px;
`

const StyledNewTaskButton = styled(IconButton)`
width: 34px;
height: 34px;
background-color: lightgray;
`

const StyledTaskListWrapper = styled.div`
flex: 1 1 auto;
`

const StyledTaskList = styled(FixedSizeList)`
list-style: none;
padding: 0;
width: 100%;
overflow: hidden;
`;

interface ComponentProps {
    projectId?: string | null;
}

export default connect(
    (state: Store.ApplicationState, ownProps: ComponentProps | undefined) => {
        var result: OwnProps = {
            tasks: Tasks.getTasksByProjectId(state, ownProps ? ownProps.projectId : undefined)
        }
        return result;
    },
    dispatch => bindActionCreators({
        ...Tasks.actionCreators,
        ...TaskGroups.actionCreators,
        ...Milestones.actionCreators
    }, dispatch)
)(TaskList);