import * as React from 'react';
import { ProjectModel, TaskGroupModel } from 'models';
import Colors from 'common/Colors';
import FontIcons from 'common/FontIcons';
import { DefaultButton } from '@fluentui/react/lib/Button';
import SectionTitle from 'components/common/SectionTitle';

interface TaskPageProjectAndGroupProps {
    project: ProjectModel | null;
    taskGroup: TaskGroupModel | null;
}

export default (props: Readonly<TaskPageProjectAndGroupProps>) => (
    <React.Fragment>
        <SectionTitle>PROJECT</SectionTitle>
        {props.project ?
            <DefaultButton className="btn-task-project-task-group" style={{ color: props.project.color || Colors.colorApp }}>
                <span className="task-project-icon" style={{ background: props.project.color || Colors.colorApp }}>{props.project.icon || FontIcons.iconProject}</span>
                <span>{props.project.title}</span>
                {props.taskGroup &&
                    <span>
                        <span className="task-project-task-group-chevron">{FontIcons.iconChevronRight}</span>
                        {props.taskGroup.name}
                    </span>
                }
            </DefaultButton>
            :
            <DefaultButton className="btn-with-icon-item-page text-align-left">
                <span className="btn-with-icon-item-page-icon">{FontIcons.iconAdd}</span>
                Add to project
                </DefaultButton>
        }
    </React.Fragment>
)