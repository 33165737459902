import { Action, Reducer } from 'redux';
import { container } from "../../inversify.config";
import { AppThunkAction } from './../';

import * as ActionTypes from '../../common/ActionTypes';
import DateStatusHelper from '../../helpers/DateStatusHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import { ProjectStudioAPI } from '../../logics/ProjectStudioAPI';
import { ChecklistItemModel, DateStatus, MilestoneModel, ModelConstants, ProjectModel, TaskGroupModel, TaskModel, TaskPriority, TaskStage, TaskStageModel, UserModel } from '../../models';
import DateTimeWithAccuracy from '../../models/DateTimeWithAccuracy';
import { DateTimeWithAccuracyType } from '../../models/DateTimeWithAccuracy';
import * as StoreLogin from '../Login';
import * as StoreMilestones from '../Milestones';
import * as StoreProjects from '../Projects';
import * as StoreTasks from '../Tasks';
import * as StoreTaskGroups from '../TaskGroups';
import * as StoreUsers from '../Users';

export interface TaskPageState {
    task: TaskModel | null;

    project: ProjectModel | null;
    taskGroup: TaskGroupModel | null;
    milestone: MilestoneModel | null;
    users: { [userId: string]: UserModel };

    startDate: DateTimeWithAccuracy | null;
    endDate: DateTimeWithAccuracy | null;
    durationSeconds: number | null;
    durationPercent: number;
    isStartDateCalculated: boolean;
    isEndDateCalculated: boolean;
    isDurationCalculated: boolean;
    taskDateStatus: DateStatus;
    dateTimeDescription: string;

    availableStages: TaskStageModel[];

    isNew: boolean;
    isEditing: boolean;
    isLoading: boolean;
    isError: boolean;
    isErrorDialogVisible: boolean;

    isResetDialogVisible: boolean;
    isRemoveDialogVisible: boolean;

    commands: TaskPageCommandsState;
    policy: TaskPagePolicyState;
}

export interface TaskPageCommandsState {
    isMarkAsDoneVisible: boolean;
}

export interface TaskPagePolicyState {
    canCreateTask: boolean;
    canUpdate: boolean;
    canDelete: boolean;
}

// ----------------
// ACTIONS

interface RequestTaskAction {
    type: typeof ActionTypes.TASK_PAGE_TASK_REQUEST;
    taskId: string;
}

interface ReceiveTaskAction {
    type: typeof ActionTypes.TASK_PAGE_TASK_RECEIVE;
    taskId: string;
    task: TaskModel | null;
}

interface SetDescriptionAction {
    type: typeof ActionTypes.TASK_PAGE_SET_DESCRIPTION;
    taskId: string;
    description: string;
}

interface SetStageAction {
    type: typeof ActionTypes.TASK_PAGE_SET_STAGE;
    taskId: string;
    stage: TaskStage;
    customStageId: number | null;
}

interface SetPriorityAction {
    type: typeof ActionTypes.TASK_PAGE_SET_PRIORITY;
    taskId: string;
    priority: TaskPriority;
}

interface RemoveAssigneeAction {
    type: typeof ActionTypes.TASK_PAGE_REMOVE_ASSIGNEE;
    taskId: string;
    assigneeId: string;
}

interface AddTagAction {
    type: typeof ActionTypes.TASK_PAGE_ADD_TAG;
    taskId: string;
    tag: string | null;
}

interface RemoveTagAction {
    type: typeof ActionTypes.TASK_PAGE_REMOVE_TAG;
    taskId: string;
    tag: string | null;
}

interface AddSubTaskAction {
    type: typeof ActionTypes.TASK_PAGE_ADD_SUBTASK;
    taskId: string;
    subTask: string | null;
}

interface RemoveSubTaskAction {
    type: typeof ActionTypes.TASK_PAGE_REMOVE_SUBTASK;
    taskId: string;
    subTask: ChecklistItemModel;
}

interface UpdateSubTaskDescriptionAction {
    type: typeof ActionTypes.TASK_PAGE_UPDATE_SUBTASK_DESCRIPTION;
    taskId: string;
    subTask: ChecklistItemModel;
    newSubTaskDescription: string | null;
}

interface ToggleSubTaskIsCompletedAction {
    type: typeof ActionTypes.TASK_PAGE_TOGGLE_SUBTASK_ISCOMPLETED;
    taskId: string;
    subTask: ChecklistItemModel;
}

interface UpdateNotesAction {
    type: typeof ActionTypes.TASK_PAGE_UPDATE_NOTES;
    taskId: string;
    notes: string | null;
}

interface SetProjectDetailsAction {
    type: typeof ActionTypes.TASK_PAGE_PROJECT_SET_DETAILS;
    taskId: string;
    projectId: string | null;
    project: ProjectModel | null;
}

interface SetTaskGroupDetailsAction {
    type: typeof ActionTypes.TASK_PAGE_TASKGROUP_SET_DETAILS;
    taskId: string;
    taskGroupId: string | null;
    taskGroup: TaskGroupModel | null;
}

interface SetMilestoneDetailsAction {
    type: typeof ActionTypes.TASK_PAGE_MILESTONE_SET_DETAILS;
    taskId: string;
    milestoneId: string | null;
    milestone: MilestoneModel | null;
}

interface SetUserDetailsAction {
    type: typeof ActionTypes.TASK_PAGE_USER_SET_DETAILS;
    taskId: string;
    userId: string;
    user: UserModel;
}

interface InitializeUsersAction {
    type: typeof ActionTypes.TASK_PAGE_USERS_INITIALIZE;
    taskId: string;
    userIds: string[];
}

interface PageLoadedAction {
    type: typeof ActionTypes.TASK_PAGE_PAGE_LOADED;
    taskId: string | undefined;
}

interface CloseErrorDialogAction {
    type: typeof ActionTypes.TASK_PAGE_CLOSE_ERROR_DIALOG;
    taskId: string;
}

interface ShowRemoveDialogAction {
    type: typeof ActionTypes.TASK_PAGE_DIALOG_SHOW_REMOVE;
    taskId: string;
}

interface CloseRemoveDialogAction {
    type: typeof ActionTypes.TASK_PAGE_DIALOG_CLOSE_REMOVE;
    taskId: string;
}

interface ShowResetDialogAction {
    type: typeof ActionTypes.TASK_PAGE_DIALOG_SHOW_RESET;
    taskId: string;
}

interface CloseResetDialogAction {
    type: typeof ActionTypes.TASK_PAGE_DIALOG_CLOSE_RESET;
    taskId: string;
}

type KnownAction = RequestTaskAction | ReceiveTaskAction
    | SetDescriptionAction | SetStageAction | SetPriorityAction | RemoveAssigneeAction
    | AddTagAction | RemoveTagAction
    | AddSubTaskAction | RemoveSubTaskAction | UpdateSubTaskDescriptionAction | ToggleSubTaskIsCompletedAction
    | UpdateNotesAction
    | SetProjectDetailsAction | SetTaskGroupDetailsAction | SetMilestoneDetailsAction | SetUserDetailsAction | InitializeUsersAction
    | PageLoadedAction | CloseErrorDialogAction
    | ShowRemoveDialogAction | CloseRemoveDialogAction| ShowResetDialogAction | CloseResetDialogAction
    | StoreLogin.RequestLoginAction
    | StoreMilestones.RequestMilestoneAction | StoreMilestones.ReceiveMilestoneAction
    | StoreProjects.RequestProjectAction | StoreProjects.ReceiveProjectAction
    | StoreTasks.ReceiveTaskAction
    | StoreTaskGroups.RequestTaskGroupAction | StoreTaskGroups.ReceiveTaskGroupAction
    | StoreUsers.RequestUserInfoAction | StoreUsers.ReceiveUserInfoAction;

// ----------------
// ACTION CREATORS

export const actionCreators = {
    initializeTaskPage: (taskId: string | undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState();
        let isLoading = state.taskPage.isLoading;

        if (!isLoading) {
            if (taskId) {
                let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);

                try {
                    let fetchTask = client.getTask(taskId);
                    dispatch({ type: ActionTypes.TASK_PAGE_TASK_REQUEST, taskId: taskId });

                    let task = await fetchTask;
                    dispatch({ type: ActionTypes.TASK_PAGE_TASK_RECEIVE, taskId: taskId, task: task });
                    dispatch({ type: ActionTypes.TASK_RECEIVE, taskId: taskId, task: task });

                    if (task.projectId) {
                        let project = state.projects && state.projects.projects && state.projects.projects[task.projectId];
                        let isLoadingProject = state.projects && state.projects.loadingProjects && state.projects.loadingProjects[task.projectId];

                        if (project) {
                            dispatch({ type: ActionTypes.TASK_PAGE_PROJECT_SET_DETAILS, taskId: taskId, projectId: task.projectId, project: project });
                        }
                        else if (!isLoadingProject) {
                            StoreProjects.actionCreators.requestProject(task.projectId, true)(dispatch as any, getState);
                        }
                    }
                    else {
                        dispatch({ type: ActionTypes.TASK_PAGE_PROJECT_SET_DETAILS, taskId: taskId, projectId: null, project: null });
                    }

                    if (task.taskGroupId && task.projectId) {
                        let taskGroup = state.taskGroups && state.taskGroups.taskGroups && state.taskGroups.taskGroups[task.taskGroupId];
                        let isLoadingTaskGroup = state.taskGroups && state.taskGroups.loadingTaskGroups && state.taskGroups.loadingTaskGroups[task.taskGroupId];

                        if (taskGroup) {
                            dispatch({ type: ActionTypes.TASK_PAGE_TASKGROUP_SET_DETAILS, taskId: taskId, taskGroupId: task.taskGroupId, taskGroup: taskGroup });
                        }
                        else if (!isLoadingTaskGroup) {
                            StoreTaskGroups.actionCreators.requestTaskGroup(task.taskGroupId, task.projectId, true)(dispatch as any, getState);
                        }
                    }
                    else {
                        dispatch({ type: ActionTypes.TASK_PAGE_TASKGROUP_SET_DETAILS, taskId: taskId, taskGroupId: null, taskGroup: null });
                    }

                    if (task.milestoneId && task.projectId) {
                        let milestone = state.milestones && state.milestones.milestones && state.milestones.milestones[task.milestoneId];
                        let isLoadingMilestone = state.milestones && state.milestones.loadingMilestones && state.milestones.loadingMilestones[task.milestoneId];

                        if (milestone) {
                            dispatch({ type: ActionTypes.TASK_PAGE_MILESTONE_SET_DETAILS, taskId: taskId, milestoneId: task.milestoneId, milestone: milestone });
                        }
                        else if (!isLoadingMilestone) {
                            StoreMilestones.actionCreators.requestMilestone(task.milestoneId, task.projectId, true)(dispatch as any, getState);
                        }
                    }
                    else {
                        dispatch({ type: ActionTypes.TASK_PAGE_MILESTONE_SET_DETAILS, taskId: taskId, milestoneId: null, milestone: null });
                    }

                    let userIds: string[] = [];
                    if (task.creatorId) { userIds.push(task.creatorId); }
                    if (task.assignees) { userIds.push(...task.assignees.map(assignee => assignee.userId)); }
                    if (userIds.length) {
                        dispatch({ type: ActionTypes.TASK_PAGE_USERS_INITIALIZE, taskId: taskId, userIds: userIds });

                        for (let userId of userIds) {
                            let user = state.users && state.users.users && state.users.users[userId];

                            if (user) {
                                dispatch({ type: ActionTypes.TASK_PAGE_USER_SET_DETAILS, taskId: taskId, userId: userId, user: user });
                            }
                            else {
                                StoreUsers.actionCreators.requestUser(userId, false)(dispatch as any, getState);
                            }
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                    dispatch({ type: ActionTypes.TASK_PAGE_TASK_RECEIVE, taskId: taskId, task: null });
                }
            }

            dispatch({ type: ActionTypes.TASK_PAGE_PAGE_LOADED, taskId: taskId });
        }
    },

    setDescription: (taskId: string, description: string) => <SetDescriptionAction>{
        type: ActionTypes.TASK_PAGE_SET_DESCRIPTION,
        taskId: taskId,
        description: description
    },

    setStage: (taskId: string, stage: TaskStage, customStageId?: number) => <SetStageAction>{
        type: ActionTypes.TASK_PAGE_SET_STAGE,
        taskId: taskId,
        stage: stage,
        customStageId: customStageId
    },

    setPriority: (taskId: string, priority: TaskPriority) => <SetPriorityAction>{
        type: ActionTypes.TASK_PAGE_SET_PRIORITY,
        taskId: taskId,
        priority: priority
    },

    removeAssignee: (taskId: string, assigneeId: string) => <RemoveAssigneeAction>{
        type: ActionTypes.TASK_PAGE_REMOVE_ASSIGNEE,
        taskId: taskId,
        assigneeId: assigneeId
    },

    addTag: (taskId: string, tag: string) => <AddTagAction>{
        type: ActionTypes.TASK_PAGE_ADD_TAG,
        taskId: taskId,
        tag: tag
    },

    removeTag: (taskId: string, tag: string) => <RemoveTagAction>{
        type: ActionTypes.TASK_PAGE_REMOVE_TAG,
        taskId: taskId,
        tag: tag
    },

    addSubTask: (taskId: string, subTask: string) => <AddSubTaskAction>{
        type: ActionTypes.TASK_PAGE_ADD_SUBTASK,
        taskId: taskId,
        subTask: subTask
    },

    removeSubTask: (taskId: string, subTask: ChecklistItemModel) => <RemoveSubTaskAction>{
        type: ActionTypes.TASK_PAGE_REMOVE_SUBTASK,
        taskId: taskId,
        subTask: subTask
    },

    updateSubTaskDescription: (taskId: string, subTask: ChecklistItemModel, newSubTaskDescription: string) => <UpdateSubTaskDescriptionAction>{
        type: ActionTypes.TASK_PAGE_UPDATE_SUBTASK_DESCRIPTION,
        taskId: taskId,
        subTask: subTask,
        newSubTaskDescription: newSubTaskDescription
    },

    toggleSubTaskIsCompleted: (taskId: string, subTask: ChecklistItemModel) => <ToggleSubTaskIsCompletedAction>{
        type: ActionTypes.TASK_PAGE_TOGGLE_SUBTASK_ISCOMPLETED,
        taskId: taskId,
        subTask: subTask
    },

    updateNotes: (taskId: string, notes: string | null) => <UpdateNotesAction>{
        type: ActionTypes.TASK_PAGE_UPDATE_NOTES,
        taskId: taskId,
        notes: notes
    },

    closeErrorDialog: () => <CloseErrorDialogAction>{ type: ActionTypes.TASK_PAGE_CLOSE_ERROR_DIALOG },

    refresh: (taskId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        actionCreators.initializeTaskPage(taskId)(dispatch, getState);
    },

    showRemoveDialog: (taskId: string) => <ShowRemoveDialogAction>{
        type: ActionTypes.TASK_PAGE_DIALOG_SHOW_REMOVE,
        taskId: taskId
    },

    closeRemoveDialog: (taskId: string) => <CloseRemoveDialogAction>{
        type: ActionTypes.TASK_PAGE_DIALOG_CLOSE_REMOVE,
        taskId: taskId
    },

    remove: (taskId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);

        let state = getState();
        let task = state.tasks && state.tasks.tasks && state.tasks.tasks[taskId];

        if (task) {
            let fetchDeleteTask = client.deleteTask(task);
            await fetchDeleteTask;

            // TODO: Huy: go back to previous page
        }
    },

    showResetDialog: (taskId: string) => <ShowResetDialogAction>{
        type: ActionTypes.TASK_PAGE_DIALOG_SHOW_RESET,
        taskId: taskId
    },

    closeResetDialog: (taskId: string) => <CloseResetDialogAction>{
        type: ActionTypes.TASK_PAGE_DIALOG_CLOSE_RESET,
        taskId: taskId
    },

    reset: (taskId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        actionCreators.initializeTaskPage(taskId)(dispatch, getState);
    }
}

// ----------------
// REDUCER

const initialState: TaskPageState = {
    task: null,

    project: null,
    taskGroup: null,
    milestone: null,
    users: { },

    startDate: null,
    endDate: null,
    durationSeconds: null,
    durationPercent: 0,
    isStartDateCalculated: false,
    isEndDateCalculated: false,
    isDurationCalculated: false,
    taskDateStatus: DateStatus.NotAvailable,
    dateTimeDescription: "",

    availableStages: [],

    isNew: false,
    isEditing: false,
    isLoading: false,
    isError: false,
    isErrorDialogVisible: false,

    isResetDialogVisible: false,
    isRemoveDialogVisible: false,

    commands: {
        isMarkAsDoneVisible: true
    },

    policy: {
        canCreateTask: true,
        canUpdate: true,
        canDelete: true
    }
}

export const reducer: Reducer<TaskPageState> = (state: TaskPageState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.TASK_PAGE_TASK_REQUEST:
            return {
                ...state,
                isLoading: true,
                isEditing: false,
                isNew: false,
                isError: false,
                isErrorDialogVisible: false,
            };
        case ActionTypes.TASK_PAGE_TASK_RECEIVE:
            if (action.task) {
                let modelDetails = reducerUtilities.populateModelDetails(action.task);

                return {
                    ...state,
                    task: action.task,
                    isLoading: false,
                    isError: false,
                    isErrorDialogVisible: false,
                    ...modelDetails
                };
            }
            else {
                return {
                    ...state,
                    task: null,
                    isLoading: false,
                    isError: true,
                    isErrorDialogVisible: true
                };
            }
        case ActionTypes.TASK_PAGE_SET_DESCRIPTION:
            if (state.task && action.taskId === state.task.id) {
                if (action.description !== state.task.description) {
                    return {
                        ...state,
                        isEditing: true,
                        task: {
                            ...state.task,
                            description: action.description
                        } as TaskModel
                    };
                }
            }
            break;
        case ActionTypes.TASK_PAGE_SET_STAGE:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isEditing: true,
                    task: {
                        ...state.task,
                        taskStage: action.stage,
                        customStageId: action.customStageId
                    } as TaskModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_SET_PRIORITY:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isEditing: true,
                    task: {
                        ...state.task,
                        priority: action.priority,
                    } as TaskModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_REMOVE_ASSIGNEE:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isEditing: true,
                    task: {
                        ...state.task,
                        assignees: state.task.assignees
                            ? state.task.assignees.filter(assignee => {
                                return assignee.userId !== action.assigneeId;
                            })
                            : [],
                    } as TaskModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_ADD_TAG:
            if (state.task && action.taskId === state.task.id) {
                if (action.tag) {
                    let tag = action.tag.trim().toLowerCase();

                    if (!state.task.tags || !state.task.tags.find(t => t === tag)) {
                        return {
                            ...state,
                            isEditing: true,
                            task: {
                                ...state.task,
                                tags: [
                                    tag,
                                    ...state.task.tags || []
                                ]
                            } as TaskModel
                        };
                    }
                }
            }
            break;
        case ActionTypes.TASK_PAGE_REMOVE_TAG:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isEditing: true,
                    task: {
                        ...state.task,
                        tags: state.task.tags
                            ? state.task.tags.filter((t) => t !== action.tag)
                            : []
                    } as TaskModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_ADD_SUBTASK:
            if (state.task && action.taskId === state.task.id) {
                if (action.subTask) {
                    let subTask = action.subTask;

                    return {
                        ...state,
                        isEditing: true,
                        task: {
                            ...state.task,
                            subTasks: [
                                ...state.task.subTasks || [],
                                {
                                    description: subTask,
                                    isCompleted: false
                                }
                            ]
                        } as TaskModel
                    };
                }
            }
            break;
        case ActionTypes.TASK_PAGE_REMOVE_SUBTASK:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isEditing: true,
                    task: {
                        ...state.task,
                        subTasks: state.task.subTasks ? state.task.subTasks.filter((t) => t !== action.subTask) : []
                    } as TaskModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_UPDATE_SUBTASK_DESCRIPTION:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isEditing: true,
                    task: {
                        ...state.task,
                        subTasks: state.task.subTasks ? state.task.subTasks.map(p => {
                            if (p === action.subTask) {
                                return Object.assign({}, p, { description: action.newSubTaskDescription });
                            }
                            else {
                                return p;
                            }
                        }) : []
                    } as TaskModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_TOGGLE_SUBTASK_ISCOMPLETED:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isEditing: true,
                    task: {
                        ...state.task,
                        subTasks: state.task.subTasks ? state.task.subTasks.map(p => {
                            if (p === action.subTask) {
                                return Object.assign({}, p, { isCompleted: !p.isCompleted });
                            }
                            else {
                                return p;
                            }
                        }) : []
                    } as TaskModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_UPDATE_NOTES:
            if (state.task && action.taskId === state.task.id) {
                if (action.notes !== state.task.notes) {
                    return {
                        ...state,
                        isEditing: true,
                        task: {
                            ...state.task,
                            notes: action.notes
                        } as TaskModel
                    };
                }
            }
            break;
        case ActionTypes.PROJECT_REQUEST:
            if (state.task && action.projectId && action.projectId === state.task.projectId) {
                return {
                    ...state,
                    project: { id: undefined, title: "Loading...", shortDescription: '' } as any as ProjectModel,
                    availableStages: reducerUtilities.getAvailableStages(null)
                };
            }
            break;
        case ActionTypes.TASK_PAGE_PROJECT_SET_DETAILS:
        case ActionTypes.PROJECT_RECEIVE:
            if (state.task && state.task.projectId) {
                if (action.projectId && action.projectId === state.task.projectId && action.project) {
                    return {
                        ...state,
                        project: action.project,
                        availableStages: reducerUtilities.getAvailableStages(action.project)
                    };
                }
            }
            else {
                return {
                    ...state,
                    project: null,
                    availableStages: reducerUtilities.getAvailableStages(null)
                };
            }
            break;
        case ActionTypes.TASKGROUP_REQUEST:
            if (state.task && action.taskGroupId && action.taskGroupId === state.task.taskGroupId) {
                return {
                    ...state,
                    taskGroup: { id: undefined, name: "Loading..." } as any as TaskGroupModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_TASKGROUP_SET_DETAILS:
        case ActionTypes.TASKGROUP_RECEIVE:
            if (state.task && state.task.taskGroupId) {
                if (action.taskGroupId && action.taskGroupId === state.task.taskGroupId && action.taskGroup) {
                    return {
                        ...state,
                        taskGroup: action.taskGroup
                    };
                }
            }
            else {
                return {
                    ...state,
                    taskGroup: null
                };
            }
            break;
        case ActionTypes.MILESTONE_REQUEST:
            if (state.task && action.milestoneId && action.milestoneId === state.task.milestoneId) {
                return {
                    ...state,
                    milestone: { id: undefined, name: "Loading..." } as any as MilestoneModel
                };
            }
            break;
        case ActionTypes.TASK_PAGE_MILESTONE_SET_DETAILS:
        case ActionTypes.MILESTONE_RECEIVE:
            if (state.task && state.task.milestoneId) {
                if (action.milestoneId && action.milestoneId === state.task.milestoneId && action.milestone) {
                    return {
                        ...state,
                        milestone: action.milestone
                    };
                }
            }
            else {
                return {
                    ...state,
                    milestone: null
                };
            }
            break;
        case ActionTypes.USER_INFO_REQUEST:
            if (action.userId && state.users[action.userId]) {
                return {
                    ...state,
                    users: {
                        ...state.users,
                        [action.userId]: { id: undefined, userName: "...", displayName: "..." } as any as UserModel
                    }
                };
            }
            break;
        case ActionTypes.TASK_PAGE_USER_SET_DETAILS:
        case ActionTypes.USER_INFO_RECEIVE:
            if (action.userId && state.users[action.userId]) {
                if (action.user) {
                    return {
                        ...state,
                        users: {
                            ...state.users,
                            [action.userId]: action.user
                        }
                    };
                }
                else {
                    return {
                        ...state,
                        users: {
                            ...state.users,
                            [action.userId]: { id: undefined, userName: "...", displayName: "..." } as any as UserModel
                        }
                    };
                }
            }
            break;
        case ActionTypes.TASK_PAGE_USERS_INITIALIZE:
            let users: { [userId: string]: UserModel } = {};
            action.userIds.forEach((userId) => users[userId] = {} as UserModel);

            return {
                ...state,
                users: users
            };
        case ActionTypes.TASK_PAGE_PAGE_LOADED:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                isNew: action.taskId === undefined
            };
        case ActionTypes.TASK_PAGE_CLOSE_ERROR_DIALOG:
            return {
                ...state,
                isErrorDialogVisible: false
            };
        case ActionTypes.TASK_PAGE_DIALOG_SHOW_REMOVE:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isRemoveDialogVisible: true
                };
            }
            break;
        case ActionTypes.TASK_PAGE_DIALOG_CLOSE_REMOVE:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isRemoveDialogVisible: false
                };
            }
            break;
        case ActionTypes.TASK_PAGE_DIALOG_SHOW_RESET:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isResetDialogVisible: true
                };
            }
            break;
        case ActionTypes.TASK_PAGE_DIALOG_CLOSE_RESET:
            if (state.task && action.taskId === state.task.id) {
                return {
                    ...state,
                    isResetDialogVisible: false
                };
            }
    }

    return state || initialState;
}

const reducerUtilities = {
    populateModelDetails(task: TaskModel): {
        startDate: DateTimeWithAccuracy,
        endDate: DateTimeWithAccuracy,
        durationSeconds: number | null,
        durationPercent: number,
        isStartDateCalculated: boolean,
        isEndDateCalculated: boolean,
        isDurationCalculated: boolean,
        taskDateStatus: DateStatus,
        dateTimeDescription: string
    } {
        let startDate = new DateTimeWithAccuracy(task.startTime, task.startTimeAccuracy);
        let endDate = new DateTimeWithAccuracy(task.endTime, task.endTimeAccuracy);
        let durationSeconds = task.duration !== undefined && task.duration !== null ? task.duration as number : null;

        startDate.adjustDateTimeAccordingToAccuracy(DateTimeWithAccuracyType.Start);
        endDate.adjustDateTimeAccordingToAccuracy(DateTimeWithAccuracyType.End);

        let isStartDateCalculated = task.endTime !== undefined && task.duration !== undefined;
        let isEndDateCalculated = task.startTime !== undefined && task.duration !== undefined;
        let isDurationCalculated = task.startTime !== undefined && task.endTime !== undefined;

        if (isDurationCalculated)
            durationSeconds = DateTimeHelper.calculateDurationSeconds(startDate, endDate);

        if (isStartDateCalculated)
            startDate = DateTimeHelper.calculateStartDate(endDate, durationSeconds);

        if (isEndDateCalculated)
            endDate = DateTimeHelper.calculateEndDate(startDate, durationSeconds);

        let taskDateStatus = reducerUtilities.getTaskDateStatus(
            startDate && startDate.getDateTime(DateTimeWithAccuracyType.Start),
            endDate && endDate.getDateTime(DateTimeWithAccuracyType.End),
            durationSeconds,
            task.taskStage);

        let dateTimeDescription = reducerUtilities.getTaskDateTimeDescription(taskDateStatus, startDate, endDate, durationSeconds, true);

        let durationPercent = reducerUtilities.getDurationPercent(taskDateStatus, startDate, endDate);

        return {
            startDate: startDate,
            endDate: endDate,
            durationSeconds: durationSeconds,
            durationPercent: durationPercent,
            isStartDateCalculated: isStartDateCalculated,
            isEndDateCalculated: isEndDateCalculated,
            isDurationCalculated: isDurationCalculated,
            taskDateStatus: taskDateStatus,
            dateTimeDescription: dateTimeDescription
        };
    },

    getAvailableStages(project: ProjectModel | null): TaskStageModel[] {
        let availableStages = (project && project.customStages) || [];
        availableStages = availableStages.map(availableStage => Object.assign({}, availableStage, { stage: TaskStage.Custom }));

        availableStages = [
            {
                id: ModelConstants.TaskStageToDoId,
                stage: TaskStage.Todo,
                name: ModelConstants.TaskStageToDoName
            },
            ...availableStages,
            {
                id: ModelConstants.TaskStageDoneId,
                stage: TaskStage.Done,
                name: ModelConstants.TaskStageDoneName
            }
        ];

        return availableStages;
    },

    getDurationPercent(taskDateStatus: DateStatus, startDate: DateTimeWithAccuracy, endDate: DateTimeWithAccuracy): number {
        if (taskDateStatus === DateStatus.Done)
            return 100;
        else if (taskDateStatus === DateStatus.Overdue)
            return 100;
        else if (taskDateStatus === DateStatus.DueToday)
            return 100;
        else
            return DateTimeHelper.calculateDurationPercent(startDate, endDate);
    },

    getTaskDateStatus(startDateLocal: Date | null, endDateLocal: Date | null, durationSeconds: number | null, stage: TaskStage): DateStatus {
        return DateStatusHelper.determineTaskDateStatus(startDateLocal, endDateLocal, durationSeconds, stage);
    },

    getTaskDateTimeDescription(dateStatus: DateStatus, startDate: DateTimeWithAccuracy, endDate: DateTimeWithAccuracy, durationSeconds: number | null, showNumberOfDayInsteadOfDateWhenDateIsFar: boolean = false, upperCase: boolean = false): string {
        return DateTimeHelper.getDateDescriptionFromDateStatus(dateStatus, startDate, endDate, durationSeconds, showNumberOfDayInsteadOfDateWhenDateIsFar, upperCase);
    },
}

// ----------------
// SELECTOR