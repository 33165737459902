import * as React from 'react';
import moment from 'moment';
import { TimeAccuracy } from 'models';
import DateTimeWithAccuracy from 'models/DateTimeWithAccuracy';

export enum DateTimeWithAccuracyType {
    Start,
    End
}

interface DateTimeWithAccuracyCardProps {
    dateTime: DateTimeWithAccuracy | null;
    dateType: DateTimeWithAccuracyType;
}

export default (props: Readonly<DateTimeWithAccuracyCardProps>) => {
    let hasDate = props.dateTime && props.dateTime.dateTime;
    let noValueText = props.dateType === DateTimeWithAccuracyType.Start
        ? "No start date"
        : "No due date";

    let headerText = "", mainText = "", footerText = "";
    let rootClassName = "date_time_with_accuracy_card";

    if (props.dateTime && props.dateTime.dateTime) {
        let date = props.dateTime.dateTime;
        let dateMoment = moment(date);

        switch (props.dateTime.accuracy) {
            case TimeAccuracy.Time:
                headerText = dateMoment.format("MMM YYYY");
                mainText = dateMoment.format("D");
                footerText = dateMoment.format("LT");
                rootClassName += " time";
                break;
            case TimeAccuracy.Day:
                headerText = dateMoment.format("MMM YYYY");
                mainText = dateMoment.format("D");
                rootClassName += " day";
                break;
            case TimeAccuracy.Month:
                headerText = dateMoment.format("YYYY");
                mainText = dateMoment.format("MMM");
                rootClassName += " month";
                break;
            case TimeAccuracy.Year:
                mainText = dateMoment.format("YYYY");
                rootClassName += " year";
                break;
        }
    }

    return hasDate
        ?
        <div className={rootClassName}>
            <div className="header">{headerText ? headerText : <span>&nbsp;</span>}</div>
            <div className="main">{mainText ? mainText : <span>&nbsp;</span>}</div>
            <div className="footer">{footerText ? footerText : <span>&nbsp;</span>}</div>
        </div>
        :
        <div className="date_time_with_accuracy_card none">
            <div>{noValueText}</div>
        </div>;
}