import moment from 'moment';
import { TimeAccuracy } from './index';
import Dates from './Dates';

export enum DateTimeWithAccuracyType {
    Start,
    End
}

export default class DateTimeWithAccuracy {
    dateTime: Date | null;
    accuracy: TimeAccuracy = TimeAccuracy.Day;

    constructor(dateTime?: Date | null, accuracy?: TimeAccuracy) {
        if (!dateTime) {
            this.dateTime = null;
        }
        else {
            if (accuracy === TimeAccuracy.Time)
                dateTime = moment(dateTime).local().toDate();

            this.dateTime = dateTime;
        }

        this.accuracy = accuracy || TimeAccuracy.Day;
    }

    public getDateTime(type: DateTimeWithAccuracyType): Date | null {
        if (!this.dateTime) return null;

        this.adjustDateTimeAccordingToAccuracy(type);

        if (this.accuracy === TimeAccuracy.Time)
            return moment(this.dateTime).local().toDate();
        else {
            if (type === DateTimeWithAccuracyType.Start)
                return moment(this.dateTime).local().startOf('day').toDate();
            else {
                try {
                    return moment(this.dateTime).local().startOf('day').add(1, 'day').subtract(1, 'second').toDate();
                }
                catch (ex) {
                    console.log(`DateTimeWithAccuracy:getDateTime:${ex}`);
                    return Dates.MaxDate;
                }
            }
        }
    }

    public adjustDateTimeAccordingToAccuracy(type: DateTimeWithAccuracyType): void {
        if (!this.dateTime) return;

        switch (this.accuracy) {
            case TimeAccuracy.Time:
                this.dateTime = moment(this.dateTime).local().toDate();
                break;
            case TimeAccuracy.Day:
                this.dateTime = moment(this.dateTime).local().startOf('day').toDate();
                break;
            case TimeAccuracy.Month:
                if (type === DateTimeWithAccuracyType.Start)
                    this.dateTime = moment(this.dateTime).local().startOf('month').toDate();
                else
                    this.dateTime = moment(this.dateTime).local().startOf('month').add(1, 'month').subtract(1, 'day').toDate();
                break;
            case TimeAccuracy.Year:
                if (type === DateTimeWithAccuracyType.Start)
                    this.dateTime = moment(this.dateTime).local().startOf('year').toDate();
                else
                    this.dateTime = moment(this.dateTime).local().startOf('year').add(1, 'year').subtract(1, 'day').toDate();
                break;
        }
    }
}