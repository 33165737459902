import { darken, lighten } from "polished";

export default class Colors {
    static readonly colorApp: string = '#009688';
    static readonly colorGreen: string = '#388e3c';
    static readonly colorRed: string = '#d32f2f';
    static readonly colorYellow: string = '#ffee58';
    static readonly colorBlue: string = '#1976d2';
    static readonly colorOrange: string = '#fb8c00';
}

export const AppColor = '#009688';
export const AppColorLight = '#4db6ac';
export const AppColorDark = '#00796b';
export const AppColorDarkDark = '#004d40';

export const AppBackground = '#f2f2f2';

export const GrayBase = '#000';
export const GrayDarker = lighten(0.135, GrayBase);
export const GrayDark = lighten(0.2, GrayBase);   // #333
export const Gray = lighten(0.335, GrayBase); // #555
export const GrayLight = lighten(0.467, GrayBase); // #777
export const GrayLightLighter = lighten(0.701, GrayBase); // #b3b3b3
export const GrayLightLighterLighter = lighten(0.865, GrayBase); // #b3b3b3
export const GrayLighter = lighten(0.935, GrayBase); // #eee
export const GrayLighterLighter = lighten(0.95, GrayBase);

export const Orange = '#fb8c00';
export const OrangeLight = lighten(0.1, Orange);
export const OrangeDark = darken(0.1, Orange);
export const Green = '#388e3c';
export const GreenLight = lighten(0.1, Green);
export const GreenDark = darken(0.1, Green);
export const Blue = '#1976d2';
export const BlueLight = lighten(0.1, Blue);
export const BlueDark = darken(0.1, Blue);