import styled, { css } from 'styled-components'

interface Props {
    backgroundColor: string | null;
}

export const StyledPage = styled.div<Props>`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
${props => props && css`background-color: ${props.backgroundColor}`};
`