import * as React from 'react';

interface Props {
    messages: string[] | null;
}

export default (props: Readonly<Props>) => (
    !!props.messages ? <React.Fragment>
        {props.messages.map(message => (
            <div className="alert alert-danger">{message}</div>
        ))}
    </React.Fragment> : null
)