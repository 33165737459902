import { Action, Reducer } from 'redux';
import { container } from "inversify.config";
import { AppThunkAction, ApplicationState } from './';

import * as ActionTypes from 'common/ActionTypes';
import { TaskModel, TaskStage } from 'models';
import { ProjectStudioAPI } from 'logics/ProjectStudioAPI';
import { compareTasks } from 'logics/SortLogic';

export const ALL_TASKS_ID = 'ALL_TASKS';
export const NO_PROJECT_ID = '#empty';

export type Order = "ASC" | "DESC";

export interface TasksState {
    tasks: { [taskId: string]: TaskModel };
    taskIdsByProject: { [projectId: string]: string[] };
    loadingProjects: { [projectId: string]: boolean };
    orders: { [projectId: string]: { [prop: string]: Order } }
}

// ----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface ReceiveTaskAction {
    type: typeof ActionTypes.TASK_RECEIVE;
    taskId: string;
    task: TaskModel;
}

interface RequestTaskList {
    type: 'REQUEST_TASK_LIST';
    projectId: string | null | undefined;
}

interface ReceiveTaskList {
    type: 'RECEIVE_TASK_LIST',
    projectId: string | null | undefined;
    tasks: TaskModel[];
}

interface RequestTask {
    type: 'REQUEST_TASK';
    taskId: string;
}

interface ApplySortOrders {
    type: 'APPLY_SORT_ORDERS';
    projectId: string;
    orders: { [prop: string]: Order };
}

interface AddTaskRequest {
    type: 'ADD_TASK_REQUEST';
    model: TaskModel;
}

interface AddTaskSuccess {
    type: 'ADD_TASK_SUCCESS';
    model: TaskModel;
}

interface AddTaskFail {
    type: 'ADD_TASK_FAIL';
    model: TaskModel;
    error: any;
}

type KnownAction = ReceiveTaskAction
    | RequestTaskList | ReceiveTaskList
    | RequestTask
    | ApplySortOrders
    | AddTaskRequest | AddTaskSuccess | AddTaskFail;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestTaskList: (projectId: string | null | undefined, forceRefesh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var isLoading = getState().tasks.loadingProjects[normalizeProjectId(projectId)];
        var loadedTasks = getState().tasks.taskIdsByProject[normalizeProjectId(projectId)];

        if (forceRefesh || (!isLoading && loadedTasks === undefined)) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);
            let fetchTask = client.getTasks(projectId);

            dispatch({ type: 'REQUEST_TASK_LIST', projectId: projectId });

            var tasks = await fetchTask;
            dispatch({ type: 'RECEIVE_TASK_LIST', projectId: projectId, tasks: tasks.filter(task => task.taskStage !== TaskStage.Done) });
        }
    },
    requestTask: (taskId: string, forceRefesh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var loadedTasks = getState().tasks.tasks[taskId];
        if (forceRefesh || loadedTasks === undefined) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);
            let fetchTask = client.getTask(taskId);

            dispatch({ type: 'REQUEST_TASK', taskId: taskId });

            var task = await fetchTask;
            dispatch({ type: ActionTypes.TASK_RECEIVE, taskId: taskId, task: task });
        }
    },
    requestSortOrders: (projectId: string | null | undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        projectId = normalizeProjectId(projectId);
        if (getState().tasks.orders[projectId] === undefined) {
            if (localStorage) {
                let key = "SortOrders_" + projectId;
                let ordersString = localStorage.getItem(key);
                if (ordersString) {
                    let orders = JSON.parse(ordersString);
                    dispatch({ type: 'APPLY_SORT_ORDERS', projectId: projectId, orders: orders });
                    return;
                }
            }
            dispatch({ type: 'APPLY_SORT_ORDERS', projectId: projectId, orders: {} });
        }
    },
    applySortOrders: (projectId: string | null, orders: { [prop: string]: Order }): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        projectId = normalizeProjectId(projectId);
        if (localStorage) {
            let key = "SortOrders_" + projectId;
            localStorage.setItem(key, JSON.stringify(orders));
        }

        dispatch({ type: 'APPLY_SORT_ORDERS', projectId: projectId, orders: orders });
    },
    addTaskModel: (model: TaskModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);
        let fetchTask = client.addTask(model);

        dispatch({ type: 'ADD_TASK_REQUEST', model: model });

        try {
            var task = await fetchTask;
            dispatch({ type: 'ADD_TASK_SUCCESS', model: task });
        } catch (e) {
            dispatch({ type: 'ADD_TASK_FAIL', model: model, error: e });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: TasksState = {
    tasks: {},
    taskIdsByProject: {},
    loadingProjects: {},
    orders: {},
}

export const reducer: Reducer<TasksState> = (state: TasksState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.TASK_RECEIVE:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.taskId]: action.task,
                }
            };
        case 'REQUEST_TASK_LIST':
            return {
                ...state,
                loadingProjects: {
                    ...state.loadingProjects,
                    [normalizeProjectId(action.projectId)]: true,
                },
            };
        case 'RECEIVE_TASK_LIST':
            var taskIdsByProject: { [id: string]: string[] } = action.tasks.reduce((m: { [id: string]: string[] }, t) => {
                // We cannot use normalizeProjectId here because t.projectId cannot indicate all project
                var projectId = t.projectId ? t.projectId : NO_PROJECT_ID;
                if (m[projectId]) {
                    m[projectId].push(t.id);
                } else {
                    m[projectId] = [t.id];
                }
                return m;
            }, {});

            let normalizedProjectId = normalizeProjectId(action.projectId);

            if (normalizedProjectId === ALL_TASKS_ID) {
                // Add
                taskIdsByProject[ALL_TASKS_ID] = action.tasks.map(t => t.id);
            } else {
                // Handle edge case that there is no task in the requested project
                if (taskIdsByProject[normalizedProjectId] === undefined) {
                    taskIdsByProject[normalizedProjectId] = [];
                }
            }
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    ...action.tasks.reduce((m: { [id: string]: TaskModel }, t) => { m[t.id] = t; return m; }, {}),
                },
                taskIdsByProject: {
                    ...state.taskIdsByProject,
                    ...taskIdsByProject,
                },
                loadingProjects: {
                    ...state.loadingProjects,
                    [normalizedProjectId]: false,
                }
            };
        case 'REQUEST_TASK':
            return {
                ...state
            };

        case 'APPLY_SORT_ORDERS':
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.projectId]: action.orders
                }
            }

        case 'ADD_TASK_REQUEST':
            break;

        case 'ADD_TASK_SUCCESS':
            let projectId = action.model.projectId || NO_PROJECT_ID;
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.model.id]: action.model
                },
                taskIdsByProject: {
                    ...state.taskIdsByProject,
                    [projectId]: state.taskIdsByProject[projectId].concat(action.model.id)
                }
            }

        case 'ADD_TASK_FAIL':
            break;

        default:
            /* eslint @typescript-eslint/no-unused-vars: "off"*/
            var exclusiveCheck: never = action;
    }

    return state || initialState;
}

// ----------------
// SELECTOR

const normalizeProjectId = (projectId: string | null | undefined) => {
    return projectId === undefined ? ALL_TASKS_ID : (projectId === null ? NO_PROJECT_ID : projectId);
}

export const getTasksByProjectId = (state: ApplicationState, projectId: string | null | undefined) => {
    let _projectId = normalizeProjectId(projectId);

    if (state.tasks.tasks === null || state.login.userInfo === null) return null;
    if (state.tasks.loadingProjects[_projectId]) return null;

    if (_projectId) {
        if (!state.tasks.orders[_projectId]) return null;

        var taskIds = state.tasks.taskIdsByProject[_projectId];
        if (taskIds) {
            var tasks = taskIds.map(k => state.tasks.tasks[k]);
            let userInfo = state.login.userInfo;
            let customStages = (!!state.projects.projects && !!state.projects.projects[_projectId]) ? state.projects.projects[_projectId].customStages : null;
            let projects = state.projects.projects;
            let taskGroups = state.taskGroups.taskGroups
            let milestones = state.milestones.milestones;

            tasks = tasks.sort((a, b) => compareTasks(a, b, state.tasks.orders[_projectId], userInfo, customStages, projects, taskGroups, milestones))
            return tasks;
        } else {
            return null;
        }
    } else {
        // Return all tasks
        return Object.keys(state.tasks).map(k => state.tasks.tasks[k]);
    }
}

export const getOrdersByProjectId = (state: TasksState, projectId: string | null) => {
    projectId = normalizeProjectId(projectId);
    return state.orders[projectId];
}