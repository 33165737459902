import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { TaskModel, TaskStage, ProjectModel } from 'models';
import * as Store from 'store';
import * as Tasks from 'store/Tasks';
import * as TaskGroups from 'store/TaskGroups';
import * as Milestones from 'store/Milestones';
import LoadingPageContent from 'components/common/LoadingPageContent';
import { GrayLighterLighter, GrayLighter } from 'common/Colors';
import TaskKanbanItem from './TaskKanbanItem';

interface OwnProps {
    project: ProjectModel | null;
    tasks: TaskModel[] | null;
}

type Props = OwnProps
    & typeof Tasks.actionCreators
    & typeof TaskGroups.actionCreators
    & typeof Milestones.actionCreators
    & ComponentProps;

const ColumnWidth = 300;
const ColumnGap = 10;

const TaskKanban = (props: Props) => {
    React.useEffect(() => {
        props.requestSortOrders(props.projectId);
        props.requestTaskList(props.projectId, false);
        props.projectId && props.requestTaskGroups(props.projectId, false);
        props.projectId && props.requestMilestones(props.projectId, false);
    });

    if (!props.tasks) {
        return <LoadingPageContent label="Loading your tasks..." />;
    }

    let groupedTasks = props.tasks.reduce((prev, curr) => {
        let stage = curr.taskStage === TaskStage.Todo ? "To Do" : (curr.taskStage === TaskStage.Done ? "Done" : "Custom");
        if (props.project && curr.taskStage === TaskStage.Custom) {
            let customStage = props.project.customStages.find(o => o.id === curr.customStageId);
            if (customStage) {
                stage = customStage.name;
            }
        }
        if (prev[stage])
            prev[stage].push(curr);
        else
            prev[stage] = [curr];
        return prev;
    }, {} as { [stage: string]: TaskModel[] });


    return <StyledGrid>
        <div style={{ width: (ColumnWidth + ColumnGap) * Object.keys(groupedTasks).length + ColumnGap }}>
            {Object.keys(groupedTasks).map(stage => {
                let tasks = groupedTasks[stage];

                return <StyledColumn key={stage}>
                    <StyledColumnHeader>{stage}</StyledColumnHeader>
                    {tasks.map(task => <TaskKanbanItem key={task.id} task={task} project={props.project} />)}
                </StyledColumn>
            })}
        </div>
    </StyledGrid>
}

const StyledGrid = styled.div`
overflow: auto;
`

const StyledColumn = styled.div`
float: left;
margin-left: ${ColumnGap}px;
border: 1px solid ${GrayLighter}
width: ${ColumnWidth}px;
`

const StyledColumnHeader = styled.div`
font-weight: bold;
text-align: center;
background-color: ${GrayLighterLighter};
padding: 6px;
`

interface ComponentProps {
    projectId: string | null;
}

export default connect(
    (state: Store.ApplicationState, ownProps: ComponentProps) => {
        var result: OwnProps = {
            project: state.projects.projects && ownProps.projectId ? state.projects.projects[ownProps.projectId] : null,
            tasks: Tasks.getTasksByProjectId(state, ownProps ? ownProps.projectId : undefined)
        }
        return result;
    },
    dispatch => bindActionCreators({
        ...Tasks.actionCreators,
        ...TaskGroups.actionCreators,
        ...Milestones.actionCreators
    }, dispatch)
)(TaskKanban);