export const DialogButtonYes = "Yes";
export const DialogButtonNo = "No";
export const DialogButtonClose = "Close";

export const TaskNotAvailableErrorMessage = "This task is not available yet. Either it has not been synchronized, it has been deleted, or you don't have access to it";
export const TaskNotAvailableErrorTitle = "Task not available";

export const ErrorResponse401 = 401;
export const ErrorResponseUnauthorized = "Unauthorized";

export const UnsavedChangesConfirmTitle = "Unsaved changes";
export const UnsavedChangesConfirmResetMessage = "You have some unsaved changes. Are you sure you want to reset?";
export const UnsavedChangesConfirmResetYesButton = "Yes";
export const UnsavedChangesConfirmResetNoButton = "Cancel";

export const RemoveTaskConfirmTitle = "Remove task";
export const RemoveTaskConfirmMessage = "Removed task cannot be recovered. Do you want to remove this task?";