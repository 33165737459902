import { DateStatus, TaskStage } from "../models";
import Dates from "../models/Dates";

export default class DateStatusHelper {
    public static determineTaskDateStatus(startDateLocal: Date | null, endDateLocal: Date | null, durationSeconds: number | null, stage: TaskStage): DateStatus {
        let dates = new Dates();

        if (!stage)
            return DateStatus.NotAvailable;
        else if (stage === TaskStage.Done)
            return DateStatus.Done;
        else {
            if (!startDateLocal && !endDateLocal) {
                if (durationSeconds === null)
                    return DateStatus.NoDate;
                else
                    return DateStatus.DurationOnly;
            }
            else if (endDateLocal && endDateLocal < dates.now)
                return DateStatus.Overdue;
            else if (!startDateLocal || startDateLocal < dates.now) {
                if (endDateLocal && endDateLocal < dates.todayPlus1)
                    return DateStatus.DueToday;
                else if (endDateLocal && endDateLocal < dates.todayPlus2)
                    return DateStatus.DueSoon;
                else if (endDateLocal && endDateLocal < dates.thisWeekPlus1)
                    return DateStatus.DueSoon;
                else if (endDateLocal && endDateLocal >= dates.thisWeekPlus1 && !startDateLocal)
                    return DateStatus.DueFuture;
                else if (endDateLocal && endDateLocal >= dates.thisWeekPlus1 && startDateLocal && startDateLocal < dates.now)
                    return DateStatus.Started;
                else if (!endDateLocal)
                    return DateStatus.Started;
                else
                    return DateStatus.NotAvailable;
            }
            else if (startDateLocal && startDateLocal < dates.todayPlus1)
                return DateStatus.StartToday;
            else if (startDateLocal && startDateLocal < dates.todayPlus2)
                return DateStatus.StartSoon;
            else if (startDateLocal && startDateLocal < dates.thisWeekPlus1)
                return DateStatus.StartSoon;
            else if (startDateLocal && startDateLocal >= dates.thisWeekPlus1)
                return DateStatus.StartFuture;
            else
                return DateStatus.NotAvailable;
        }
    }
}