import * as React from 'react';
import moment from 'moment';
import EndDateCard from './EndDateCard';
import StartDateCard from './StartDateCard';
import FontIcons from 'common/FontIcons';
import DateTimeWithAccuracy from 'models/DateTimeWithAccuracy';
import { DefaultButton } from '@fluentui/react/lib/Button';

interface StartDateEndDateDurationProps {
    startDate: DateTimeWithAccuracy | null;
    endDate: DateTimeWithAccuracy | null;
    durationSeconds: number | null;
    durationPercent: number;
    isStartDateLockVisible: boolean;
    isEndDateLockVisible: boolean;
    isDurationLockVisible: boolean;
    dateTimeDescription: string;
}

export default (props: Readonly<StartDateEndDateDurationProps>) => {
    let durationString = "";

    if (props.durationSeconds !== null) {
        let duration = moment.duration(props.durationSeconds || 0, "seconds");
        durationString = duration.humanize();
    }

    return <div className="start-end-duration-date">
        <DefaultButton className="btn-start-end-date">
            <StartDateCard dateTime={props.startDate} isLockVisible={props.isStartDateLockVisible} />
        </DefaultButton>
        <DefaultButton className="btn-duration">
            <div>
                <div>
                    {
                        durationString
                            ? <span className="duration">{durationString}</span>
                            : <span>&nbsp;</span>
                    }
                    {
                        props.isDurationLockVisible &&
                        <span className="font-icon lock" title="Duration is calculated based on start date and due date">
                            {FontIcons.iconLock}
                        </span>
                    }
                </div>
                <progress value={props.durationPercent} max="100" />
                {
                    props.dateTimeDescription
                        ? <div>{props.dateTimeDescription}</div>
                        : <div>&nbsp;</div>
                }
            </div>
        </DefaultButton>
        <DefaultButton className="btn-start-end-date">
            <EndDateCard dateTime={props.endDate} isLockVisible={props.isEndDateLockVisible} />
        </DefaultButton>
    </div>;
}