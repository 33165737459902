import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Store from 'store';
import * as StoreTaskPage from 'store/pages/TaskPage';
import { CommandBarCommands } from 'components/common/CommandBarCommands';

import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuItem, ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';

interface TaskPageCommandsOwnProps {
    id: string;
    isNew: boolean;
    isEditing: boolean;
    commands: StoreTaskPage.TaskPageCommandsState;
    policy: StoreTaskPage.TaskPagePolicyState;
}

interface TaskPageCommandsOwnActionProps {
    taskPageActions: typeof StoreTaskPage.actionCreators;
}

type TaskPageCommandsProps = TaskPageCommandsOwnProps & TaskPageCommandsOwnActionProps;

class TaskPageCommands extends React.Component<TaskPageCommandsProps> {

    public render() {
        let commandBarCommands: CommandBarCommands;

        if (this.props.isNew) {
            commandBarCommands = this.getCommandBarForNew(this.props);
        }
        else if (this.props.isEditing) {
            commandBarCommands = this.getCommandBarForEditing(this.props);
        }
        else {
            commandBarCommands = this.getCommandBar(this.props);
        }

        return <CommandBar className="page-command-bar"
            items={commandBarCommands.items || []}
            overflowItems={commandBarCommands.overflowItems}
            farItems={commandBarCommands.farItems} />;
    }

    private getCommandBar(props: TaskPageCommandsProps): CommandBarCommands {
        let items = [
        ] as IContextualMenuItem[];

        if (props.commands.isMarkAsDoneVisible) {
            items.push({
                key: "markAsDone",
                iconProps: {
                    iconName: "CheckBox"
                },
                name: "Mark as Done"
            });
        }

        items.push({
            key: "refresh",
            iconProps: {
                iconName: "Refresh"
            },
            onClick: () => { this.props.taskPageActions.refresh(this.props.id) },
            name: "Refresh"
        });

        if (props.policy.canDelete) {
            items.push({
                key: "remove",
                iconProps: {
                    iconName: "Delete"
                },
                onClick: () => { this.props.taskPageActions.showRemoveDialog(this.props.id) },
                name: "Remove"
            });
        }

        let overflowItems = [
            {
                key: "c946f715-dd91-42e3-87de-aa7e88d3748a",
                itemType: ContextualMenuItemType.Divider
            }
        ] as IContextualMenuItem[];

        if (props.policy.canCreateTask) {
            overflowItems.push(
                {
                    key: "new",
                    iconProps: {
                        iconName: "Add"
                    },
                    name: "New task"
                }, {
                    key: "clone",
                    iconProps: {
                        iconName: "Copy"
                    },
                    name: "Clone"
                }
            );
        }

        overflowItems.push(
            {
                key: "email",
                iconProps: {
                    iconName: "Mail"
                },
                name: "Email"
            }, {
                key: "6be206d5-a7e5-47e7-a94b-05e877706152",
                itemType: ContextualMenuItemType.Divider
            }, {
                key: "copyLink",
                iconProps: {
                    iconName: "Link"
                },
                name: "Copy link"
            }, {
                key: "c11f4460-f8d1-44d3-a120-66c1d5897775",
                itemType: ContextualMenuItemType.Divider
            }, {
                key: "moreInformation",
                iconProps: {
                    iconName: "Info"
                },
                name: "More information"
            }
        );

        let farItems = [
            {
                key: "openInApp",
                iconProps: {
                    iconName: "FolderOpen"
                },
                name: "Open in Project Studio",
                href: "projectstudio:tasks/" + this.props.id,
                style: {
                    textDecoration: "none"
                }
            }
        ] as IContextualMenuItem[];

        return {
            items: items,
            overflowItems: overflowItems,
            farItems: farItems
        };
    }

    private getCommandBarForEditing(props: TaskPageCommandsProps): CommandBarCommands {
        let items = [
        ] as IContextualMenuItem[];

        if (props.policy.canUpdate) {
            items.push({
                key: "save",
                iconProps: {
                    iconName: "Save"
                },
                name: "Save"
            });
        }

        items.push({
            key: "reset",
            iconProps: {
                iconName: "Refresh"
            },
            onClick: () => { this.props.taskPageActions.showResetDialog(this.props.id) },
            name: "Reset"
        });

        if (props.policy.canDelete) {
            items.push({
                key: "remove",
                iconProps: {
                    iconName: "Delete"
                },
                onClick: () => { this.props.taskPageActions.showRemoveDialog(this.props.id) },
                name: "Remove"
            });
        }

        let overflowItems = [
            {
                itemType: ContextualMenuItemType.Divider
            }, {
                key: "copyLink",
                iconProps: {
                    iconName: "Link"
                },
                name: "Copy link"
            }, {
                itemType: ContextualMenuItemType.Divider
            }, {
                key: "moreInformation",
                iconProps: {
                    iconName: "Info"
                },
                name: "More information"
            }
        ] as IContextualMenuItem[];

        let farItems = [
            {
                key: "openInApp",
                iconProps: {
                    iconName: "FolderOpen"
                },
                name: "Open in Project Studio",
                href: "projectstudio:tasks/" + this.props.id,
                style: {
                    textDecoration: "none"
                }
            }
        ] as IContextualMenuItem[];

        return {
            items: items,
            overflowItems: overflowItems,
            farItems: farItems
        };
    }

    private getCommandBarForNew(props: TaskPageCommandsProps): CommandBarCommands {
        let items = [
        ] as IContextualMenuItem[];

        if (props.policy.canCreateTask) {
            items.push({
                key: "save",
                iconProps: {
                    iconName: "Save"
                },
                name: "Save"
            });
        }

        return {
            items: items
        };
    }
}

export default connect(
    (state: Store.ApplicationState) => {
        var result: TaskPageCommandsOwnProps = {
            id: state.taskPage.task && state.taskPage.task.id,
            isNew: state.taskPage.isNew,
            isEditing: state.taskPage.isEditing,
            commands: state.taskPage.commands,
            policy: state.taskPage.policy
        } as TaskPageCommandsOwnProps;
        return result;
    },
    (dispatch) => {
        return {
            taskPageActions: bindActionCreators(StoreTaskPage.actionCreators, dispatch)
        } as TaskPageCommandsOwnActionProps;
    }
)(TaskPageCommands);