import { css } from "styled-components";

export default class FontIcons {
    static readonly iconAdd: string = '\ue710';
    static readonly iconBulletedList: string = '\ue8fd';
    static readonly iconCancel: string = '\ue711';
    static readonly iconCheckbox: string = '\ue739';
    static readonly iconCheckboxChecked: string = '\ue73a';
    static readonly iconChevronDown: string = '\ue70d';
    static readonly iconChevronLeft: string = '\ue76b';
    static readonly iconChevronRight: string = '\ue76c';
    static readonly iconDelete: string = '\ue74d';
    static readonly iconFlag: string = '\ue7c1';
    static readonly iconFlagFill: string = '\ueb4b';
    static readonly iconLock: string = '\ue72e';
    static readonly iconMilestone: string = '\ue18a';
    static readonly iconMultiSelect: string = '\ue762';
    static readonly iconProject: string = '\ue8b7';
    static readonly iconStarFull: string = '\ue735';
    static readonly iconTag: string = '\ue8ec';
}

export const FontIcon = css`
font-family: "Segoe MDL2 Assets", SegoeMDL2;
`;