import * as React from 'react';
import styled, { css } from 'styled-components';
import { ProjectModel, MilestoneModel, TaskGroupModel } from 'models';
import FontIcons, { FontIcon } from 'common/FontIcons';
import { WidthThreshold } from 'common/LayoutConstants';

interface Props {
    project: ProjectModel | null;
    taskGroup: TaskGroupModel | null;
    milestone: MilestoneModel | null;
}

const getAbbreviation = (text: string) => {
    return text.split(' ').map(t => t.charAt(0)).join('');
}

export default (props: Props) => {
    let fullPath = '';
    let shortPath = '';
    if (props.project) {
        shortPath = getAbbreviation(props.project.title);
        fullPath = props.project.title;

        if (props.taskGroup) {
            shortPath += " > " + getAbbreviation(props.taskGroup.name);
            fullPath += " > " + props.taskGroup.name;
        }
    }

    return <StyledWrapper color={props.project ? props.project.color : 'black'}>
        <div className="project-icon">{props.project ? props.project.icon : ''}</div>
        <StyledShortPath>{shortPath}</StyledShortPath>
        <StyledFullPath>{fullPath}</StyledFullPath>
        <StyledMilestoneIcon className="project-icon">{FontIcons.iconMilestone}</StyledMilestoneIcon>
        {props.milestone && props.milestone.name}
    </StyledWrapper>
}

const StyledWrapper = styled.div<any>`
${props => props.color && css`color: ${props.color}`};
display: inline-block;
margin-top: -22px;
font-size: 0.85em;
font-weight: bold;

.project-icon {
    ${FontIcon}
    display: inline-block;
    margin-right: 3px;
    vertical-align: bottom;
}
`

const StyledShortPath = styled.span`
@media (min-width: ${WidthThreshold}px) {
display: none;
}
`

const StyledFullPath = styled.span`
@media (max-width: ${WidthThreshold - 1}px) {
display: none;
}
`

const StyledMilestoneIcon = styled.div`
margin-left: 20px;
`