import * as React from 'react';
import styled from 'styled-components';
import FontIcons from 'common/FontIcons';
import { Orange, Green } from 'common/Colors';

interface Props {
    isBasic?: boolean;
}

export default (props: Readonly<Props>) => (
    <StyledPremium color={props.isBasic ? Green : Orange}>
        <StyledIcon className="icon">{FontIcons.iconStarFull}</StyledIcon>&nbsp;&nbsp;{props.isBasic ? "BASIC" : "PREMIUM"}
    </StyledPremium>
);

const StyledPremium = styled.div<any>`
background-color: ${props => props.color};
padding: 2px 6px 2px 6px;
font-size: 75%;
font-weight: bold;
line-height: 1;
color: white;
text-align: center;
white-space: nowrap;
vertical-align: baseline;
display: inline;
`

const StyledIcon = styled.span`
font-size: 9px;
`