import * as React from 'react';
import DateTimeWithAccuracyCard from './DateTimeWithAccuracyCard';
import { DateTimeWithAccuracyType } from './DateTimeWithAccuracyCard';
import FontIcons from 'common/FontIcons';
import DateTimeWithAccuracy from 'models/DateTimeWithAccuracy';

interface StartDateCardProps {
    dateTime: DateTimeWithAccuracy | null;
    isLockVisible: boolean;
}

export default (props: Readonly<StartDateCardProps>) => (
    <div className="start-end-date-card">
        <DateTimeWithAccuracyCard dateTime={props.dateTime} dateType={DateTimeWithAccuracyType.Start} />
        {
            props.isLockVisible &&
            <div className="font-icon lock" title="Start date is calculated based on due date and duration">
                {FontIcons.iconLock}
            </div>
        }
    </div>
)