import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import AcceptPage from 'components/pages/AcceptPage';
import LinkPage from 'components/pages/LinkPage';
import ProfilePage from 'components/pages/ProfilePage';
import ProjectPage from 'components/pages/ProjectPage';
import TaskPage from 'components/pages/TaskPage';

class TaskPageRoutes extends React.Component<any, any> {
    public render() {
        return <Switch>
            <Route exact path='/tasks' component={TaskPage} />
            <Route path='/tasks/:id' component={TaskPage} />
        </Switch>;
    }
}

export const routes = <Route path='/' component={() => (
    <Layout>
        <Route exact path='/' component={ProjectPage} />
        <Route exact path='/accept/:membershipId' component={AcceptPage} />
        <Route exact path='/link/:type/:id' component={LinkPage} />
        <Route exact path='/profile' component={ProfilePage} />
        <Route path='/projects/:projectId/:tab' component={ProjectPage} />
        <Route path='/tasks' component={TaskPageRoutes} />
    </Layout>
)} />
