import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Dialog, DialogType } from '@fluentui/react/lib/Dialog';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Icon } from '@fluentui/react/lib/Icon';
import { ApplicationState } from 'store';
import * as TasksStore from 'store/Tasks';
import { IconButton } from '@fluentui/react/lib/Button';
import { GrayLighter } from '../../common/Colors';

interface Props {
    orders: { [prop: string]: TasksStore.Order } | undefined;
}

interface State {
    orders: { [prop: string]: TasksStore.Order } | null;
}

const allOptions: IDropdownOption[] = [
    { key: 'endTime', text: 'Due date', data: { icon: 'Calendar' } },
    { key: 'startTime', text: 'Start date', data: { icon: 'Calendar' } },
    { key: 'stage', text: 'Task stage', data: { icon: 'CheckMark' } },
    { key: 'me', text: 'Me first', data: { icon: 'Contact' } },
    { key: 'assignee', text: 'Assignee', data: { icon: 'People' } },
    { key: 'project', text: 'Project & Group', data: { icon: 'Folder' } },
    { key: 'milestone', text: 'Milestone', data: { icon: 'WaitlistConfirm' } },
    { key: 'priority', text: 'Priority', data: { icon: 'Flag' } },
    { key: 'description', text: 'Description', data: { icon: 'Font' } }
]

const StyledIcon = styled(Icon)`
margin-right: 8px;
`

const StyledRemove = styled(IconButton)`
float: right;
`

const renderTitle = (option: IDropdownOption, onRemove: () => void): JSX.Element => (
    <div className="dropdownExample-option">
        {option.data && option.data.icon && (
            <StyledIcon iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
        )}
        <span>{option.text}</span>
        <StyledRemove iconProps={{ iconName: 'StatusCircleErrorX' }} title='Remove' onClick={() => onRemove()} />
    </div>
)

const renderOption = (option: IDropdownOption): JSX.Element => (
    <div className="dropdownExample-option">
        {option.data && option.data.icon && (
            <Icon style={{ marginRight: '8px' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
        )}
        <span>{option.text}</span>
    </div>
)

const renderContent = (orders: { [prop: string]: TasksStore.Order }, setState: any) => (
    <React.Fragment>
        {Object.keys(orders).map(prop => {
            var options = allOptions.filter(o => o.key.toString() === prop || !orders[o.key.toString()])

            return (
                <StyledOrder key={prop}>
                    <Dropdown
                        options={options}
                        onRenderTitle={option => renderTitle((option as IDropdownOption[])[0], () => {
                            let newOrders = { ...orders };
                            delete (newOrders[prop]);
                            setState({ orders: newOrders });
                        })}
                        onRenderOption={option => renderOption(option as IDropdownOption)}
                        selectedKey={prop}
                        onChange={(_, option) => {
                            if (option) {
                                let newOrders = { ...orders };
                                delete (newOrders[prop]);
                                newOrders[option.key.toString()] = 'ASC';
                                setState({ orders: newOrders });
                            }
                        }}
                    />
                    <StyledSwapButton iconProps={{ iconName: orders[prop] === 'ASC' ? 'Ascending' : 'Descending' }}
                        onClick={() => {
                            let newOrders = { ...orders };
                            newOrders[prop] = orders[prop] === 'ASC' ? 'DESC' : 'ASC';
                            setState({ orders: newOrders });
                        }}
                    />
                </StyledOrder>
            )
        })}
        <Dropdown placeholder="Select a sort option"
            options={allOptions.filter(o => !orders[o.key.toString()])}
            onRenderTitle={option => renderOption((option as IDropdownOption[])[0])}
            onRenderOption={option => renderOption(option as IDropdownOption)}
            selectedKey={null}
            onChange={(_, option) => {
                if (_.type !== 'focus' && option) {
                    let newOrders = { ...orders };
                    newOrders[option.key.toString()] = 'ASC';
                    setState({ orders: newOrders })
                }
            }}
        />
    </React.Fragment>
)

const SortOrderDialog = (props: Readonly<Props & ComponentProps> & typeof TasksStore.actionCreators) => {
    let [state, setState] = React.useState<State>({ orders: null });

    const { orders, projectId, requestSortOrders } = props;
    React.useEffect(() => {
        if (orders === undefined) {
            requestSortOrders(projectId);
        } else if (state.orders === null) {
            setState({ orders: orders });
        }
    }, [orders, projectId, requestSortOrders, state.orders])

    return (
        <Dialog hidden={props.hidden}
            onDismiss={() => {
                state.orders !== null && props.applySortOrders(props.projectId, state.orders);
                props.onDismiss();
            }}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'SORT TASKS'
            }}>

            {state.orders && renderContent(state.orders, setState)}
        </Dialog>
    )
}

const StyledOrder = styled.div`
display: grid;
grid-template-columns: 1fr auto;
`

const StyledSwapButton = styled(IconButton)`
background-color: ${GrayLighter};
`

interface ComponentProps {
    projectId: string | null;
    hidden: boolean;
    onDismiss: () => void;
}

export default connect(
    (state: ApplicationState, componentProps: ComponentProps) => {
        return {
            orders: TasksStore.getOrdersByProjectId(state.tasks, componentProps.projectId)
        }
    },
    dispatch => bindActionCreators({
        ...TasksStore.actionCreators
    }, dispatch)
)(SortOrderDialog)