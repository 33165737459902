import * as React from 'react';
import { TaskPriority } from 'models';
import FontIcons, { FontIcon } from 'common/FontIcons';
import { IconButton } from '@fluentui/react/lib/Button';
import styled, { css } from 'styled-components';
import { Orange } from 'common/Colors';

interface TaskPagePriorityProps {
    priority: TaskPriority;
    handlePriorityChange: (priority: TaskPriority) => void;
}

export default (props: Readonly<TaskPagePriorityProps>) => (
    <IconButton className="btn-background-transparent"
        onClick={() => {
            let newPriority = props.priority === TaskPriority.High
                ? TaskPriority.Mid : TaskPriority.High;

            props.handlePriorityChange(newPriority);
        }}>
        {props.priority === TaskPriority.High ?
            <StyledPriority priority="high">{FontIcons.iconFlagFill}</StyledPriority> :
            <StyledPriority>{FontIcons.iconFlag}</StyledPriority>
        }
    </IconButton>
)

interface StyledPriorityProps {
    priority?: string;
}

const StyledPriority = styled.span<StyledPriorityProps>`
${FontIcon}
font-size: 18px;
${props => props.priority === "high" && css`color: ${Orange};`}
`;