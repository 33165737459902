import * as React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import Scrollbars from 'react-custom-scrollbars'
import styled from 'styled-components';

import NavUserProfile from './NavUserProfile';
import ProjectList from './ProjectList';
import { ProjectModel } from 'models';
import { UserInfoState } from 'store/Login';

import { SidebarSize } from './Layout';

interface Props {
    size: SidebarSize;
    userInfo: UserInfoState | null;
    projects: ProjectModel[] | null;
}

export default (props: Readonly<Props>) => (
    <div className={classnames({
        'main-nav': true,
        'minimal': props.size === SidebarSize.minimal
    })}>
        {
            props.userInfo &&
            <StyledNavProfile>
                <NavLink to={'/profile'}>
                    <NavUserProfile userInfo={props.userInfo} />
                </NavLink>
            </StyledNavProfile>
        }
        <Scrollbars renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: 'white', opacity: 0.5 }} />} autoHide>
            <ProjectList size={props.size} projects={props.projects} />
        </Scrollbars>
    </div>
)

const StyledNavProfile = styled.div`
margin: 8px;
flex: 0 0 auto;
`;