import "url-search-params-polyfill";
import { injectable, inject } from "inversify";
import moment from 'moment';

import { ServiceConstants } from './ServiceConstants';
import { vSoftLoginClient } from './vSoftLoginClient';
import { ProjectModel, TaskGroupModel, MilestoneModel, TaskModel, UserProfileModel, MembershipModel, MembershipDetailsModel } from '../models';

@injectable()
export class ProjectStudioAPI {
    baseUrl: string;
    @inject(vSoftLoginClient) vSoftLoginClient: vSoftLoginClient = new vSoftLoginClient();

    constructor(/*@inject(vSoftLoginClient) vSoftLoginClient: vSoftLoginClient*/) {
        this.baseUrl = ServiceConstants.PROJECTSTUDIO_API_BASEURL;
        //this.vSoftLoginClient = vSoftLoginClient;
    }

    // #region Common

    private async get<T>(url: string, preventRefresh?: boolean): Promise<T> {
        let loginResult = this.vSoftLoginClient.loginFromCache();

        if (loginResult) {
            let response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + loginResult.access_token
                }
            });

            if (response.ok) {
                let result = await response.json() as T;
                return result;
            } else if (response.status === 401 && !preventRefresh) {
                // Try to refresh one time
                loginResult = await this.vSoftLoginClient.refresh(loginResult);

                return await this.get<T>(url, true);
            } else {
                let text = await response.text();
                throw new HttpException(response.status, text);
            }
        } else {
            // TODO:
            throw new HttpException(401, "No login information");
        }
    }

    private async post(url: string, body?: any, additionalHeaders?: { [key: string]: string }, preventRefresh?: boolean): Promise<string> {
        var loginResult = this.vSoftLoginClient!.loginFromCache();

        if (loginResult) {
            var response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + loginResult.access_token,
                    'Content-Type': 'application/json',
                    ...(additionalHeaders || {})
                },
                body: body === undefined ? body : JSON.stringify(body),
                method: 'post'
            });

            if (response.ok) {
                return await response.text();
            } else if (response.status === 401 && !preventRefresh) {
                // Try to refresh one time
                loginResult = await this.vSoftLoginClient.refresh(loginResult);

                return await this.post(url, body, additionalHeaders, true);
            } else {
                var text = await response.text();
                throw new HttpException(response.status, text);
            }
        } else {
            // TODO:
            throw new HttpException(401, "Token expired");
        }
    }

    private async postJSON<T>(url: string, body?: any, additionalHeaders?: { [key: string]: string }, preventRefresh?: boolean): Promise<T> {
        var loginResult = this.vSoftLoginClient.loginFromCache();

        if (loginResult) {
            var response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + loginResult.access_token,
                    'Content-Type': 'application/json',
                    ...(additionalHeaders || {})
                },
                body: body === undefined ? body : JSON.stringify(body),
                method: 'post'
            });

            if (response.ok) {
                return await response.json() as T;
            } else if (response.status === 401 && !preventRefresh) {
                // Try to refresh one time
                loginResult = await this.vSoftLoginClient.refresh(loginResult);

                return await this.postJSON<T>(url, body, additionalHeaders, true);
            } else {
                var text = await response.text();
                throw new HttpException(response.status, text);
            }
        } else {
            // TODO:
            throw new HttpException(401, "Token expired");
        }
    }

    private async delete<T>(url: string, additionalHeaders?: { [key: string]: string }, preventRefresh?: boolean): Promise<T> {
        var loginResult = this.vSoftLoginClient.loginFromCache();

        if (loginResult) {
            var response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + loginResult.access_token,
                    ...(additionalHeaders || {})
                },
                method: 'delete'
            });

            if (response.ok) {
                return await response.json() as T;
            } else if (response.status === 401 && !preventRefresh) {
                // Try to refresh one time
                loginResult = await this.vSoftLoginClient.refresh(loginResult);

                return await this.delete<T>(url, additionalHeaders, true);
            } else {
                var text = await response.text();
                throw new HttpException(response.status, text);
            }
        } else {
            // TODO:
            throw new HttpException(401, "Token expired");
        }
    }

    // #endregion

    // #region Projects

    public getProjects(): Promise<ProjectModel[]> {
        return this.get<ProjectModel[]>(this.baseUrl + "/v1/projects");
    }

    public getProject(projectId: string): Promise<ProjectModel> {
        return this.get<ProjectModel>(this.baseUrl + "/v1/projects/" + projectId);
    }

    // #endregion

    // #region Tasks

    public getTasks(projectId?: string | null): Promise<TaskModel[]> {
        var url = this.baseUrl + "/v1/tasks";

        if (projectId === null) {
            url += "?projectId=00000000-0000-0000-0000-000000000000";
        }
        else if (projectId) {
            url += "?projectId=" + projectId;
        }

        return this.get<TaskModel[]>(url);
    }

    public getTask(taskId: string): Promise<TaskModel> {
        return this.get<TaskModel>(this.baseUrl + "/v1/tasks/" + taskId);
    }

    public async addTask(model: TaskModel): Promise<TaskModel> {
        var task = await this.postJSON<TaskModel>(this.baseUrl + "/v1/tasks", model);
        this.adjustTasks([task]);
        return task;
    }

    public async updateTask(model: TaskModel): Promise<TaskModel> {
        var task = await this.postJSON<TaskModel>(this.baseUrl + "/v1/tasks/" + model.id, model, { 'X-If-Unmodified-Since': model.updatedDateTime.toString() });
        this.adjustTasks([task]);
        return task;
    }

    public deleteTask(model: TaskModel) {
        return this.delete(this.baseUrl + "/v1/tasks/" + model.id, { 'X-If-Unmodified-Since': model.updatedDateTime.toString() })
    }

    // #endregion

    // #region Task Groups

    public getTaskGroups(projectId: string): Promise<TaskGroupModel[]> {
        return this.get<TaskGroupModel[]>(this.baseUrl + "/v1/taskgroups/" + projectId);
    }

    public getTaskGroup(taskGroupId: string, projectId: string): Promise<TaskGroupModel> {
        return this.get<TaskGroupModel>(this.baseUrl + "/v1/taskgroups/" + projectId + "/" + taskGroupId);
    }

    // #endregion

    // #region Milestone

    public getMilestones(projectId: string): Promise<MilestoneModel[]> {
        return this.get<MilestoneModel[]>(this.baseUrl + "/v1/milestones/" + projectId);
    }

    public getMilestone(milestoneId: string, projectId: string): Promise<MilestoneModel> {
        return this.get<MilestoneModel>(this.baseUrl + "/v1/milestones/" + projectId + "/" + milestoneId);
    }

    // #endregion

    // #region Membership

    public getMembership(membershipId: string): Promise<MembershipModel> {
        return this.get<MembershipModel>(this.baseUrl + "/v1/memberships/" + membershipId);
    }

    public getMembershipDetails(membershipId: string): Promise<MembershipDetailsModel> {
        return this.get<MembershipDetailsModel>(this.baseUrl + "/v1/memberships/details/" + membershipId);
    }

    // #endregion

    // #region Profile

    public getProfile(): Promise<UserProfileModel> {
        return this.get<UserProfileModel>(this.baseUrl + "/v1/profiles/me");
    }

    // #endregion

    // #region Invitation

    public async acceptInvitation(membershipId: string) {
        return this.post(this.baseUrl + "/v1/memberships/accept/" + membershipId);
    }

    // #endregion

    private adjustTasks(tasks: TaskModel[]) {
        tasks.forEach(task => {
            if (typeof task.duration === "string") {
                task.duration = moment.duration(task.duration).asSeconds();
            }
        });
    }
}

export class HttpException {
    constructor(status: number, content: string) {
        this.status = status;
        this.content = content;
    }

    status: number;
    content: string;
}