import moment from 'moment';

export default class Dates {
    now: Date;
    today: Date;
    todayPlus1: Date;
    todayPlus2: Date;
    todayMinus1: Date;
    thisWeek: Date;
    thisWeekPlus1: Date;
    thisWeekPlus2: Date;

    static MinDate: Date = new Date(-8640000000000000);
    static MaxDate: Date = new Date(8640000000000000);

    private momentMoment: moment.Moment;

    constructor() {
        this.momentMoment = moment().local();

        this.now = this.momentMoment.clone().toDate();

        this.today = this.momentMoment.clone().startOf('day').toDate();
        this.todayPlus1 = this.momentMoment.clone().startOf('day').add(1, 'day').toDate();
        this.todayPlus2 = this.momentMoment.clone().startOf('day').add(2, 'day').toDate();
        this.todayMinus1 = this.momentMoment.clone().startOf('day').subtract(1, 'day').toDate();

        this.thisWeek = this.momentMoment.clone().startOf('week').toDate();
        this.thisWeekPlus1 = this.momentMoment.clone().startOf('week').add(1, 'week').toDate();
        this.thisWeekPlus2 = this.momentMoment.clone().startOf('week').add(2, 'week').toDate();
    }

    todayPlus(days: number): Date {
        return this.momentMoment.clone().startOf('day').add(days, 'day').toDate();
    }

    thisWeekPlus(weeks: number): Date {
        return this.momentMoment.clone().startOf('week').add(weeks, 'week').toDate();
    }
}