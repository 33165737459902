import { createStore, applyMiddleware, compose, combineReducers, Store, StoreEnhancerStoreCreator, ReducersMapObject, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import * as StoreModule from './store';
import { History } from 'history';

export default function configureStore(history: History, initialState?: StoreModule.ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;

    // If devTools is installed, connect to it
    const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer;
    const createStoreWithMiddleware = compose<StoreEnhancerStoreCreator<StoreModule.ApplicationState>>(
        applyMiddleware(
            thunk,
            routerMiddleware(history),
            //logger
        ),
        devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next
    )(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = buildRootReducer(StoreModule.reducers(history));
    const store = createStoreWithMiddleware(allReducers, initialState) as Store<StoreModule.ApplicationState>;

    // Enable Webpack hot module replacement for reducers
    //if (module.hot) {
    //    module.hot.accept('./store', () => {
    //        const nextRootReducer = require<typeof StoreModule>('./store');
    //        store.replaceReducer(buildRootReducer(nextRootReducer.reducers(history)));
    //    });
    //}

    return store;
}

function buildRootReducer(allReducers: ReducersMapObject) {
    return combineReducers<StoreModule.ApplicationState>(allReducers);
}
