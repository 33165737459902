import styled from 'styled-components';
import { PagePadding, WidthThreshold } from 'common/LayoutConstants';

export const StyledContent = styled.div`
padding-left: ${PagePadding}px;
padding-right: ${PagePadding}px;
margin-top: 8px;
transition: padding 0.1s ease;

@media (max-width: ${WidthThreshold}px) {
    padding-left: 0;
    padding-right: 0;
}
`