import { Action, Reducer } from 'redux';
import { container } from "../inversify.config";
import { AppThunkAction } from './';

import * as ActionTypes from '../common/ActionTypes';
import { ProjectStudioAPI } from '../logics/ProjectStudioAPI';
import { MilestoneModel } from '../models';

export interface MilestonesState {
    milestones: { [milestoneId: string]: MilestoneModel };
    milestoneIdsByProject: { [projectId: string]: string[] };
    loadingProjects: { [projectId: string]: boolean };
    loadingMilestones: { [milestoneId: string]: boolean };
}

// ----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface RequestMilestoneAction {
    type: typeof ActionTypes.MILESTONE_REQUEST;
    milestoneId: string;
}

export interface ReceiveMilestoneAction {
    type: typeof ActionTypes.MILESTONE_RECEIVE;
    milestoneId: string;
    milestone: MilestoneModel;
}

export interface RequestMilestonesAction {
    type: "REQUEST_MILESTONE_LIST";
    projectId: string;
}

export interface ReceiveMilestonesAction {
    type: "RECEIVE_MILESTONE_LIST";
    projectId: string;
    milestones: MilestoneModel[];
}

type KnownAction =
    RequestMilestoneAction | ReceiveMilestoneAction |
    RequestMilestonesAction | ReceiveMilestonesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestMilestones: (projectId: string, forceRefresh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState();

        let milestones = state.milestones.milestoneIdsByProject[projectId];
        let isLoading = state.milestones.loadingProjects[projectId];

        if (forceRefresh || (!isLoading && !milestones)) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);

            let fetchData = client.getMilestones(projectId);
            dispatch({ type: "REQUEST_MILESTONE_LIST", projectId: projectId });

            let milestones = await fetchData;
            dispatch({ type: "RECEIVE_MILESTONE_LIST", projectId: projectId, milestones: milestones });
        }
    },
    requestMilestone: (milestoneId: string, projectId: string, forceRefresh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState();

        let milestone = state.milestones.milestones && state.milestones.milestones[milestoneId];
        let isLoading = state.milestones.loadingMilestones && state.milestones.loadingMilestones[milestoneId];

        if (forceRefresh || (!isLoading && !milestone)) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);

            let fetchMilestone = client.getMilestone(milestoneId, projectId);
            dispatch({ type: ActionTypes.MILESTONE_REQUEST, milestoneId: milestoneId });

            let milestone = await fetchMilestone;
            dispatch({ type: ActionTypes.MILESTONE_RECEIVE, milestoneId: milestoneId, milestone: milestone });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: MilestonesState = {
    milestones: {},
    milestoneIdsByProject: {},
    loadingProjects: {},
    loadingMilestones: {}
};

export const reducer: Reducer<MilestonesState> = (state: MilestonesState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.MILESTONE_REQUEST:
            return {
                ...state,
                loadingMilestones: {
                    ...state.loadingMilestones,
                    [action.milestoneId]: true
                }
            };
        case ActionTypes.MILESTONE_RECEIVE:
            return {
                ...state,
                milestones: {
                    ...state.milestones,
                    [action.milestoneId]: action.milestone
                },
                loadingMilestones: {
                    ...state.loadingMilestones,
                    [action.milestoneId]: false
                }
            };


        case "REQUEST_MILESTONE_LIST":
            return {
                ...state,
                loadingProjects: {
                    ...state.loadingProjects,
                    [action.projectId]: true
                }
            }

        case "RECEIVE_MILESTONE_LIST":
            return {
                ...state,
                milestones: {
                    ...state.milestones,
                    ...action.milestones.reduce((m: { [id: string]: MilestoneModel }, o) => { m[o.id] = o; return m }, {})
                },
                milestoneIdsByProject: {
                    ...state.milestoneIdsByProject,
                    [action.projectId]: action.milestones.map(o => o.id)
                },
                loadingProjects: {
                    ...state.loadingProjects,
                    [action.projectId]: false
                }
            }
    }

    return state || initialState;
}

// ----------------
// SELECTOR