import styled from 'styled-components';
import { PagePadding } from 'common/LayoutConstants';

interface Props {
    padding?: number;
}

export default styled.h3<Props>`
margin: 8px 0;
padding: 0 ${props => props.padding === undefined ? PagePadding : props.padding}px;

.ms-TextField-fieldGroup {
    min-height: 36px;
}

input[type=text] {
    padding: 2px 8px;
    font-size: 20px;
    line-height: 1em;
    width: 100%;
}
`