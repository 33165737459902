import * as React from 'react';
import styled from 'styled-components';
import { ProjectStatus } from 'models';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Gray, GrayLight, GrayDark, Blue, BlueLight, BlueDark } from 'common/Colors';
import SectionTitle from 'components/common/SectionTitle';

interface Props {
    status: ProjectStatus;
    onStatusChange: (status: ProjectStatus) => void;
}

export default (props: Readonly<Props>) => (
    <React.Fragment>
        <SectionTitle>
            STATUS
        </SectionTitle>
        <StyledList>
            <StyledListItem active={props.status === ProjectStatus.Active}>
                <svg className="mid-arrow-start"><path d="M0 0 L12 12 L0 24 L 14 24 L 14 0 Z" /></svg>
                <DefaultButton className="btn-background-transparent"
                    onClick={() => props.onStatusChange(ProjectStatus.Active)}>
                    Active
                </DefaultButton>
                <svg className="mid-arrow-end"><path d="M0 0 L2 0 L14 12 L2 24 L0 24 Z" /></svg>
            </StyledListItem>
            <StyledListItem active={props.status === ProjectStatus.Closed}>
                <svg className="mid-arrow-start"><path d="M0 0 L12 12 L0 24 L 14 24 L 14 0 Z" /></svg>
                <DefaultButton className="btn-background-transparent"
                    onClick={() => props.onStatusChange(ProjectStatus.Active)}>
                    Closed
                </DefaultButton>
                <svg className="mid-arrow-end"><path d="M0 0 L2 0 L14 12 L2 24 L0 24 Z" /></svg>
            </StyledListItem>
        </StyledList>
    </React.Fragment>
)

interface StyledListItemProps {
    active: boolean;
}

const StyledListItem = styled.div<StyledListItemProps>`
display: flex;
flex-direction: row;
align-items: stretch;
flex: 0 0 auto;

svg, button {
    height: 24px;
}

svg {
    fill: ${props => props.active ? Blue : Gray};
    width: 14px;
    flex: 0 0 auto;
}

button {
    background: ${props => props.active ? Blue : Gray};
    color: white;
    padding: 2px 8px 2px 8px;
    font-weight: normal;
    flex: 0 0 auto;

    &:hover {
        color: white;
        background: ${props => props.active ? BlueLight : GrayLight};
    }

    &:active {
        color: white;
        background: ${props => props.active ? BlueDark : GrayDark};
    }

    .ms-Button-label {
        font-weight: normal !important;
    }
}

&:hover svg {
    fill: ${props => props.active ? BlueLight : GrayLight};
}

&:active svg {
    fill: ${props => props.active ? BlueDark : GrayDark};
}

svg.mid-arrow-start {
    margin-left: -10px;
    margin-right: -2px;
}

svg.mid-arrow-end {
    margin-left: -2px;
}

&:first-child svg.mid-arrow-start, &:last-child svg.mid-arrow-end {
    display: none;
}
`

const StyledList = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
overflow-x: auto;
margin-top: 8px;
margin-right: -24px;
padding-right: 24px;
`;