import * as React from 'react';

interface NumberIndicatorProps {
    number: number;
    backgroundStyle?: any;
}

export default (props: Readonly<NumberIndicatorProps>) => {
    let numberBackgroundStyle = props.backgroundStyle
        ?
        {
            background: props.backgroundStyle
        }
        :
        {
        };


    let numberString = props.number.toString();
    let innerClassName = "";
    if (props.number >= 100) {
        numberString = "99+";
        innerClassName = "small";
    }

    return <div className="number-indicator" style={numberBackgroundStyle}>
        <div className={innerClassName}>
            {numberString}
        </div>
    </div>;
}