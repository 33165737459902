import * as React from 'react';
import styled from 'styled-components';
import { UserModel } from 'models';
import UserCardCircle from 'components/users/UserCardCircle';
import { IconButton } from '@fluentui/react/lib/Button';
import SectionTitle from 'components/common/SectionTitle';
import SectionContent from 'components/common/SectionContent';

interface TaskPageAssigneesProps {
    assignees: UserModel[];
    creator: UserModel | null;
    handleAssigneeRemove: (assigneeId: string) => void;
}

export default (props: Readonly<TaskPageAssigneesProps>) => (
    <React.Fragment>
        <SectionTitle>ASSIGNEES</SectionTitle>
        <StyledSectionContent>
            <IconButton className="icon-btn-circle-card" iconProps={{ iconName: 'Add' }} />
            {!!props.assignees.length && props.assignees.map((assignee, index) => {
                return <div key={index} className="position-relative">
                    <UserCardCircle displayName={assignee && assignee.displayName} avatarUrl={assignee && assignee.avatarUrl} />
                    <IconButton className="icon-btn-circle-card-option red" iconProps={{ iconName: 'Cancel' }} onClick={() => props.handleAssigneeRemove(assignee.id)} />
                </div>;
            })}
        </StyledSectionContent>
        {props.creator &&
            <React.Fragment>
                <SectionTitle>CREATOR</SectionTitle>
                <StyledSectionContent>
                    <div className="position-relative">
                        <UserCardCircle displayName={props.creator.displayName} avatarUrl={props.creator.avatarUrl} />
                    </div>
                </StyledSectionContent>
            </React.Fragment>
        }
    </React.Fragment>
)

const StyledSectionContent = styled(SectionContent)`
display: flex;
flex-direction: row;

& > * {
    margin-right: 8px;
}
`;