import * as React from 'react';
import * as $ from 'jquery';

declare global {
    interface Window {
        mspb: any;// (a: string, (badge: any) => void) => void;
    }
}

export default React.memo(() => {
    React.useEffect(() => {
        $.getScript('https://storebadge.azureedge.net/src/badge-1.8.1.js', () => {
            window.mspb('9nblggh3st8b', function (badge: any) { var element = document.getElementById('mspb-9nblggh3st8b'); if (element) element.innerHTML = badge; })
        })
    });

    return <React.Fragment>
        <div id="mspb-9nblggh3st8b" className="9nblggh3st8b" style={{ minHeight: 216 }}></div>
    </React.Fragment>
})