import * as React from 'react';
import styled from 'styled-components';
import { UserInfoState } from 'store/Login';
import PremiumLabel from 'components/common/PremiumLabel';

interface Props {
    userInfo: UserInfoState;
}

export default (props: Readonly<Props>) => (
    <StyledWrapper>
        <div className="nav-avatar-wrapper">
            <StyledAvatar src={props.userInfo.avatarUrl} />
        </div>
        <div className="nav-profile-details">
            <StyledName>{props.userInfo.displayName}</StyledName>
            <StyledEmail>{props.userInfo.email}</StyledEmail>
            {props.userInfo.isPremium && <PremiumLabel />}
        </div>
    </StyledWrapper>
)

const StyledWrapper = styled.div`
display: flex;
align-items: center;
`;

const StyledAvatar = styled.img`
width: 100%;
height: 100%;
`;

const StyledName = styled.div`
font-size: 18px;
`;

const StyledEmail = styled.div`
font-size: 12px;
margin-top: -2px;
margin-bottom: 4px;
`;