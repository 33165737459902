import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { container } from "inversify.config";
import * as Store from 'store';
import * as Login from 'store/Login';
import { ProjectStudioAPI } from 'logics/ProjectStudioAPI';
import { vSoftLoginClient } from 'logics/vSoftLoginClient';
import StyledPageTitle from 'components/common/StyledPageTitle';
import PremiumLabel from 'components/common/PremiumLabel';

type ProfilePageProps = Login.LoginState & RouteComponentProps<{}>;

class ProfilePage extends React.Component<ProfilePageProps> {
    vSoftLoginClient: vSoftLoginClient;
    projectStudioClient: ProjectStudioAPI;

    constructor(props: ProfilePageProps) {
        super(props);

        this.vSoftLoginClient = container.get<vSoftLoginClient>(vSoftLoginClient);
        this.projectStudioClient = container.get<ProjectStudioAPI>(ProjectStudioAPI);
    }

    logout = async () => {
        this.vSoftLoginClient.logout();
        this.props.history.push('/');
        window.location.reload(true);
    }

    public render() {
        return this.props.userInfo && (
            <React.Fragment>
                <StyledPageTitle>Profile</StyledPageTitle>

                <StyledWrapper>
                    <div style={{
                        width: 150,
                        height: 150,
                        overflow: 'hidden',
                        borderRadius: '50%',
                        marginRight: 15
                    }}>
                        <img src={this.props.userInfo.avatarUrl} style={{ width: 150, height: 150 }} alt="Profile" />
                    </div>

                    <div style={{ flexBasis: 'content', width: 500 }}>
                        <div>
                            <StyledLabel>Username:</StyledLabel> <StyledValue>{this.props.userInfo.userName}</StyledValue>
                        </div>
                        <div>
                            <StyledLabel>Full Name:</StyledLabel> <StyledValue>{this.props.userInfo.displayName}</StyledValue>
                        </div>
                        <div>
                            <StyledLabel>Email Address:</StyledLabel> <StyledValue>{this.props.userInfo.email}</StyledValue>
                        </div>
                        <div>
                            <StyledLabel>Account:</StyledLabel>
                            {this.props.userInfo.isPremium ? <PremiumLabel /> : <PremiumLabel isBasic={true} />}
                        </div>
                        {this.props.userInfo.isPremium &&
                            <div>
                                <StyledLabel>Premium Expiration:</StyledLabel> <StyledValue>{moment(this.props.userInfo.premiumExpiration).format('l')}</StyledValue>
                            </div>
                        }
                        <div>
                            <StyledLabel>Linked Services:</StyledLabel>
                        </div>
                        <a className="btn btn-primary" href="https://account.vsoftstudio.com" target="_blank" style={{ marginRight: 5 }} rel="noreferrer"><span className="glyphicon glyphicon-pencil" aria-hidden="true"></span> Edit profile</a>
                        <button className="btn btn-danger" onClick={this.logout}><span className="glyphicon glyphicon-ban-circle" aria-hidden="true"></span> Sign out</button>
                    </div>
                </StyledWrapper>
            </React.Fragment>
        );
    }
}

const StyledWrapper = styled.div`
display: flex;
flex-flow: row wrap;
margin: 0 15px;
`;

const StyledLabel = styled.div`
display: inline-block;
width: 130px;
color: gray;
margin-bottom: 8px;
`;

const StyledValue = styled.div`
display: inline-block;
font-weight: bold;
margin-bottom: 8px;
`;

export default connect(
    (state: Store.ApplicationState) => state.login
)(ProfilePage);