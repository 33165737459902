import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink as Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { GrayLighterLighter } from 'common/Colors';
import TaskListItemCheckbox from './TaskListItemCheckbox';
import Assignees from 'components/Assignees';
import TaskPagePriority from 'components/tasks/TaskPagePriority';
import ProjectAndMilestoneIndicator from './ProjectAndMilestoneIndicator';

import { ProjectModel, TaskGroupModel, TaskModel, MilestoneModel } from 'models';
import * as Store from 'store';

interface TaskListItemProps {
    task: TaskModel,
    project: ProjectModel | null;
    taskGroup: TaskGroupModel | null;
    milestone: MilestoneModel | null;
}

type Props = TaskListItemProps & TaskListItemComponentProps;

const TaskListItem = (props: Readonly<Props>) => {

    return (
        <StyledItem style={props.style} alternate={props.index % 2 === 0}>
            <StyledLink to={"/tasks/" + props.task.id}>
                <TaskListItemCheckbox task={props.task} project={props.project} />
                <StyledDescription>{props.task.description}</StyledDescription>
                {props.project && (
                    <StyledProjectWrapper>
                        <ProjectAndMilestoneIndicator project={props.project} milestone={props.milestone} taskGroup={props.taskGroup} />
                    </StyledProjectWrapper>
                )}
                <StyledTaskPagePriorityWrapper>
                    <TaskPagePriority priority={props.task.priority}
                        handlePriorityChange={priority => { /*TODO*/ }} />
                </StyledTaskPagePriorityWrapper>
                <Assignees taskId={props.taskId} />
                <div className="clear-fix"></div>
            </StyledLink>
        </StyledItem>
    );
}

const StyledItem = styled.li<any>`
${props => props.alternate && css`background-color: ${GrayLighterLighter}`}
`

const StyledLink = styled(Link)`
padding: 5px 15px;
display: block;
height: 47px;
clear: both;
position: relative;

&:hover {
    text-decoration: none;
    background: #95C5EB;
}
`

const StyledDescription = styled.div`
display: block;
margin-left: 44px;
margin-top: -2px;
margin-right: 60px;
color: black;
font-size: 1.1em;
white-space: nowrap;
overflow: hidden;
word-wrap: break-word;
text-overflow: ellipsis;
height: 22px;
width: calc(100% - 110px);
`

const StyledProjectWrapper = styled.span`
margin-left: 10px;
`

const StyledTaskPagePriorityWrapper = styled.div`
position: absolute;
top: 8px;
right: 42px;
`

interface TaskListItemComponentProps {
    taskId: string;
    style: any;
    index: number;
}

export default connect(
    (state: Store.ApplicationState, ownProps: TaskListItemComponentProps | undefined) => {
        if (ownProps) {
            var task = state.tasks.tasks[ownProps.taskId];
            var result: TaskListItemProps = {
                task: task,
                project: (task && task.projectId && state.projects.projects) ? state.projects.projects[task.projectId] : null,
                taskGroup: (task && task.taskGroupId) ? state.taskGroups.taskGroups[task.taskGroupId] : null,
                milestone: (task && task.milestoneId) ? state.milestones.milestones[task.milestoneId] : null
            };
            return result;
        }
        throw new Error("TaskListItem must have props!");
    }
)(TaskListItem);