import styled from 'styled-components';

interface Props {
    backgroundColor?: string;
    scale?: number;
}

export default styled.div<Props>`
margin-right: 8px;
color: white;
display: inline-block;
width: ${props => (props.scale || 1) * 30}px;
height: ${props => (props.scale || 1) * 30}px;
line-height: ${props => (props.scale || 1) * 30}px;
font-size: ${props => props.scale || 1}em;
font-family: "Segoe MDL2 Assets", SegoeMDL2;
text-align: center;
background-color: ${props => props.backgroundColor || 'transparent'};
`