import * as React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import styled from 'styled-components';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { ProjectModel } from 'models';
import FontIcons from 'common/FontIcons';
import { AppColorLight, Gray } from 'common/Colors';
import { SidebarSize } from './Layout';
import StyledProjectIcon from 'components/common/StyledProjectIcon';

interface Props {
    size: SidebarSize;
    projects: ProjectModel[] | null;
}

const renderItems = (projects: ProjectModel[]) => projects
    .sort((a, b) => a.title.localeCompare(b.title))
    .map(project =>
        <li key={project.id}>
            <NavLink to={'/projects/' + project.id + '/tasks'} activeClassName='active'>
                <StyledProjectIcon backgroundColor={project.color}>{project.icon}</StyledProjectIcon>
                <span className="project-title">{project.title}</span>
            </NavLink>
        </li>
    )

export default (props: Readonly<Props>) => (
    <StyledList className={classnames({
        'minimal': props.size !== SidebarSize.normal
    })}>
        <li>
            <NavLink exact to={'/'} activeClassName='active'>
                <StyledProjectIcon>{FontIcons.iconMultiSelect}</StyledProjectIcon>
                <span className="project-title">All Tasks</span>
            </NavLink>
        </li>
        <li>
            <StyledSeparator />
        </li>
        <li style={{ display: "flex" }}>
            {props.size === SidebarSize.normal &&
                <StyledNewProjectWrapper>
                    <StyledProjectText>PROJECTS</StyledProjectText>
                    <DefaultButton className="btn-add-project" text="NEW" iconProps={{ iconName: "Add" }} />
                </StyledNewProjectWrapper>}
            {props.size !== SidebarSize.normal &&
                <NavLink exact to={'/projects/'} activeClassName='active'>
                    <StyledProjectIcon>{FontIcons.iconAdd}</StyledProjectIcon>
                    <span className="project-title">New</span>
                </NavLink>}
        </li>
        <li>
            <NavLink to={'/projects/~/tasks'} activeClassName='active'>
                <StyledProjectIcon></StyledProjectIcon>
                <span className="project-title">No project</span>
            </NavLink>
        </li>
        {!!props.projects && renderItems(props.projects)}
    </StyledList>
)

const StyledList = styled.ul`
padding: 0;
list-style: none;
width: 320px;
transition: width 0.2s ease-out;

li {
    font-size: 15px;
    margin: 0px;
    padding: 0px;
    display: flex;
}

li a {
    width: 100%;
    white-space: nowrap; /* If a menu item's text is too long, truncate it */
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px;
    color: white;
}

li a:hover {
    background-color: ${AppColorLight};
    text-decoration: none;
}

li a.active, li a.active:hover, li a.active:focus {
    background-color: ${Gray};
    text-decoration: none;
    color: white;
}

li a span {
    line-height: 28px;
    vertical-align: top;
    text-overflow: ellipsis;
    overflow: hidden;
}
`;

const StyledSeparator = styled.div`
background-color: #444;
height: 2px;
width: 100%;
margin: 0px 8px 0px 8px;
`

const StyledNewProjectWrapper = styled.div`
margin-left: 8px;
margin-right: 8px;
display: flex;
flex: 1 1 auto;
align-items: center;
height: 46px;
`

const StyledProjectText = styled.div`
font-weight: bold;
color: #fff;
flex: 1 1 auto;
`