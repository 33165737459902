import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect, ConnectedComponent } from 'react-redux';
import styled from 'styled-components';
import { container } from 'inversify.config';

import { DefaultButton } from '@fluentui/react';
import { AppColor, AppColorLight, AppColorDark } from 'common/Colors';
import NavMenu from './NavMenu';
import LoginDialog from 'components/dialogs/LoginDialog';
import LoadingPageContent from 'components/common/LoadingPageContent';
import { WidthThreshold } from 'common/LayoutConstants';

import * as Store from 'store';
import { ProjectModel } from 'models';
import * as StoreLogin from 'store/Login';
import * as StoreProjects from 'store/Projects';
import * as StoreShell from 'store/Shell';
import { vSoftLoginClient, LoginResult } from 'logics/vSoftLoginClient';


interface OwnProps {
    login: StoreLogin.LoginState;
    shell: StoreShell.ShellState;

    projects: ProjectModel[] | null;

    loginActions: typeof StoreLogin.actionCreators;
    projectsActions: typeof StoreProjects.actionCreators;
    shellActions: typeof StoreShell.actionCreators;
}

interface State {
    show: boolean;
    sidebarSize: SidebarSize;
    canResizeContent: boolean;
}

type Props = OwnProps;

export enum SidebarSize {
    normal,
    minimal
}

class Layout extends React.Component<Props, State> {
    vSoftLoginClient: vSoftLoginClient;

    constructor(props: Props) {
        super(props);

        this.state = {
            show: false,
            sidebarSize: SidebarSize.normal,
            canResizeContent: true
        }

        this.vSoftLoginClient = container.get<vSoftLoginClient>(vSoftLoginClient);
    }

    componentDidMount() {
        var loginResult = this.vSoftLoginClient.loginFromCache();
        if (loginResult && loginResult.access_token) {
            this.props.loginActions.requestUserInfo();
        }

        this.setState({ show: true });

        this.setSidebarSize();

        window.addEventListener('resize', () => {
            this.setSidebarSize();
        });
    }

    componentDidUpdate() {
        if (this.props.login.isAuthenticated) {
            this.props.projectsActions.requestProjectList(false);
        }
    }

    private setSidebarSize() {
        if (window.innerWidth < WidthThreshold && this.state.canResizeContent) {
            this.setState({ canResizeContent: false });
        } else if (window.innerWidth >= WidthThreshold && !this.state.canResizeContent) {
            this.setState({ canResizeContent: true });
        }

        if (window.innerWidth < WidthThreshold && this.state.sidebarSize === SidebarSize.normal) {
            this.setState({ sidebarSize: SidebarSize.minimal });
        }
        else if (window.innerWidth > 1200 && this.state.sidebarSize === SidebarSize.minimal) {
            this.setState({ sidebarSize: SidebarSize.normal });
        }
    }

    handleLogin = async (loginResult: LoginResult) => {
        this.props.loginActions.requestUserInfo();
    }

    //requireAuth = (nextState, replace) => {
    //    if (this.props.login) {
    //        replace({
    //            pathname: '/',
    //            state: {
    //                nextPathname: nextState.location.pathname,
    //            },
    //        })
    //    }
    //}

    toggleSplitView = () => {
        if (this.state.sidebarSize === SidebarSize.minimal) {
            this.setState({
                sidebarSize: SidebarSize.normal
            });
        } else {
            this.setState({
                sidebarSize: SidebarSize.minimal
            });
        }
    }

    public render() {
        if (!this.state.show) return null;

        if (this.props.login.isLoading) {
            return <LoadingPageContent label='Signing in...' />;
        }

        return (!this.props.login.isAuthenticated ?
            <LoginDialog onLogin={this.handleLogin} />
            :
            <StyledApp>
                <StyledSideBar minimal={this.state.sidebarSize === SidebarSize.minimal}>
                    <NavMenu size={this.state.sidebarSize} projects={this.props.projects} userInfo={this.props.login ? this.props.login.userInfo : null} />
                </StyledSideBar>
                <StyledBody minimal={this.state.sidebarSize === SidebarSize.minimal} canResize={this.state.canResizeContent}>
                    <StyledTopBar>
                        <StyledButton onClick={() => this.toggleSplitView()}>
                            <div>
                                <span className='sr-only'>Menu</span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                            </div>
                        </StyledButton>
                        <StyledTitle>{this.props.shell.pageTitle}</StyledTitle>
                    </StyledTopBar>
                    <StyledContent>{this.props.children}</StyledContent>
                </StyledBody>
            </StyledApp>
        );
    }
}

const FullSidebarWidth = 320;
const MiniSidebarWidth = 46;

const StyledApp = styled.div`
width: 100%;
height: 100%;
`;

const StyledTopBar = styled.div`
height: 40px;
background: ${AppColor};
position: fixed;
width: 100%;
z-index: 1000;
`;

const StyledButton = styled(DefaultButton)`
&, &.ms-Button {
    display: block;
    position: fixed;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    margin: 2px;
    z-index: 500;
    background-color: ${AppColor};
}

&:hover {
    background: ${AppColorLight};
}

&:active {
    background: ${AppColorDark};
}

.icon-bar {
    display: block;
    background-color: white;
    width: 24px;
    height: 1px;
    margin: 5px auto;
}
`;

const StyledSideBar = styled.div<any>`
position: fixed;

order: -1;
display: flex;
flex: 0 0 auto;
width: ${props => props.minimal ? MiniSidebarWidth : FullSidebarWidth}px;
height: 100%;
transition: all 0.2s ease-out;
float: left;
`;

const StyledBody = styled.div<any>`
width: calc(100% - ${props => props.minimal || !props.canResize ? MiniSidebarWidth : FullSidebarWidth}px);
margin-left: ${props => props.minimal ? MiniSidebarWidth : FullSidebarWidth}px;
overflow-x: hidden;
min-height: 100%;
transition: all 0.2s ease-out;
`;

const StyledContent = styled.div`
padding: 40px 0 0 0;
position: relative;
height: 100vh;
`;

const StyledTitle = styled.div`
padding: 1px;
padding-left: 48px;
height: 100%;
color: white;
font-size: 24px;
font-weight: lighter;
`;

export default connect(
    (state: Store.ApplicationState) => {
        return {
            login: state.login,
            projects: StoreProjects.getProjects(state.projects),
            shell: state.shell
        };
    },
    (dispatch) => {
        return {
            loginActions: bindActionCreators(StoreLogin.actionCreators, dispatch),
            projectsActions: bindActionCreators(StoreProjects.actionCreators, dispatch),
            shellActions: bindActionCreators(StoreShell.actionCreators, dispatch),
        }
    }
)(Layout) as ConnectedComponent<typeof Layout, any>;