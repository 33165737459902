import * as React from 'react';
import { RouteComponentProps } from 'react-router'
import { container } from "inversify.config";
import styled from 'styled-components';

import { ProjectStudioAPI, HttpException } from 'logics/ProjectStudioAPI';
import { vSoftLoginClient } from 'logics/vSoftLoginClient';
import { MembershipDetailsModel } from 'models';
import GetApp from 'components/GetApp';
import StyledPageTitle from 'components/common/StyledPageTitle';
import LoadingPageContent from 'components/common/LoadingPageContent';

interface RouteProps {
    membershipId: string;
}

type Props = RouteComponentProps<RouteProps>;

interface State {
    isLoading: boolean;
    membershipDetails?: MembershipDetailsModel;

    isAccepting?: boolean;
}

export default class AcceptPage extends React.PureComponent<Props, State> {
    vSoftLoginClient: vSoftLoginClient;
    projectStudioClient: ProjectStudioAPI;

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            isAccepting: false
        };

        this.vSoftLoginClient = container.get<vSoftLoginClient>(vSoftLoginClient);
        this.projectStudioClient = container.get<ProjectStudioAPI>(ProjectStudioAPI);
    }

    async componentDidMount() {
        if (this.props.match && this.props.match.params.membershipId) {
            var loginResult = this.vSoftLoginClient.loginFromCache();
            if (loginResult && loginResult.access_token) {
                try {
                    this.setState({
                        isLoading: true
                    });
                    var membershipDetails = await this.projectStudioClient.getMembershipDetails(this.props.match.params.membershipId);
                    this.setState({
                        isLoading: true,
                        membershipDetails: membershipDetails
                    });
                }
                finally {
                    this.setState({
                        isLoading: false
                    });
                }
            }
        }
    }

    public static readonly MaximumFreeMembers = 2;
    public static readonly MaximumProjectsForFreeAccount = 5;

    accept = async () => {
        if (this.state.membershipDetails) {
            try {
                this.setState({
                    isLoading: false,
                    isAccepting: true
                });

                try {
                    await this.projectStudioClient.acceptInvitation(this.state.membershipDetails.id);
                    window.location.reload(true);
                } catch (error) {
                    if (error instanceof HttpException) {
                        if (error.status === 402) {
                            if (error.content === "ExceededMaximumFreeMembers") {
                                alert("You cannot join this project because each project can have only " + AcceptPage.MaximumFreeMembers + " free members with edit permission.\n\nTo join this project, please upgrade your account to Premium account, or ask the project owner to change your membership to view only.")
                            } else if (error.content === "ExceededMaximumProjectsForFreeAccount") {
                                alert("You cannot join this project because free users can have only " + AcceptPage.MaximumProjectsForFreeAccount + " projects.\n\nTo join this project, please upgrade your account to Premium account, or ask the project owner to change your membership to view only.");
                            } else {
                                alert("Something is wrong: " + error.content + "! You cannot join the project at the moment.");
                            }
                        } else {
                            alert("Something is wrong: " + error.content + "! You cannot join the project at the moment.");
                        }
                    }
                    else {
                        alert("Cannot accept invitation! Error: " + error);
                    }
                }
            }
            finally {
                this.setState({
                    isLoading: false,
                    isAccepting: false
                });
            }
        }
    };

    public render() {
        if (this.state.isLoading) {
            return <LoadingPageContent label="Loading invitation..." />;
        }

        if (this.state.membershipDetails) {
            var desc;
            if (this.state.membershipDetails.groupName) {
                desc = <span>You are invited to join task group <strong>{this.state.membershipDetails.groupName}</strong> in project <strong>{this.state.membershipDetails.projectTitle}</strong>{this.state.membershipDetails.role === "Reader" ? " with read-only access" : ""}.</span>;
            } else {
                desc = <span>You are invited to join project <strong>{this.state.membershipDetails.projectTitle}</strong>{this.state.membershipDetails.role === "Reader" ? " with read-only access" : ""}.</span>;
            }

            var error = (
                this.state.membershipDetails.groupName ?
                    <StyledError>*You cannot accept this invitation because you are already {this.state.membershipDetails.currentRole === "Owner" ? "the owner" : "a member"} of task group {this.state.membershipDetails.groupName} in project {this.state.membershipDetails.projectTitle}!</StyledError> :
                    <StyledError>*You cannot accept this invitation because you are already {this.state.membershipDetails.currentRole === "Owner" ? "the owner" : "a member"} of project {this.state.membershipDetails.projectTitle}!</StyledError>
            );

            return (
                <React.Fragment>
                    <StyledPageTitle>Accept invitation</StyledPageTitle>
                    {this.state.membershipDetails.state === "Accepted" ?
                        <StyledContent>
                            <StyledMessage>This invitation is accepted!</StyledMessage>
                            <br />
                            <p>You can download Project Studio from Microsoft Store on your Windows 10 devices.</p>
                            <GetApp />
                        </StyledContent>
                        :
                        <StyledContent>
                            <StyledMessage>{desc}</StyledMessage>
                            {this.state.membershipDetails.currentRole ? error :
                                (this.state.isAccepting ?
                                    <div>Accepting invitation...</div> :
                                    <button className="btn btn-info" onClick={this.accept} style={{ marginTop: 10 }}>Accept Invitation</button>)
                            }
                        </StyledContent>
                    }
                </React.Fragment>
            );
        }
        else {
            return <React.Fragment>
                <StyledPageTitle>Invitation</StyledPageTitle>
                <StyledContent>
                    <p>This is not a valid link to an invitation!</p>
                </StyledContent>
            </React.Fragment>;
        }
    }
}

const StyledContent = styled.div`
padding: 15px;
`

const StyledMessage = styled.p`
font-size: 1.2em;
margin: 0;
`

const StyledError = styled.p`
color: red;
font-weight: bold;
`