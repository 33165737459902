import * as React from 'react';
import SortOrderDialog from 'components/dialogs/SortOrderDialog';
import { CommandBar } from '@fluentui/react/lib/CommandBar';

interface Props {
    projectId: string | null;
    onShowList: () => void;
    onShowTimeline: () => void;
    onShowKanban: () => void;
}

interface State {
    showSortDialog: boolean;
}

const getItems = (onShowList: () => void, onShowTimeline: () => void, onShowKanban: () => void) => {
    return [
        {
            key: 'showList',
            name: 'List',
            iconProps: {
                iconName: 'CustomList'
            },
            ariaLabel: 'List',
            onClick: onShowList
        },
        {
            key: 'showTimeline',
            name: 'Timeline',
            iconProps: {
                iconName: 'AutoFillTemplate'
            },
            ariaLabel: 'Timeline',
            onClick: onShowTimeline
        },
        {
            key: 'showKanban',
            name: 'Kanban',
            iconProps: {
                iconName: 'CRMReport'
            },
            ariaLabel: 'Kanban',
            onClick: onShowKanban
        }
    ]
}

const getFarItems = (onFilter: () => void, onSort: () => void) => {
    return [
        {
            key: 'filter',
            name: 'Filter',
            ariaLabel: 'Filter',
            iconProps: {
                iconName: 'Filter'
            },
            onClick: onFilter
        },
        {
            key: 'sort',
            name: 'Sort',
            ariaLabel: 'Sort',
            iconProps: {
                iconName: 'SortLines'
            },
            onClick: onSort
        }
    ];
}

export default (props: Props) => {
    let [state, setState] = React.useState<State>({ showSortDialog: false });

    return (
        <React.Fragment>
            <CommandBar
                styles={{
                    root: {
                        paddingLeft: 14
                    }
                }}
                items={getItems(props.onShowList, props.onShowTimeline, props.onShowKanban)}
                farItems={getFarItems(
                    () => { },
                    () => setState({ ...state, showSortDialog: true })
                )}
            />

            <SortOrderDialog
                projectId={props.projectId}
                hidden={!state.showSortDialog}
                onDismiss={() => setState({ ...state, showSortDialog: false })} />
        </React.Fragment>
    )
}