import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import * as MessageConstants from 'common/MessageConstants';
import * as Store from 'store';
import * as StoreShell from 'store/Shell';
import * as StoreTaskPage from 'store/pages/TaskPage';

import UnsavedChangesBanner from 'components/common/UnsavedChangesBanner';
import * as ComponentsTasks from 'components/tasks';
import TaskPageDescription from 'components/tasks/TaskPageDescription';
import TaskPagePriority from 'components/tasks/TaskPagePriority';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PivotItem } from '@fluentui/react/lib/Pivot';
import { GrayLighter, AppBackground } from 'common/Colors';
import LoadingPageContent from 'components/common/LoadingPageContent';
import { StyledPivot, StyledScrollablePivotContent } from 'components/common/StyledPivot';
import { StyledPage } from 'components/common/StyledPage';
import { StyledContent } from 'components/common/StyledContent';
import { PagePadding, WidthThreshold } from 'common/LayoutConstants';

interface TaskPageRoutingProps {
    id: string | undefined;
}

type TaskPageProps =
    StoreTaskPage.TaskPageState
    & typeof StoreTaskPage.actionCreators
    & typeof StoreShell.actionCreators
    & RouteComponentProps<TaskPageRoutingProps>;

class TaskPage extends React.Component<TaskPageProps> {

    componentDidMount() {
        this.props.updatePageTitle("Task");

        let taskId = this.props.match.params.id;
        this.props.initializeTaskPage(taskId);
    }

    componentWillUnmount() {
        this.props.updatePageTitle(null);
    }

    public render() {
        if (this.props.task && !this.props.isLoading && !this.props.isError) {
            let task = this.props.task;

            let creator = task.creatorId ? this.props.users[task.creatorId] : null;
            let assignees = task.assignees
                ? task.assignees.map((assignee) => {
                    let user = this.props.users[assignee.userId];
                    return user;
                })
                : [];

            return (
                <StyledPage backgroundColor={AppBackground}>
                    <div className="page-header-content">
                        <ComponentsTasks.TaskPageCommands />
                    </div>
                    <StyledHeader>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ flex: "1 1 auto" }}>
                                <TaskPageDescription description={task.description}
                                    handleDescriptionChange={description => this.props.setDescription(task.id, description)} />
                            </div>
                            <div style={{ marginLeft: 8, marginRight: -8 }}>
                                <TaskPagePriority priority={task.priority}
                                    handlePriorityChange={priority => this.props.setPriority(task.id, priority)} />
                            </div>
                        </div>
                        <ComponentsTasks.TaskPageStage stage={task.taskStage} customStageId={task.customStageId} availableStages={this.props.availableStages}
                            handleStageChange={(stage, customStageId) => this.props.setStage(task.id, stage, customStageId)} />
                    </StyledHeader>
                    <StyledContent className="pivot-wrapper">
                        <StyledPivot>
                            <PivotItem headerText="DETAILS" className="page-tab">
                                <StyledScrollablePivotContent>
                                    <div className="ms-Grid">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4 ms-xxxl3">
                                                <ComponentsTasks.TaskPageProjectAndGroup project={this.props.project} taskGroup={this.props.taskGroup} />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4 ms-xxxl3">
                                                <ComponentsTasks.TaskPageMilestone project={this.props.project} milestone={this.props.milestone} />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4 ms-xxxl3">
                                                <ComponentsTasks.TaskPageDuration startDate={this.props.startDate} endDate={this.props.endDate} durationSeconds={this.props.durationSeconds} durationPercent={this.props.durationPercent}
                                                    isStartDateCalculated={this.props.isStartDateCalculated} isEndDateCalculated={this.props.isEndDateCalculated} isDurationCalculated={this.props.isDurationCalculated}
                                                    dateTimeDescription={this.props.dateTimeDescription} />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComponentsTasks.TaskPageAssignees assignees={assignees} creator={creator} handleAssigneeRemove={(assigneeId) => { this.props.removeAssignee(task.id, assigneeId) }} />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComponentsTasks.TaskPageTags tags={task.tags || []}
                                                    handleTagAdd={(tag) => { this.props.addTag(task.id, tag); }}
                                                    handleTagRemove={(tag) => { this.props.removeTag(task.id, tag); }} />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComponentsTasks.TaskPageSubTasks subTasks={task.subTasks || []}
                                                    handleSubTaskAdd={(subTask) => { this.props.addSubTask(task.id, subTask); }}
                                                    handleSubTaskRemove={(subTask) => { this.props.removeSubTask(task.id, subTask); }}
                                                    handleSubTaskDescriptionChange={(subTask, newSubTask) => { this.props.updateSubTaskDescription(task.id, subTask, newSubTask); }}
                                                    handleSubTaskIsCompletedToggle={(subTask) => { this.props.toggleSubTaskIsCompleted(task.id, subTask); }} />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComponentsTasks.TaskPageNotes notes={task.notes}
                                                    handleNotesChange={(notes) => this.props.updateNotes(task.id, notes)} />
                                            </div>
                                        </div>
                                    </div>
                                </StyledScrollablePivotContent>
                            </PivotItem>
                            <PivotItem headerText="DISCUSSION">
                                Discussion
                        </PivotItem>
                        </StyledPivot>
                    </StyledContent>
                    {this.props.isEditing && <div className="page-footer-content"><UnsavedChangesBanner /></div>}
                    {this.props.isRemoveDialogVisible &&
                        <Dialog
                            hidden={!this.props.isRemoveDialogVisible}
                            dialogContentProps={{
                                type: DialogType.largeHeader,
                                title: MessageConstants.RemoveTaskConfirmTitle,
                                subText: MessageConstants.RemoveTaskConfirmMessage
                            }}
                            modalProps={{
                                isBlocking: true,
                                containerClassName: 'ms-dialogMainOverride'
                            }}>
                            <DialogFooter>
                                <PrimaryButton onClick={() => { this.props.closeRemoveDialog(task.id); this.props.remove(task.id); }} text={MessageConstants.DialogButtonYes} />
                                <DefaultButton onClick={() => this.props.closeRemoveDialog(task.id)} text={MessageConstants.DialogButtonNo} />
                            </DialogFooter>
                        </Dialog>}
                    {this.props.isResetDialogVisible &&
                        <Dialog
                            hidden={!this.props.isResetDialogVisible}
                            dialogContentProps={{
                                type: DialogType.largeHeader,
                                title: MessageConstants.UnsavedChangesConfirmTitle,
                                subText: MessageConstants.UnsavedChangesConfirmResetMessage
                            }}
                            modalProps={{
                                isBlocking: true,
                                containerClassName: 'ms-dialogMainOverride'
                            }}>
                            <DialogFooter>
                                <PrimaryButton onClick={() => { this.props.closeResetDialog(task.id); this.props.reset(task.id); }} text={MessageConstants.UnsavedChangesConfirmResetYesButton} />
                                <DefaultButton onClick={() => this.props.closeResetDialog(task.id)} text={MessageConstants.UnsavedChangesConfirmResetNoButton} />
                            </DialogFooter>
                        </Dialog>}
                </StyledPage>
            )
        }
        else if (this.props.isLoading) {
            return <LoadingPageContent label="Loading your task" />;
        }
        else {
            return <Dialog
                hidden={!this.props.isErrorDialogVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: MessageConstants.TaskNotAvailableErrorTitle,
                    subText: MessageConstants.TaskNotAvailableErrorMessage
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: 'ms-dialogMainOverride'
                }}>
                <DialogFooter>
                    <PrimaryButton onClick={() => this.props.closeErrorDialog()} text={MessageConstants.DialogButtonClose} />
                </DialogFooter>
            </Dialog>;
        }
    }
}

const StyledHeader = styled.div`
background-color: white;
padding-left: ${PagePadding}px;
padding-right: ${PagePadding}px;
padding-bottom: 8px;
border-bottom: 1px solid ${GrayLighter};
transition: padding 0.1s ease;

@media (max-width: ${WidthThreshold}px) {
    padding-left: 10px;
    padding-right: 10px;
}
`

export default connect(
    (state: Store.ApplicationState) => state.taskPage,
    dispatch => bindActionCreators({
        ...StoreTaskPage.actionCreators,
        ...StoreShell.actionCreators,
    }, dispatch)
)(TaskPage);