import * as React from 'react';
import styled from 'styled-components';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react/lib/Button';
import SectionTitle from 'components/common/SectionTitle';
import SectionContent from 'components/common/SectionContent';
import { StyledSectionIconButton } from 'components/common/StyledSectionIconButton';
import { GrayLightLighter } from 'common/Colors';

interface TaskPageTagsProps {
    tags: string[];

    handleTagAdd: (tag: string) => void;
    handleTagRemove: (tag: string) => void;
}

interface TaskPageTagsState {
    newTag: string;
}

export default class TaskPageTags extends React.Component<TaskPageTagsProps, TaskPageTagsState> {
    constructor(props: TaskPageTagsProps) {
        super(props);

        this.state = { newTag: '' };
    }

    private handleTagAdd() {
        this.props.handleTagAdd(this.state.newTag);
        this.setState({ newTag: '' })
    }

    public render() {
        return <React.Fragment>
            <SectionTitle>TAGS</SectionTitle>
            <SectionContent>
                <div className="text-field-new-item-with-button">
                    <StyledSectionIconButton active={!!this.state.newTag} disabled={!this.state.newTag} iconProps={{ iconName: this.state.newTag ? "Add" : "Tag" }}
                        onClick={() => this.handleTagAdd()} />
                    <TextField
                        className="text-field-new-item" value={this.state.newTag}
                        placeholder="Enter a new tag and press Enter"
                        onChange={e => this.setState({ newTag: (e.target as HTMLInputElement).value })}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if (event.key === 'Enter') {
                                this.handleTagAdd();
                            }
                        }} />
                </div>
                {this.props.tags.length > 0 &&
                    <StyledList>
                        {this.props.tags.map((tag, index) => {
                            return <StyledTag key={index}>
                                <StyledDeleteButton iconProps={{ iconName: 'Cancel' }}
                                    onClick={() => this.props.handleTagRemove(tag)} />
                                <span className="content">{tag}</span>
                            </StyledTag>;
                        })}
                    </StyledList>
                }
            </SectionContent>
        </React.Fragment>;
    }
}

const StyledList = styled.div`
display: flex;
flex-direction: row;
margin-top: 8px;
`;

const StyledTag = styled.div`
display: flex;
margin-right: 8px;
align-items: center;

.content {
    vertical-align: middle;
    margin-left: 4px;
    margin-right: 4px;
}
`;

const StyledDeleteButton = styled(IconButton)`
width: 24px;
height: 24px;
background: ${GrayLightLighter};
i {
color: black;
}
`