import { Action, Reducer } from 'redux';
import * as ActionTypes from '../common/ActionTypes';

export interface ShellState {
    pageTitle: string;
}

// ----------------
// ACTIONS

interface UpdatePageTitleAction {
    type: typeof ActionTypes.SHELL_PAGETITLE_UPDATE;
    pageTitle: string | null;
}

type KnownAction = UpdatePageTitleAction;

// ----------------
// ACTION CREATORS

export const actionCreators = {
    updatePageTitle: (pageTitle: string | null) => ({
        type: ActionTypes.SHELL_PAGETITLE_UPDATE,
        pageTitle: pageTitle
    })
}

// ----------------
// REDUCER

const initialState: ShellState = {
    pageTitle: "Project Studio"
}

export const reducer: Reducer<ShellState> = (state: ShellState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.SHELL_PAGETITLE_UPDATE:
            return {
                ...state,
                pageTitle: action.pageTitle || "Project Studio"
            };
    }

    return state || initialState;
}

// ----------------
// SELECTOR