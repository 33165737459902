import * as React from 'react';
import styled from 'styled-components';
import { Persona } from '@fluentui/react/lib/Persona';

interface UserCardCircleProps {
    displayName: string;
    avatarUrl: string;
}

interface UserCardCircleState {
    buttonState: "none" | "hover" | "active";
}

export default class UserCardCircle extends React.Component<UserCardCircleProps, UserCardCircleState> {
    constructor(props: UserCardCircleProps) {
        super(props);
        this.state = {
            buttonState: "none"
        };
    }

    public render() {
        return <StyledCircle title={this.props.displayName}>
            <Persona text={this.props.displayName} hidePersonaDetails={true} title={this.props.displayName} imageUrl={this.props.avatarUrl} />
            <StyledTint className={`icon-btn-circle-card ${this.state.buttonState}`}
                onMouseOver={() => this.changeToHoverStateIfNotActiveState()}
                onMouseEnter={() => this.changeToHoverState()}
                onMouseDown={() => this.changeToActiveState()}
                onMouseUp={() => this.changeToHoverState()}
                onMouseLeave={() => this.changeToNormalState()}>
            </StyledTint>
        </StyledCircle>;
    }

    private changeToNormalState(): void {
        this.setState({
            buttonState: "none"
        });
    }

    private changeToHoverState(): void {
        this.setState({
            buttonState: "hover"
        });
    }

    private changeToHoverStateIfNotActiveState(): void {
        if (this.state.buttonState !== "active") {
            this.changeToHoverState();
        }
    }

    private changeToActiveState(): void {
        this.setState({
            buttonState: "active"
        });
    }
}

const StyledCircle = styled.div`
position: relative;

button:focus {
    outline: 0;
}
`;

const StyledTint = styled.button`
background: transparent;
position: absolute;
left: 0px;
top: 0px;

&:hover {
    background: rgba(255, 255, 255, 0.5);
}

&:active {
    background: rgba(0, 0, 0, 0.1);
}
`;