import * as React from 'react';
import styled from 'styled-components';
import { TextField } from '@fluentui/react/lib/TextField';
import StyledProjectIcon from 'components/common/StyledProjectIcon';
import { Blue } from '../../common/Colors';

interface Props {
    icon: string;
    color: string;
    description: string;
    onDescriptionChange: (description: string) => void;
}

export default (props: Readonly<Props>) => (
    <StyledWrapper className="text-field-new-item-with-button">
        <StyledProjectIcon backgroundColor={props.color} scale={1.5}>{props.icon}</StyledProjectIcon>
        <StyledTextField className="text-field-new-item" placeholder="Add description..."
            multiline={true} resizable={false} autoAdjustHeight={true}
            value={props.description}
            onChange={e => props.onDescriptionChange((e.target as HTMLInputElement).value)} />
    </StyledWrapper>
)

const StyledWrapper = styled.div`
margin-top: 10px;
`

const StyledTextField = styled(TextField)`
textarea:focus {
    border-bottom: 2px solid ${Blue};
}

div.ms-TextField-fieldGroup {
    border: 0;
}
`