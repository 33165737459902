import { Action, Reducer } from 'redux';
import { container } from "../inversify.config";
import { AppThunkAction, LoadableComponent } from './';

import { RequestProjectList } from './Projects';
import { ProjectStudioAPI } from '../logics/ProjectStudioAPI';
import { vSoftLoginClient, UserInfo, AuthenticationException } from '../logics/vSoftLoginClient';
import { UserProfileModel } from '../models';

export interface LoginState extends LoadableComponent {
    isAuthenticated: boolean;
    userInfo: UserInfoState | null;
}

export interface UserInfoState {
    id: string;
    userName: string;
    displayName: string;
    avatarUrl: string;
    email: string;
    isPremium: boolean;
    premiumExpiration: Date;
};

// ----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface RequestLoginAction {
    type: 'AUTH_LOGIN_REQUEST'
}

interface RequestUserInfoAction {
    type: 'REQUEST_USER_PROFILE'
}

interface ReceiveUserInfoAction {
    type: 'RECEIVE_USER_PROFILE',
    userInfo: UserInfo,
    profile: UserProfileModel
}

interface FailLoginAction {
    type: 'FAIL_LOGIN';
    error: string;
}

export type KnownAction = RequestLoginAction | RequestUserInfoAction | ReceiveUserInfoAction | FailLoginAction
    | RequestProjectList;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestUserInfo: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        if (getState().login.userInfo === null) {
            let client = container.get<vSoftLoginClient>(vSoftLoginClient);

            try {
                let fetchUserInfoTask = client.getUserInfo();
                dispatch({ type: 'REQUEST_USER_PROFILE' });

                let userInfo = await fetchUserInfoTask;

                let pjsClient = container.get<ProjectStudioAPI>(ProjectStudioAPI);
                let profile = await pjsClient.getProfile();

                dispatch({ type: 'RECEIVE_USER_PROFILE', userInfo: userInfo, profile: profile });
            }
            catch (e) {
                if (e instanceof AuthenticationException) {
                    dispatch({ type: 'FAIL_LOGIN', error: e.message });
                }
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState = {
    isLoading: false,
    errors: null,
    isAuthenticated: false,
    userInfo: null,
}

export const reducer: Reducer<LoginState> = (state: LoginState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'AUTH_LOGIN_REQUEST':
            return {
                ...state,
                isLoading: true,
                errors: null,
                isAuthenticated: false,
            }
        case 'REQUEST_USER_PROFILE':
            return {
                ...state,
                isLoading: true,
                errors: null,
                userInfo: null,
            }
        case 'RECEIVE_USER_PROFILE':
            return {
                isLoading: false,
                errors: null,
                isAuthenticated: true,
                userInfo: {
                    id: action.userInfo.Id,
                    userName: action.userInfo.UserName,
                    displayName: action.userInfo.DisplayName,
                    email: action.userInfo.Email,
                    avatarUrl: action.userInfo.AvatarUrl,
                    isPremium: action.profile.isPremium,
                    premiumExpiration: action.profile.premiumExpiration
                },
            };
        case 'FAIL_LOGIN':
            return {
                ...state,
                isLoading: false,
                errors: [action.error],
                isAuthenticated: false,
            };
        case 'REQUEST_PROJECT_LIST':
            break;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            /* eslint @typescript-eslint/no-unused-vars: "off"*/
            const exhaustiveCheck: never = action;
    }

    return state || initialState;
}