import * as React from 'react';
import styled from 'styled-components';
import { ChecklistItemModel } from 'models';
import Colors from 'common/Colors';
import NumberIndicator from 'components/common/NumberIndicator';
import SectionTitle from 'components/common/SectionTitle';
import SectionContent from 'components/common/SectionContent';
import { IconButton } from '@fluentui/react/lib/Button';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { TextField } from '@fluentui/react/lib/TextField';
import { StyledSectionIconButton } from 'components/common/StyledSectionIconButton';

interface TaskPageSubTasksProps {
    subTasks: ChecklistItemModel[] | undefined;

    handleSubTaskAdd: (newSubTask: string) => void;
    handleSubTaskRemove: (subTask: ChecklistItemModel) => void;
    handleSubTaskDescriptionChange: (subTask: ChecklistItemModel, newSubTaskDescription: string) => void;
    handleSubTaskIsCompletedToggle: (subTask: ChecklistItemModel) => void;
}

interface TaskPageSubTasksState {
    newSubTask: string;
}

export default class TaskPageSubTasks extends React.Component<TaskPageSubTasksProps, TaskPageSubTasksState> {
    constructor(props: TaskPageSubTasksProps) {
        super(props);

        this.state = { newSubTask: '' };
    }

    private handleSubTaskAdd() {
        this.props.handleSubTaskAdd(this.state.newSubTask);
        this.setState({ newSubTask: '' })
    }

    public render() {
        let remainingSubTasksNumberIndicator: JSX.Element | null = null;

        if (this.props.subTasks && this.props.subTasks.length) {
            let subTasksCount = this.props.subTasks.length;
            let remainingSubTasksCount = this.props.subTasks.filter(value => !value.isCompleted).length;
            let remainingSubTasksRatio = remainingSubTasksCount / subTasksCount * 100;
            let subTasksNumberBackgroundStyle = `linear-gradient(to bottom, ${Colors.colorOrange} ${remainingSubTasksRatio}%,${Colors.colorApp} ${remainingSubTasksRatio}%)`

            remainingSubTasksNumberIndicator = <NumberIndicator number={remainingSubTasksCount} backgroundStyle={subTasksNumberBackgroundStyle} />;
        }

        return <React.Fragment>
            <StyledSectionTitle>
                <div>CHECKLIST</div>
                {remainingSubTasksNumberIndicator}
            </StyledSectionTitle>
            <SectionContent>
                <div className="text-field-new-item-with-button">
                    <StyledSectionIconButton active={!!this.state.newSubTask} disabled={!this.state.newSubTask} iconProps={{ iconName: this.state.newSubTask ? "Add" : "BulletedList" }}
                        onClick={() => this.handleSubTaskAdd()} />
                    <TextField className="text-field-new-item" value={this.state.newSubTask}
                        placeholder="Enter a new item and press Enter"
                        onChange={e => this.setState({ newSubTask: (e.target as HTMLInputElement).value })}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if (event.key === 'Enter') {
                                this.handleSubTaskAdd();
                            }
                        }} />
                </div>
                {this.props.subTasks && this.props.subTasks.length > 0 &&
                    this.props.subTasks.map((subTask, index) => {
                        let subTaskContentClassName = "content";

                        if (subTask.isCompleted) {
                            subTaskContentClassName += " completed";
                        }

                        return <StyledSubTask key={index}>
                            <Checkbox checked={subTask.isCompleted}
                                onChange={() => this.props.handleSubTaskIsCompletedToggle(subTask)} />
                            <TextField className={subTaskContentClassName} value={subTask.description}
                                multiline autoAdjustHeight resizable={false} rows={1}
                                onChange={e => this.props.handleSubTaskDescriptionChange(subTask, (e.target as HTMLInputElement).value)} />
                            <IconButton className="icon-btn-item-page transparent-red" iconProps={{ iconName: 'Delete' }}
                                onClick={() => this.props.handleSubTaskRemove(subTask)} />
                        </StyledSubTask>;
                    })}
            </SectionContent>
        </React.Fragment>;
    }
}

const StyledSubTask = styled.div`
display: flex;
align-items: stretch;
margin-top: 2px;
margin-bottom: 2px;
            
.content {
    flex: 1;
    .ms-TextField-fieldGroup {
        min-height: 30px;
    }
}
.content.completed {
    text-decoration: line-through;
}
.ms-Checkbox {
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 10px;
}
.ms-Button {
    min-height: 32px;
    height: auto;
}
`;

const StyledSectionTitle = styled(SectionTitle)`
display: flex;
.number-indicator {
    margin-left: 4px;
    margin-right: 4px;
}
`