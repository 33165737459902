import * as React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import StyledPageTitle from 'components/common/StyledPageTitle';

interface Props {
    description: string;
    handleDescriptionChange: (description: string) => void;
}

export default (props: Readonly<Props>) => (
    <StyledPageTitle padding={0}>
        <TextField placeholder="Enter task description"
            value={props.description}
            onChange={e => props.handleDescriptionChange((e.target as HTMLInputElement).value)} />
    </StyledPageTitle>
)