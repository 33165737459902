import styled from 'styled-components';
import { Pivot } from '@fluentui/react/lib/Pivot';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { GrayLightLighterLighter } from '../../common/Colors';

export const StyledPivot = styled(Pivot)`
.ms-Pivot-link, .ms-Pivot-link.ms-Button {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
    margin-bottom: -1px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    font-weight: 600;
}

.ms-Pivot-link.is-selected {
    background-color: white;
    border-top-color: ${GrayLightLighterLighter};
    border-left-color: ${GrayLightLighterLighter};
    border-right-color: ${GrayLightLighterLighter};
}

.page-tab {
    border-top: 1px solid ${GrayLightLighterLighter};
    border-left: 1px solid ${GrayLightLighterLighter};
    border-right: 1px solid ${GrayLightLighterLighter};
}
`;

export const StyledScrollablePivotContent = styled(ScrollablePane)`
.ms-Grid {
    padding: 0 8px;
}
`;
