import * as React from 'react';
import styled from 'styled-components';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react/lib/Button';
import ReactMarkdown from 'react-markdown';
import SectionTitle from 'components/common/SectionTitle';
import SectionContent from 'components/common/SectionContent';
import { GrayLighter, Blue, BlueLight, GrayLightLighter } from 'common/Colors';

interface Props {
    notes: string | undefined;
    handleNotesChange: (notes: string | null) => void;
}

interface State {
    isEditting: boolean;
}

export default (props: Readonly<Props>) => {
    let [state, setState] = React.useState<State>({
        isEditting: !props.notes
    });
    return <React.Fragment>
        <SectionTitle>
            NOTES {!!props.notes && <StyledEditButton iconProps={{ iconName: 'Edit' }} onClick={() => setState({ isEditting: !state.isEditting })} active={state.isEditting} />}
        </SectionTitle>
        <SectionContent>
            {state.isEditting && <StyledTextField value={props.notes || undefined} placeholder="Write some notes..."
                multiline autoAdjustHeight rows={8}
                onChange={e => props.handleNotesChange((e.target as HTMLInputElement).value)} />}

            {props.notes && <ReactMarkdown source={props.notes} renderers={{ link: LinkComponent }} />}
        </SectionContent>
    </React.Fragment>;
}

const LinkComponent = (props: any) => (
    <a href={props.href} title={props.title} target="_blank" rel="noreferrer">{props.children}</a>
)

const StyledTextField = styled(TextField)`
margin-top: 10px;
margin-bottom: 10px;
textarea {
    max-height: 360px;
}
&.ms-TextField {
    max-height: 360px;
}
`

interface StyledEditButtonProps {
    active: boolean;
}

const StyledEditButton = styled(IconButton) <StyledEditButtonProps>`
background: ${props => props.active ? Blue : GrayLighter};
color: ${props => props.active ? 'white' : 'black'};
height: 20px;
width: 20px;
margin-left: 10px;

:hover {
    color: ${props => props.active ? 'white' : 'black'};
    background: ${props => props.active ? BlueLight : GrayLightLighter};
}

i {
    font-size: 12px;
}
`