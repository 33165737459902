import moment from 'moment';
import { TimeAccuracy } from '../models';

export default class TimeAccuracyComparer {
    public static min(accuracy1: TimeAccuracy, accuracy2: TimeAccuracy): TimeAccuracy {
        return TimeAccuracyComparer.convertIntToTimeAccuracyForComparison(Math.min(
                            TimeAccuracyComparer.convertTimeAccuracyToIntForComparison(accuracy1),
                            TimeAccuracyComparer.convertTimeAccuracyToIntForComparison(accuracy2)));
    }

    public static minWithDuration(accuracy: TimeAccuracy, durationSeconds: number): TimeAccuracy {
        return TimeAccuracyComparer.min(accuracy, TimeAccuracyComparer.convertDurationToTimeAccuracyForComparison(durationSeconds));
    }

    public static max(accuracy1: TimeAccuracy, accuracy2: TimeAccuracy): TimeAccuracy {
        return TimeAccuracyComparer.convertIntToTimeAccuracyForComparison(Math.max(
                            TimeAccuracyComparer.convertTimeAccuracyToIntForComparison(accuracy1),
                            TimeAccuracyComparer.convertTimeAccuracyToIntForComparison(accuracy2)));
    }

    private static convertTimeAccuracyToIntForComparison(accuracy: TimeAccuracy): number {
        switch (accuracy) {
            case TimeAccuracy.Time:
                return 0;
            case TimeAccuracy.Day:
                return 1;
            case TimeAccuracy.Month:
                return 2;
            case TimeAccuracy.Year:
                return 3;
            default:
                return -1;
        }
    }

    private static convertIntToTimeAccuracyForComparison(intNumber: number): TimeAccuracy {
        switch (intNumber) {
            case 0:
                return TimeAccuracy.Time;
            case 1:
                return TimeAccuracy.Day;
            case 2:
                return TimeAccuracy.Month;
            case 3:
                return TimeAccuracy.Year;
            default:
                return TimeAccuracy.Day;
        }
    }

    private static convertDurationToTimeAccuracyForComparison(durationSeconds: number): TimeAccuracy {
        let duration = moment.duration(durationSeconds, 'seconds');

        if (duration.hours() !== 0 || duration.minutes() !== 0)
            return TimeAccuracy.Time;
        else
            return TimeAccuracy.Day;
    }
}