import { Reducer, AnyAction } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import * as Login from './Login';
import * as Milestones from './Milestones';
import * as Projects from './Projects';
import * as Shell from './Shell';
import * as Tasks from './Tasks';
import * as TaskGroups from './TaskGroups';
import * as Users from './Users';
import * as TaskPage from './pages/TaskPage';

// The top-level state object
export interface ApplicationState {
    login: Login.LoginState,
    milestones: Milestones.MilestonesState,
    projects: Projects.ProjectsState,
    tasks: Tasks.TasksState,
    taskGroups: TaskGroups.TaskGroupsState,
    users: Users.UsersState,

    // Pages
    taskPage: TaskPage.TaskPageState,

    // Shell
    shell: Shell.ShellState,
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = (history: History) => {
    return {
        router: connectRouter(history) as Reducer<RouterState, AnyAction>,

        login: Login.reducer,
        milestones: Milestones.reducer,
        projects: Projects.reducer,
        tasks: Tasks.reducer,
        taskGroups: TaskGroups.reducer,
        users: Users.reducer,

        // Pages
        taskPage: TaskPage.reducer,

        // Shell
        shell: Shell.reducer,
    };
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export interface LoadableComponent {
    isLoading: boolean;
    errors: string[] | null;
}