import { Reducer } from 'redux';
import { container } from "../inversify.config";
import { AppThunkAction } from './';

import * as ActionTypes from '../common/ActionTypes';
import { vSoftLoginClient, UserInfo } from '../logics/vSoftLoginClient';
import { UserModel } from '../models';
import * as StoreLogin from './Login';

export const ALL_TASKS_ID = 'ALL_TASKS';
export const NO_PROJECT_ID = '#empty';

export interface UsersState {
    users: { [userId: string]: UserModel };
    loadingUsers: { [userId: string]: boolean };
}

// ----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface RequestUserInfoAction {
    type: typeof ActionTypes.USER_INFO_REQUEST;
    userId: string;
}

export interface ReceiveUserInfoAction {
    type: typeof ActionTypes.USER_INFO_RECEIVE,
    userId: string;
    user: UserModel;
}

type KnownAction = RequestUserInfoAction | ReceiveUserInfoAction
    | StoreLogin.RequestLoginAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestUser: (userId: string, forceRefresh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState();

        let user = state.users && state.users.users && state.users.users[userId];
        let isLoading = state.users && state.users.loadingUsers && state.users.loadingUsers[userId];

        if (forceRefresh || (!isLoading && !user)) {
            let client = container.get<vSoftLoginClient>(vSoftLoginClient);

            // TODO: handle exception
            let fetchUser = client.getUserInfoById(userId);
            dispatch({ type: ActionTypes.USER_INFO_REQUEST, userId: userId });

            let user = await fetchUser;
            if (user) {
                dispatch({ type: ActionTypes.USER_INFO_RECEIVE, userId: userId, user: actionCreatorUtilities.convertToUserModel(user) });
            } else {
                // FAIL
                console.log('Cannot get user info!', userId);
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: UsersState = {
    users: {},
    loadingUsers: {},
};

export const reducer: Reducer<UsersState> = (state: UsersState = initialState, action: KnownAction) => {
    switch (action.type) {
        case ActionTypes.USER_INFO_REQUEST:
            return {
                ...state,
                loadingUsers: {
                    ...state.loadingUsers,
                    [action.userId]: true
                }
            };
        case ActionTypes.USER_INFO_RECEIVE:
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.userId]: action.user
                },
                loadingUsers: {
                    ...state.loadingUsers,
                    [action.userId]: false
                }
            };
    }

    return state || initialState;
}

const actionCreatorUtilities = {
    convertToUserModel: (userInfo: UserInfo): UserModel => {
        return {
            id: userInfo.Id,
            userName: userInfo.UserName,
            fullName: userInfo.DisplayName || userInfo.UserName,
            displayName: userInfo.DisplayName,
            email: userInfo.Email,
            avatarUrl: userInfo.AvatarUrl,
        };
    }
}

// ----------------
// SELECTOR

