import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TaskModel, ProjectModel } from 'models';
import Assignees from './Assignees';
import { GrayLighter, GrayLight } from 'common/Colors';
import ProjectAndMilestoneIndicator from './ProjectAndMilestoneIndicator';

interface Props {
    task: TaskModel;
    project: ProjectModel | null;
}

export default (props: Props) => {
    let { task, project } = props;

    return <StyledItem key={task.id}>
        <Link to={"/tasks/" + props.task.id}>
            <StyledDescription>{task.description}</StyledDescription>
            <ProjectAndMilestoneIndicator project={project} milestone={null} taskGroup={null} />
            <Assignees taskId={task.id} />
        </Link>
    </StyledItem>
}

const StyledItem = styled.div`
position: relative;
margin: 6px;
padding: 6px;
border: 1px solid ${GrayLighter};
border-radius: 3px;

:hover {
    border: 1px solid ${GrayLight};
}

a:hover, a:focus, a:active {
    text-decoration: none;
}
`

const StyledDescription = styled.div`
display: block;
color: black;
font-size: 1.1em;
overflow: hidden;
white-space: normal;
word-wrap: break-word;
text-overflow: wrap;
width: calc(100% - 40px);
`