import { Action, Reducer } from 'redux';
import { container } from "../inversify.config";
import { AppThunkAction, LoadableComponent } from './';

import * as ActionTypes from '../common/ActionTypes';
import * as MessageConstants from '../common/MessageConstants';
import { ProjectStudioAPI } from '../logics/ProjectStudioAPI';
import { ProjectModel } from '../models';
import * as StoreLogin from './Login';

export interface ProjectsState extends LoadableComponent {
    projects: { [projectId: string]: ProjectModel } | null;
    loadingProjects: { [projectId: string]: boolean };
}

// ----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface RequestProjectAction {
    type: typeof ActionTypes.PROJECT_REQUEST;
    projectId: string;
}

export interface ReceiveProjectAction {
    type: typeof ActionTypes.PROJECT_RECEIVE;
    projectId: string;
    project: ProjectModel;
}

export interface RequestProjectList {
    type: 'REQUEST_PROJECT_LIST'
}

interface ReceiveProjectList {
    type: 'RECEIVE_PROJECT_LIST',
    projects: ProjectModel[]
}

export type KnownAction = RequestProjectAction | ReceiveProjectAction
    | RequestProjectList | ReceiveProjectList
    | StoreLogin.RequestLoginAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestProject: (projectId: string, forceRefresh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState();

        let project = state.projects && state.projects.projects && state.projects.projects[projectId];
        let isLoading = state.projects && state.projects.loadingProjects && state.projects.loadingProjects[projectId];

        if (forceRefresh || (!isLoading && !project)) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);

            let fetchProject = client.getProject(projectId);
            dispatch({ type: ActionTypes.PROJECT_REQUEST, projectId: projectId });

            let project = await fetchProject;
            dispatch({ type: ActionTypes.PROJECT_RECEIVE, projectId: projectId, project: project });
        }
    },
    requestProjectList: (forceRefresh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        if (forceRefresh || (getState().projects.projects === null && !getState().projects.isLoading)) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);
            let fetchTask = client.getProjects();
            dispatch({ type: 'REQUEST_PROJECT_LIST' });

            try {
                var projects = await fetchTask;
                dispatch({ type: 'RECEIVE_PROJECT_LIST', projects: projects });
            }
            catch (e) {
                if (e.message === MessageConstants.ErrorResponseUnauthorized) {
                    dispatch({ type: ActionTypes.AUTH_LOGIN_REQUEST });
                }
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: ProjectsState = {
    projects: null,
    loadingProjects: {},
    isLoading: false,
    errors: null
};

export const reducer: Reducer<ProjectsState> = (state: ProjectsState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.PROJECT_REQUEST:
            return {
                ...state,
                loadingProjects: {
                    ...state.loadingProjects,
                    [action.projectId]: true
                }
            };
        case ActionTypes.PROJECT_RECEIVE:
            return {
                ...state,
                projects: {
                    ...(state.projects || {}),
                    [action.projectId]: action.project
                },
                loadingProjects: {
                    ...state.loadingProjects,
                    [action.projectId]: false
                }
            };
        case 'REQUEST_PROJECT_LIST':
            return {
                ...state,
                isLoading: true,
                errors: null,
                projects: null
            }
        case 'RECEIVE_PROJECT_LIST':
            return {
                ...state,
                isLoading: false,
                errors: null,
                projects: action.projects.reduce((m: { [id: string]: ProjectModel }, p) => { m[p.id] = p; return m; }, {})
            };
    }

    return state || initialState;
}

// ----------------
// SELECTOR

export const getProjects = (state: ProjectsState) => {
    if (state.projects === null) return null;
    const projects = state.projects;
    return Object.keys(projects).map(k => projects[k]);
}