import { Action, Reducer } from 'redux';
import { container } from "../inversify.config";
import { AppThunkAction } from './';

import * as ActionTypes from '../common/ActionTypes';
import { ProjectStudioAPI } from '../logics/ProjectStudioAPI';
import { TaskGroupModel } from '../models';

export interface TaskGroupsState {
    taskGroups: { [taskGroupId: string]: TaskGroupModel };
    taskGroupIdsByProject: { [projectId: string]: string[] };
    loadingProjects: { [projectId: string]: boolean };
    loadingTaskGroups: { [taskGroupId: string]: boolean };
}

// ----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface RequestTaskGroupAction {
    type: typeof ActionTypes.TASKGROUP_REQUEST;
    taskGroupId: string;
}

export interface ReceiveTaskGroupAction {
    type: typeof ActionTypes.TASKGROUP_RECEIVE;
    taskGroupId: string;
    taskGroup: TaskGroupModel;
}

export interface RequestTaskGroupsAction {
    type: "REQUEST_TASKGROUP_LIST";
    projectId: string;
}

export interface ReceiveTaskGroupsAction {
    type: "RECEIVE_TASKGROUP_LIST";
    projectId: string;
    taskGroups: TaskGroupModel[];
}

type KnownAction =
    RequestTaskGroupAction | ReceiveTaskGroupAction |
    RequestTaskGroupsAction | ReceiveTaskGroupsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestTaskGroups: (projectId: string, forceRefresh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState();

        let taskGroup = state.taskGroups.taskGroupIdsByProject[projectId];
        let isLoading = state.taskGroups.loadingProjects[projectId];

        if (forceRefresh || (!isLoading && !taskGroup)) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);

            let fetchTaskGroups = client.getTaskGroups(projectId);
            dispatch({ type: "REQUEST_TASKGROUP_LIST", projectId: projectId });

            let taskGroups = await fetchTaskGroups;
            dispatch({ type: "RECEIVE_TASKGROUP_LIST", projectId: projectId, taskGroups: taskGroups });
        }
    },
    requestTaskGroup: (taskGroupId: string, projectId: string, forceRefresh: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState();

        let taskGroup = state.taskGroups && state.taskGroups.taskGroups && state.taskGroups.taskGroups[taskGroupId];
        let isLoading = state.taskGroups && state.taskGroups.loadingTaskGroups && state.taskGroups.loadingTaskGroups[taskGroupId];

        if (forceRefresh || (!isLoading && !taskGroup)) {
            let client = container.get<ProjectStudioAPI>(ProjectStudioAPI);

            let fetchTaskGroup = client.getTaskGroup(taskGroupId, projectId);
            dispatch({ type: ActionTypes.TASKGROUP_REQUEST, taskGroupId: taskGroupId });

            let taskGroup = await fetchTaskGroup;
            dispatch({ type: ActionTypes.TASKGROUP_RECEIVE, taskGroupId: taskGroupId, taskGroup: taskGroup });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const initialState: TaskGroupsState = {
    taskGroups: {},
    loadingTaskGroups: {},
    loadingProjects: {},
    taskGroupIdsByProject: {}
};

export const reducer: Reducer<TaskGroupsState> = (state: TaskGroupsState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.TASKGROUP_REQUEST:
            return {
                ...state,
                loadingTaskGroups: {
                    ...state.loadingTaskGroups,
                    [action.taskGroupId]: true
                }
            };
        case ActionTypes.TASKGROUP_RECEIVE:
            return {
                ...state,
                taskGroups: {
                    ...state.taskGroups,
                    [action.taskGroupId]: action.taskGroup
                },
                loadingTaskGroups: {
                    ...state.loadingTaskGroups,
                    [action.taskGroupId]: false
                }
            };

        case "REQUEST_TASKGROUP_LIST":
            return {
                ...state,
                loadingProjects: {
                    ...state.loadingProjects,
                    [action.projectId]: true
                }
            }

        case "RECEIVE_TASKGROUP_LIST":
            return {
                ...state,
                taskGroups: {
                    ...state.taskGroups,
                    ...action.taskGroups.reduce((m: { [id: string]: TaskGroupModel }, o) => { m[o.id] = o; return m }, {})
                },
                taskGroupIdsByProject: {
                    ...state.taskGroupIdsByProject,
                    [action.projectId]: action.taskGroups.map(o => o.id)
                },
                loadingProjects: {
                    ...state.loadingProjects,
                    [action.projectId]: false
                }
            }
    }

    return state || initialState;
}

// ----------------
// SELECTOR