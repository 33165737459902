import { Container } from "inversify";
import "reflect-metadata";
import { ProjectStudioAPI } from "./logics/ProjectStudioAPI";
import { vSoftLoginClient } from "./logics/vSoftLoginClient";

let container = new Container();
container.bind<vSoftLoginClient>(vSoftLoginClient).to(vSoftLoginClient);
container.bind<ProjectStudioAPI>(ProjectStudioAPI).to(ProjectStudioAPI);

export { container };
