import styled, { css } from 'styled-components';
import { IconButton } from '@fluentui/react/lib/Button';
import { GrayLightLighter, Blue } from 'common/Colors';

interface StyledSectionIconButtonProps {
    active: boolean;
}

export const StyledSectionIconButton = styled(IconButton) <StyledSectionIconButtonProps>`
i {
    color: ${props => props.active ? 'white' : 'black'};
}
background-color: ${props => props.active ? Blue : GrayLightLighter};
${props => !props.active && css`cursor: default;`}
`