import moment from 'moment';
import StringHelper from './StringHelper';
import TimeAccuracyComparer from './TimeAccuracyComparer';
import { DateStatus, TimeAccuracy } from '../models';
import DateTimeWithAccuracy from '../models/DateTimeWithAccuracy';
import Dates from '../models/Dates';
import { DateTimeWithAccuracyType } from '../models/DateTimeWithAccuracy';

export default class DateTimeHelper {
    public static getDurationDescription(durationSeconds: number | null): string
    {
        let duration = durationSeconds !== null ? moment.duration(durationSeconds, "seconds") : null;

        if (duration === null)
            return "";
        else if (duration < moment.duration(2, "hours") || duration.asHours() !== parseInt(duration.asHours().toString(), 10))
            return parseInt(duration.asMinutes().toString(), 10) + StringHelper.singleOrPlural(parseInt(duration.asMinutes().toString(), 10), " minute", " minutes");
        else if (duration < moment.duration(1, "day") || duration.asDays() !== parseInt(duration.asDays().toString(), 10))
            return duration.asHours().toString() + StringHelper.singleOrPlural(parseInt(duration.asHours().toString(), 10), " hour", " hours");
        else
            return duration.asDays().toString() + StringHelper.singleOrPlural(parseInt(duration.asDays().toString(), 10), " day", " days");
    }

    public static calculateStartDate(endDate: DateTimeWithAccuracy, durationSeconds: number | null): DateTimeWithAccuracy {
        let endDateTime = endDate.getDateTime(DateTimeWithAccuracyType.End);

        if (endDateTime && durationSeconds !== null) {
            let startDateAccuracy = TimeAccuracyComparer.minWithDuration(endDate.accuracy, durationSeconds);

            if (endDate.accuracy !== TimeAccuracy.Time) {
                durationSeconds = durationSeconds - 1;
            }

            if (durationSeconds < 0)
                durationSeconds = 0;

            let startDateTime = Dates.MinDate;

            try {
                startDateTime = moment(endDateTime).add(-durationSeconds, 'seconds').toDate();
            }
            catch { }

            return new DateTimeWithAccuracy(startDateTime, startDateAccuracy);
        }
        else {
            return new DateTimeWithAccuracy(null, TimeAccuracy.Day);
        }
    }

    public static calculateEndDate(startDate: DateTimeWithAccuracy, durationSeconds: number | null): DateTimeWithAccuracy {
        let startDateTime = startDate.getDateTime(DateTimeWithAccuracyType.Start);

        if (startDateTime && durationSeconds !== null) {
            let endDateAccuracy = TimeAccuracyComparer.minWithDuration(startDate.accuracy, durationSeconds);

            if (endDateAccuracy !== TimeAccuracy.Time) {
                durationSeconds = durationSeconds - 1;
            }

            if (durationSeconds < 0)
                durationSeconds = 0;

            let endDateTime = Dates.MaxDate;

            try {
                endDateTime = moment(startDateTime).add(durationSeconds, 'seconds').toDate();
            }
            catch { }

            return new DateTimeWithAccuracy(endDateTime, endDateAccuracy);
        }
        else {
            return new DateTimeWithAccuracy(null, TimeAccuracy.Day);
        }
    }

    public static calculateDurationSeconds(startDate: DateTimeWithAccuracy, endDate: DateTimeWithAccuracy): number {
        let startDateTime = startDate.getDateTime(DateTimeWithAccuracyType.Start);
        let endDateTime = endDate.getDateTime(DateTimeWithAccuracyType.End);

        if (startDateTime && endDateTime) {
            let durationMilliseconds = endDateTime.getTime() - startDateTime.getTime();
            let durationSeconds = durationMilliseconds / 1000;

            if (endDate.accuracy !== TimeAccuracy.Time) {
                durationSeconds = durationSeconds + 1;
            }

            return durationSeconds;
        }
        else {
            return 0;
        }
    }

    public static calculateDurationPercent(start: DateTimeWithAccuracy, end: DateTimeWithAccuracy): number {
        let startDateTime = start.getDateTime(DateTimeWithAccuracyType.Start);
        let endDateTime = end.getDateTime(DateTimeWithAccuracyType.End);

        let today = new Dates().today;

        if (startDateTime === null || endDateTime === null) {
            return 0;
        }
        else {
            if (startDateTime > endDateTime) {
                return 0;
            }
            else if (today >= endDateTime) {
                return 100;
            }
            else if (today <= startDateTime) {
                return 0;
            }
            else {
                let startDate = moment(startDateTime).startOf("day").toDate();
                let endDate = moment(endDateTime).startOf("day").toDate();

                let startAccuracy = start.accuracy;
                let endAccuracy = end.accuracy;

                let totalDate = parseInt(moment.duration(endDate.getTime() - startDate.getTime(), "milliseconds").asDays().toString(), 10);
                let elapsedDate = parseInt(moment.duration(today.getTime() - startDate.getTime(), "milliseconds").asDays().toString(), 10);

                // Special logic to handle Daylight Saving Time (DST) and only applicable if both start and end accuracies are not Time
                // For example, start = 1/1/2015 00:00 GMT -7
                //              end   = 3/1/2015 23:59 GMT -7
                //              days should be 2 because end - start = 2d 23:59:59 => correct
                // When DST ends, start = 1/11/2015 00:00 GMT -7
                //                end   = 3/11/2015 23:59 GMT -8
                //                days becomes 3 because end - start = 3d 00:59:59 => incorrect
                //                it should be 2 instead
                // When DST starts, start = 8/3/2015 00:00 GMT -8
                //                  end = 10/3/2015 23:59 GMT -7
                //                  days becomes 2 because end - start = 2d 22:59:59 => correct
                if (startAccuracy !== TimeAccuracy.Time && endAccuracy !== TimeAccuracy.Time)
                {
                    let totalDateDifference = moment.duration(endDate.getTime() - startDate.getTime(), "milliseconds");
                    let elapsedDateDifference = moment.duration(today.getTime() - startDate.getTime(), "milliseconds");

                    if (totalDateDifference.hours() === 0 && totalDateDifference.minutes() === 59 && totalDateDifference.seconds() === 59)
                    {
                        totalDate -= 1;

                        if (totalDate === 0)
                            totalDate = 0;
                    }

                    if (elapsedDateDifference.hours() === 0 && elapsedDateDifference.minutes() === 59 && elapsedDateDifference.seconds() === 59)
                    {
                        elapsedDate -= 1;

                        if (elapsedDate === 0)
                            elapsedDate = 0;
                    }
                }

                return totalDate === 0 ? 0 : elapsedDate * 100 / totalDate;
            }
        }
    }

    public static getDateDescriptionFromDateStatus(dateStatus: DateStatus, startDate: DateTimeWithAccuracy, endDate: DateTimeWithAccuracy, durationSeconds: number | null, showNumberOfDayInsteadOfDateWhenDateIsFar: boolean = false, upperCase: boolean = false): string {
        var overdueString = upperCase ? "Overdue" : "overdue";
        var dueString = upperCase ? "Due" : "due";
        var startedString = upperCase ? "Started" : "started";
        var startString = upperCase ? "Start" : "start";

        switch (dateStatus)
        {
            case DateStatus.Overdue:
            case DateStatus.DueToday:
            case DateStatus.DueSoon:
            case DateStatus.DueFuture:
            case DateStatus.InProgress:
                {
                    return DateTimeHelper.getDateDescriptionFromDateTimeWithAccuracy(endDate, DateTimeWithAccuracyType.End,
                                              `${overdueString}`, `${overdueString} for`, `${dueString}`, `${dueString} on`, `${dueString}`, `${dueString} in`, showNumberOfDayInsteadOfDateWhenDateIsFar);
                }
            case DateStatus.Started:
            case DateStatus.StartToday:
            case DateStatus.StartSoon:
            case DateStatus.StartFuture:
                {
                    return DateTimeHelper.getDateDescriptionFromDateTimeWithAccuracy(startDate, DateTimeWithAccuracyType.Start,
                                              `${startedString}`, `${startedString} for`, `${startString}`, `${startString} on`, `${startString}`, `${startString} in`, showNumberOfDayInsteadOfDateWhenDateIsFar);
                }
            case DateStatus.DurationOnly:
                {
                    return DateTimeHelper.getDurationDescription(durationSeconds);
                }
            default:
                return "";
        }
    }

    private static getDateDescriptionFromDateTimeWithAccuracy(dateTimeWithAccuracy: DateTimeWithAccuracy,
                                                              type: DateTimeWithAccuracyType,
                                                              pastPrefix: string,
                                                              pastPrefixForShowNumberOfDay: string,
                                                              todayOrTomorrowPrefix: string,
                                                              soonPrefix: string,
                                                              futurePrefix: string,
                                                              soonOrFuturePrefixForShowNumberOfDay: string,
                                                              showNumberOfDayInsteadOfDateWhenDateIsFar: boolean): string {
        var dateTime = dateTimeWithAccuracy.getDateTime(type);
        return DateTimeHelper.getDateDescription(dateTime,
                                                 dateTimeWithAccuracy.accuracy,
                                                 pastPrefix,
                                                 pastPrefixForShowNumberOfDay,
                                                 todayOrTomorrowPrefix,
                                                 soonPrefix,
                                                 futurePrefix,
                                                 soonOrFuturePrefixForShowNumberOfDay,
                                                 showNumberOfDayInsteadOfDateWhenDateIsFar);
    }

    public static getDateDescription(dateTime: Date | null,
                                     timeAccuracy: TimeAccuracy = TimeAccuracy.Time,
                                     pastPrefix: string = "",
                                     pastPrefixForShowNumberOfDay: string = "",
                                     todayOrTomorrowPrefix: string = "",
                                     soonPrefix: string = "",
                                     futurePrefix: string = "",
                                     soonOrFuturePrefixForShowNumberOfDay: string = "",
                                     showNumberOfDayInsteadOfDateWhenDateIsFar: boolean = false): string {
        var dates = new Dates();
        if (dateTime !== null)
        {
            if (dateTime < dates.todayMinus1)
            {
                if (!showNumberOfDayInsteadOfDateWhenDateIsFar)
                    return `${pastPrefix} ${DateTimeHelper.getMMMdd(dateTime)}`.trim();
                else
                    return `${pastPrefixForShowNumberOfDay} ${DateTimeHelper.getDateDistanceDescription(moment(dateTime).startOf("day").toDate(), dates.today)}`.trim();
            }
            else if (dateTime < dates.today)
                return `${pastPrefix} yesterday`.trim();
            else if (dateTime < dates.now)
            {
                if (timeAccuracy === TimeAccuracy.Time)
                    return `${pastPrefix} ${DateTimeHelper.getTimeComponent(dateTime)}`.trim();
                else
                    return `${pastPrefix} today`.trim();
            }
            else if (dateTime < dates.todayPlus1)
            {
                if (timeAccuracy === TimeAccuracy.Time)
                    return `${todayOrTomorrowPrefix} ${DateTimeHelper.getTimeComponent(dateTime)}`.trim();
                else
                    return `${todayOrTomorrowPrefix} today`.trim();
            }
            else if (dateTime < dates.todayPlus2)
                return `${todayOrTomorrowPrefix} tomorrow`.trim();
            else
            {
                if (!showNumberOfDayInsteadOfDateWhenDateIsFar)
                {
                    let endOfWeek = moment().endOf("week").startOf("day").toDate();

                    if (dateTime < moment(endOfWeek).add(1, "day").toDate())
                        return `${soonPrefix} ${DateTimeHelper.getDayOfWeek(dateTime)}`.trim();
                    else
                        return `${futurePrefix} ${DateTimeHelper.getMMMdd(dateTime)}`.trim();
                }
                else
                    return `${soonOrFuturePrefixForShowNumberOfDay} ${DateTimeHelper.getDateDistanceDescription(dates.today, moment(dateTime).startOf("day").toDate())}`.trim();
            }
        }
        else
        {
            return "";
        }
    }

    private static getMMMdd(dateTime: Date): string {
        var today = moment((new Dates()).today);
        var date = moment(dateTime).startOf("day");

        if (date.year() !== today.year())
            return `${ date.format("ll") }`;
        else
            return `${ date.format("ll").replace(date.format("[, ]Y"), "") }`;
    }

    private static getDayOfWeek(dateTime: Date): string
    {
        return moment(dateTime).format("ddd");
    }

    private static getTimeComponent(dateTime: Date): string
    {
        return moment(dateTime).format("LT");
    }

    private static getDateDistanceDescription(fromDate: Date, toDate: Date): string
    {
        var dateDistanceDescription = "";

        let momentFromDate = moment(fromDate);
        let momentToDate = moment(toDate);

        dateDistanceDescription = momentFromDate.from(momentToDate, true);

        return dateDistanceDescription;
    }
}