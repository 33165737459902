export const SHELL_PAGETITLE_UPDATE = "SHELL_PAGETITLE_UPDATE";

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";

export const TASK_RECEIVE = "TASK_RECEIVE";

export const PROJECT_REQUEST = "PROJECT_REQUEST";
export const PROJECT_RECEIVE = "PROJECT_RECEIVE";

export const TASKGROUP_REQUEST = "TASKGROUP_REQUEST";
export const TASKGROUP_RECEIVE = "TASKGROUP_RECEIVE";

export const MILESTONE_REQUEST = "MILESTONE_REQUEST";
export const MILESTONE_RECEIVE = "MILESTONE_RECEIVE";

export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_RECEIVE = "USER_INFO_RECEIVE";

export const TASK_PAGE_TASK_REQUEST = "TASK_PAGE_TASK_REQUEST";
export const TASK_PAGE_TASK_RECEIVE = "TASK_PAGE_TASK_RECEIVE";
export const TASK_PAGE_SET_DESCRIPTION = "TASK_PAGE_SET_DESCRIPTION";
export const TASK_PAGE_SET_STAGE = "TASK_PAGE_SET_STAGE";
export const TASK_PAGE_SET_PRIORITY = "TASK_PAGE_SET_PRIORITY";
export const TASK_PAGE_REMOVE_ASSIGNEE = "TASK_PAGE_REMOVE_ASSIGNEE";
export const TASK_PAGE_ADD_TAG = "TASK_PAGE_ADD_TAG";
export const TASK_PAGE_REMOVE_TAG = "TASK_PAGE_REMOVE_TAG";
export const TASK_PAGE_ADD_SUBTASK = "TASK_PAGE_ADD_SUBTASK";
export const TASK_PAGE_REMOVE_SUBTASK = "TASK_PAGE_REMOVE_SUBTASK";
export const TASK_PAGE_UPDATE_SUBTASK_DESCRIPTION = "TASK_PAGE_UPDATE_SUBTASK_DESCRIPTION";
export const TASK_PAGE_TOGGLE_SUBTASK_ISCOMPLETED = "TASK_PAGE_TOGGLE_SUBTASK_ISCOMPLETED";
export const TASK_PAGE_UPDATE_NOTES = "TASK_PAGE_UPDATE_NOTES";

export const TASK_PAGE_PROJECT_SET_DETAILS = "TASK_PAGE_PROJECT_SET_DETAILS";
export const TASK_PAGE_TASKGROUP_SET_DETAILS = "TASK_PAGE_TASKGROUP_SET_DETAILS";
export const TASK_PAGE_MILESTONE_SET_DETAILS = "TASK_PAGE_MILESTONE_SET_DETAILS";
export const TASK_PAGE_USER_SET_DETAILS = "TASK_PAGE_USER_SET_DETAILS";
export const TASK_PAGE_USERS_INITIALIZE = "TASK_PAGE_USERS_INITIALIZE";

export const TASK_PAGE_PAGE_LOADED = "TASK_PAGE_PAGE_LOADED";
export const TASK_PAGE_CLOSE_ERROR_DIALOG = "TASK_PAGE_CLOSE_ERROR_DIALOG";

export const TASK_PAGE_DIALOG_SHOW_RESET = "TASK_PAGE_DIALOG_SHOW_RESET";
export const TASK_PAGE_DIALOG_CLOSE_RESET = "TASK_PAGE_DIALOG_CLOSE_RESET";
export const TASK_PAGE_DIALOG_SHOW_REMOVE = "TASK_PAGE_DIALOG_SHOW_REMOVE";
export const TASK_PAGE_DIALOG_CLOSE_REMOVE = "TASK_PAGE_DIALOG_CLOSE_REMOVE";