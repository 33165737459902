import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { UserModel } from 'models';
import * as Store from 'store';
import * as Users from 'store/Users';
import { bindActionCreators } from 'redux';
import { Orange, GrayLightLighter } from '../common/Colors';

interface AssigneesOwnProps {
    userIds: string[];
    userInfos: UserModel[];
}

type Props = AssigneesOwnProps & AssigneesComponentProps
    & typeof Users.actionCreators;

const fetchData = (props: Props) => {
    if (props.userIds !== null && props.userInfos.length < props.userIds.length) {
        props.userIds.forEach((userId) => {
            if (!props.userInfos.find(u => u.id === userId)) {
                props.requestUser(userId, false);
            }
        });
    }
}

// const getAbbreviation = (text: string) => {
//     return text.split(' ').map(t => t.charAt(0)).join('');
// }

const Assignees = (props: Readonly<Props>) => {
    React.useEffect(() => fetchData(props));

    return !!props.userInfos && (
        <React.Fragment>
            <StyledAssignee title={props.userInfos.length === 0 ? 'No assignee' : `${props.userInfos.length} assignee(s)\n${props.userInfos.map((u) => '- ' + (u.displayName || u.userName)).join('\n')}`}>
                {props.userInfos.length > 0 && <img src={props.userInfos[0].avatarUrl} alt="Assignee" />}
            </StyledAssignee>
            {props.userInfos.length > 1 && <StyledCount>{props.userInfos.length}</StyledCount>}
        </React.Fragment>
    )
}

const StyledAssignee = styled.div`
height: 30px;
width: 30px;
position: absolute;
top: 8px;
right: 8px;
border-radius: 50%;
overflow: hidden;
background-color: ${GrayLightLighter};

img {
    height: 30px;
    width: 30px;
}
`

const StyledCount = styled.div`
position: absolute;
right: 2px;
top: 25px;
background: ${Orange};
display: block;
width: 18px;
height: 18px;
text-align: center;
color: white;
border-radius: 9px;
font-size: 0.9em;
`

interface AssigneesComponentProps {
    taskId: string;
}

export default connect(
    (state: Store.ApplicationState, ownProps: AssigneesComponentProps | undefined) => {
        if (!ownProps) return {
            userIds: [],
            userInfos: []
        };

        var task = state.tasks.tasks[ownProps.taskId];
        var userIds = task.assignees ? task.assignees.map(a => a.userId) : [];

        // Move current user to the front
        if (state.login.userInfo) {
            let userId = state.login.userInfo.id;
            if (userIds.find(o => o === userId)) {
                userIds = userIds.filter(o => o !== userId)
                userIds.unshift(userId);
            }
        }

        var userInfos: UserModel[] = [];
        if (task.assignees && state.users.users) {
            userInfos = userIds.map(userId => state.users.users[userId]).filter(u => !!u);
        }
        var result: AssigneesOwnProps = {
            userIds: userIds,
            userInfos: userInfos,
        };
        return result;
    },
    dispatch => bindActionCreators({
        ...Users.actionCreators
    }, dispatch)
)(Assignees);