import * as React from 'react';
import StartDateEndDateDuration from 'components/common/StartDateEndDateDuration';
import DateTimeWithAccuracy from 'models/DateTimeWithAccuracy';
import SectionTitle from 'components/common/SectionTitle';
import SectionContent from 'components/common/SectionContent';

interface TaskPageDurationProps {
    startDate: DateTimeWithAccuracy | null;
    endDate: DateTimeWithAccuracy | null;
    durationSeconds: number | null;
    durationPercent: number;
    isStartDateCalculated: boolean;
    isEndDateCalculated: boolean;
    isDurationCalculated: boolean;
    dateTimeDescription: string;
}

export default (props: Readonly<TaskPageDurationProps>) => (
    <React.Fragment>
        <SectionTitle>DURATION</SectionTitle>
        <SectionContent>
            <StartDateEndDateDuration
                startDate={props.startDate}
                endDate={props.endDate}
                durationSeconds={props.durationSeconds}
                durationPercent={props.durationPercent}
                isStartDateLockVisible={props.isStartDateCalculated}
                isEndDateLockVisible={props.isEndDateCalculated}
                isDurationLockVisible={props.isDurationCalculated}
                dateTimeDescription={props.dateTimeDescription} />
        </SectionContent>
    </React.Fragment>
)