import * as React from 'react';
import styled from 'styled-components';
import { container } from 'inversify.config';
import { vSoftLoginClient, LoginResult } from 'logics/vSoftLoginClient';
import Error from 'components/Error';
import GetApp from 'components/GetApp';
import { AppColor, AppColorLight, AppColorDarkDark, AppColorDark } from 'common/Colors';

interface LoginDialogProps {
    onLogin: (result: LoginResult) => Promise<any>;
}

enum LoginDialogMode {
    SignIn,
    SignUp
}

interface LoginDialogState {
    mode: LoginDialogMode;

    username: string;
    fullname: string;
    password: string;
    confirmpassword: string;
    email: string;
    errors: string[] | null;
    isSigningIn?: boolean;
}

export default class LoginDialog extends React.Component<LoginDialogProps, LoginDialogState> {
    vSoftLoginClient: vSoftLoginClient;

    constructor(props: LoginDialogProps) {
        super(props);

        this.state = {
            mode: LoginDialogMode.SignIn,
            username: '',
            fullname: '',
            password: '',
            confirmpassword: '',
            email: '',
            errors: null
        };

        this.vSoftLoginClient = container.get<vSoftLoginClient>(vSoftLoginClient);
    }

    private async login() {
        this.setState({
            isSigningIn: true
        });

        try {
            var loginResult = await this.vSoftLoginClient.login(this.state.username, this.state.password);
            if (loginResult && loginResult.access_token) {
                this.setState({ errors: null });
                await this.props.onLogin(loginResult);
            } else if (loginResult && loginResult.error) {
                this.setState({ errors: [loginResult.error] });
            } else {
                this.setState({ errors: ["Unknown error!"] });
            }
        }
        catch (e) {
            this.setState({ errors: ['Cannot login! ' + e] });
        }
        finally {
            this.setState({
                isSigningIn: false
            });
        }
    }

    private async register() {
        this.setState({
            isSigningIn: true
        });

        try {
            await this.vSoftLoginClient.register(this.state.username, this.state.fullname, this.state.password, this.state.confirmpassword, this.state.email);

            this.setState({
                mode: LoginDialogMode.SignIn
            });

            await this.login();
        }
        catch (e) {
            this.setState({ errors: ['Cannot register new account! ' + e] });
        }
        finally {
            this.setState({
                isSigningIn: false
            });
        }
    }

    public render() {
        var signIn = <React.Fragment>
            <img src={(window.cdnPath ? (window.cdnPath + '/') : '') + 'img/Wide310x150Logo.scale-400.png'} style={{ maxWidth: '300px' }} alt="Project Studio" />

            <div style={{
                maxWidth: 500,
                margin: '0 auto',
                paddingLeft: 12,
                paddingRight: 12
            }}>
                <h4>Sign in to your <strong>vSoft Studio</strong> account</h4>
                {this.state.isSigningIn ?
                    <div>Signing in...</div>
                    :
                    <form id="form-login" className="form" onSubmit={e => { e.preventDefault(); this.login(); }}>
                        <input className="form-control" type="text" placeholder="Username or email" value={this.state.username} onChange={(e) => this.setState({ username: e.currentTarget.value })} required />
                        <input className="form-control" type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.currentTarget.value })} required />
                        <p style={{ textAlign: 'center' }}>By signing in, you have agreed to our <a href="https://projectstudio.online/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://projectstudio.online/terms/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a></p>
                        <div style={{ textAlign: 'center' }}>
                            <button type="submit" value="Sign In" className="btn btn-info"><span className="glyphicon glyphicon-lock"></span> SIGN IN</button>
                        </div>
                        <Error messages={this.state.errors} />
                    </form>
                }
            </div>

            <StyledSwitchButton onClick={e => this.setState({ mode: LoginDialogMode.SignUp })} className='btn btn-switch'>
                <span style={{ fontWeight: 'bold' }}>Don't have an account?</span><br />
                <span style={{ fontSize: '2em', fontWeight: 'lighter' }}>Sign Up</span>
            </StyledSwitchButton>
        </React.Fragment>

        var signUp = <React.Fragment>
            <div style={{
                maxWidth: 500,
                margin: '0 auto',
                paddingLeft: 12,
                paddingRight: 12
            }}>
                <div style={{ textAlign: 'right', paddingTop: 10 }}>
                    <img src="https://vsoftlogin.blob.core.windows.net/images/vSoft-66x29-cropped.png" alt="vSoft Studio" />
                </div>

                <h4 style={{ textAlign: 'left' }}>Sign up for a <strong>FREE</strong> vSoft Studio account</h4>
                {this.state.isSigningIn ?
                    <div>Signing up...</div>
                    :
                    <form id="form-login" className="form" onSubmit={e => { e.preventDefault(); this.register(); }}>
                        <input className="form-control" type="text" placeholder="Username" value={this.state.username} onChange={(e) => this.setState({ username: e.currentTarget.value })} required />
                        <input className="form-control" type="text" placeholder="Full Name" value={this.state.fullname} onChange={(e) => this.setState({ fullname: e.currentTarget.value })} required />
                        <input className="form-control" type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.currentTarget.value })} required />
                        <input className="form-control" type="password" placeholder="Confirm Password" value={this.state.confirmpassword} onChange={(e) => this.setState({ confirmpassword: e.currentTarget.value })} required />
                        <input className="form-control" type="email" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({ email: e.currentTarget.value })} required />
                        <p>By signing up, you have agreed to our <a href="https://projectstudio.online/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://projectstudio.online/terms/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a></p>
                        <button type="submit" value="Sign Up" className="btn btn-info"><span className="glyphicon glyphicon-lock"></span>SIGN UP</button>
                        <Error messages={this.state.errors} />
                    </form>
                }
            </div>

            <StyledSwitchButton onClick={e => this.setState({ mode: LoginDialogMode.SignIn })} className='btn btn-switch'>
                <span style={{ fontWeight: 'bold' }}> Already registered?</span><br />
                <span style={{ fontSize: '2em', fontWeight: 'lighter' }}>Sign In</span>
            </StyledSwitchButton>
        </React.Fragment>

        return (
            <StyledBackground>
                <StyledWrapper>
                    <div className="alert alert-warning">This website is under development and current support read-only view of the tasks you created in the Windows app.</div>
                    <StyledPanel>
                        {this.state.mode === LoginDialogMode.SignIn ? signIn : signUp}
                    </StyledPanel>
                    <StyledGetApp>
                        <p style={{ textAlign: 'center' }}> Get more features from our Windows app!</p>
                        <GetApp />
                    </StyledGetApp>
                </StyledWrapper>
            </StyledBackground>
        );
    }
}

const StyledBackground = styled.div`
position: absolute;
z-index: 100000;
background-color: #333;
top: 0;
left: 0;
right: 0;
display: table;
width: 100%;
min-height: 100%;
`

const StyledWrapper = styled.div`
display: table-cell;
vertical-align: middle;
margin: 40px 0;
`;

const StyledPanel = styled.div`
background-color: ${AppColor};
font-weight: lighter;
text-align: center;

a {
    color: #ffee58;
    text-decoration: underline;
}

input[type=text], input[type=password], input[type=email] {
    background-color: ${AppColorLight};
    border: none;
    color: black;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 1.1em;

    ::placeholder {
        color: #444;
    }
}
`

const StyledSwitchButton = styled.button`
width: 100%;
color: white;
background-color: ${AppColorDarkDark};
margin-top: 20px;
padding: 10px;
:hover {
    color: white;
    background-color: ${AppColorDark};
}
`;

const StyledGetApp = styled.div`
max-width: 500px;
margin: 0 auto;
.ms-product-badge {
    display: block;
}
`;