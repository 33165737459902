import * as React from 'react';
import styled from 'styled-components';
import { ProjectModel, TaskModel, TaskStage } from 'models';

interface TaskListItemCheckboxProps {
    task: TaskModel;
    project: ProjectModel | null;
}

export default (props: Readonly<TaskListItemCheckboxProps>) => {
    if (props.task.taskStage === TaskStage.Todo) {
        return <StyledCheckbox className="todo"></StyledCheckbox>;
    } else if (props.task.taskStage === TaskStage.Done) {
        return <StyledCheckbox className="done"></StyledCheckbox>;
    } else if (props.project) {
        var stage = props.project.customStages.find(s => s.id === props.task.customStageId);
        if (stage) {
            return <StyledCheckbox className="custom">{stage.name.charAt(0)}</StyledCheckbox>;
        } else {
            return <StyledCheckbox className="todo"></StyledCheckbox>;
        }
    }
    return null;
}

const StyledCheckbox = styled.div`
display: block;
width: 34px;
height: 34px;
line-height: 30px;
font-size: 20px;
border-width: 2px;
float: left;
border: 2px solid #1976d2;
margin: 2px 0;
padding: 0;
text-align: center;

&.todo {
}

&.done {
    border-color: #388e3c;
    color: #388e3c;
    content: "&#xE73E";
    font-family: "Segoe MDL2 Assets", SegoeMDL2;
}

&.custom {
    color: #fb8c00;
    border-color: #fb8c00;
}
`;