export interface BaseModel {
    id: string;
    creatorId?: string;

    addedDateTime: Date;
    updatedDateTime: Date;
}

export interface BaseSoftDeleteModel extends BaseModel {
    isDeleted: boolean;
}

export interface UserProfileModel extends BaseModel {
    lastSeenNotification: Date;
    premiumExpiration: Date;
    isPremium: boolean;
}

export interface ProjectModel extends BaseSoftDeleteModel {
    title: string;
    shortDescription: string;

    color: string;
    icon: string;

    startTime: Date | null;
    startTimeAccuracy: TimeAccuracy;
    endTime: Date | null;
    endTimeAccuracy: TimeAccuracy;

    status: ProjectStatus;
    customStages: TaskStageModel[];
}

export interface TaskGroupModel extends BaseSoftDeleteModel {
    projectId: string;
    name: string;
    order: number;
}

export interface MilestoneModel extends BaseSoftDeleteModel {
    projectId: string;
    name: string;
}

export interface TaskModel extends BaseSoftDeleteModel {
    projectId?: string;
    taskGroupId?: string;
    milestoneId?: string;

    description: string;
    order?: number;

    taskStage: TaskStage;
    customStageId?: number;
    subStage?: TaskSubStage;

    priority: TaskPriority;

    startTime?: Date;
    startTimeAccuracy?: TimeAccuracy;
    endTime?: Date;
    endTimeAccuracy?: TimeAccuracy;
    duration?: number | string;

    timeReminders?: TimeReminderModel[];

    assignees?: AssigneeModel[];
    notes?: string;
    subTasks?: ChecklistItemModel[];
    tags?: string[];
}

export interface UserModel {
    id: string;
    userName: string;
    fullName: string;
    displayName: string;
    email: string;
    avatarUrl: string;
    nameInitials?: string;
    color?: string;
}

export interface AssigneeModel {
    userId: string;
    email: string;
}

export interface MembershipModel extends BaseModel {
    userId: string;
    projectId: string;
    taskGroupId: string;

    email: string;
    role: Role;
    state: MemberState;

    acceptedDateTime: Date;
}

export interface MembershipDetailsModel extends BaseModel {
    projectTitle: string;
    groupName?: string;

    role: string;
    currentRole: string;
    state: string;
}

export interface TaskStageModel {
    id: number;     // Id need to be >= 0 as TODO: -2, DONE: -1 (default choices)
    stage: TaskStage;
    name: string;
    icon?: string;
    useSubStage?: boolean;
}

export interface TimeReminderModel {
    reminderTime: Date;
    notes: string;
}

export interface ChecklistItemModel {
    description: string;
    isCompleted: boolean;
}

export enum TimeAccuracy {
    Time = "Time",
    Day = "Day",
    Month = "Month",
    Year = "Year"
}

export enum DateStatus {
    NotAvailable = "NotAvailable",
    Done = "Done",
    Overdue = "Overdue",
    DueToday = "DueToday",
    DueSoon = "DueSoon",
    DueFuture = "DueFuture",
    Started = "Started",
    StartToday = "StartToday",
    StartSoon = "StartSoon",
    StartFuture = "StartFuture",
    DurationOnly = "DurationOnly",
    NoDate = "NoDate",
    InProgress = "InProgress",
    Delayed = "Delayed",
    Cancelled = "Cancelled",
    Default = "Default",
}

export enum TaskStage {
    Todo = "Todo",
    Custom = "Custom",
    Done = "Done"
}

export enum TaskSubStage {
    Doing = "Doing",
    Done = "Done"
}

export enum TaskPriority {
    Low = -1,
    Mid = 0,
    High = 1
}

export enum ProjectStatus {
    Active = "Active",
    Closed = "Closed",
    Delayed = "Delayed",
    Cancelled = "Cancelled"
}

export enum Role {
    Writer = "Writer",
    Reader = "Reader",
    Owner = "Owner"
}

export enum MemberState {
    Accepted = "Accepted",
    Pending = "Pending",
}

export class ModelConstants {
    static readonly TaskStageToDoId: number = -2;
    static readonly TaskStageDoneId: number = -1;
    static readonly TaskStageToDoName: string = "To Do";
    static readonly TaskStageDoneName: string = "Done";
}