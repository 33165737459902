import * as React from 'react';
import styled from 'styled-components';
import { TaskStage, TaskStageModel } from 'models';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Gray, GrayLight, GrayDark, Blue, BlueLight, BlueDark, Orange, Green, OrangeLight, OrangeDark, GreenLight, GreenDark } from 'common/Colors';

interface TaskPageStageProps {
    stage: TaskStage;
    customStageId?: number;
    availableStages: TaskStageModel[];
    handleStageChange: (stage: TaskStage, customStageId?: number) => void;
}

export default (props: Readonly<TaskPageStageProps>) => (
    <StyledList>
        {props.availableStages.map((stage, index) => {
            let stageStage = stage.stage;
            let stageClassName = "";

            if (stageStage === TaskStage.Done) {
                stageClassName = "done";
            }
            else if (stageStage === TaskStage.Custom) {
                stageClassName = "custom";
            }
            else if (stageStage === TaskStage.Todo) {
                stageClassName = "todo";
            }

            let isStageSelected = false;

            if ((props.stage !== TaskStage.Custom && props.stage === stageStage)
                || (props.stage === TaskStage.Custom && props.stage === stageStage && props.customStageId === stage.id)) {
                isStageSelected = true;
            }

            return <div key={index} className={"task-stage " + stageClassName + (isStageSelected ? " selected" : "")}>
                <svg className="mid-arrow-start"><path d="M0 0 L12 12 L0 24 L 14 24 L 14 0 Z" /></svg>
                <DefaultButton className="btn-background-transparent"
                    onClick={() => {
                        props.handleStageChange(stageStage, stageStage === TaskStage.Custom ? stage.id : undefined);
                    }}>
                    {stage.name}
                </DefaultButton>
                <svg className="mid-arrow-end"><path d="M0 0 L2 0 L14 12 L2 24 L0 24 Z" /></svg>
            </div>
        })
        }
    </StyledList>
)

const StyledList = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
overflow-x: auto;
margin-right: -24px;
padding-right: 24px;

.task-stage {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 0 0 auto;
}

.task-stage svg, .task-stage button {
    height: 24px;
}

.task-stage svg {
    fill: ${Gray};
    width: 14px;
    flex: 0 0 auto;
}

.task-stage:hover svg {
    fill: ${GrayLight};
}

.task-stage:active svg {
    fill: ${GrayDark};
}

.task-stage svg.mid-arrow-start {
    margin-left: -10px;
    margin-right: -2px;
}

.task-stage svg.mid-arrow-end {
    margin-left: -2px;
}

.task-stage:first-child svg.mid-arrow-start {
    display: none;
}

.task-stage:last-child svg.mid-arrow-end {
    display: none;
}

.task-stage.todo.selected svg {
    fill: ${Blue};
}

.task-stage.todo.selected:hover svg {
    fill: ${BlueLight};
}

.task-stage.todo.selected:active svg {
    fill: ${BlueDark};
}

.task-stage.custom.selected svg {
    fill: ${Orange};
}

.task-stage.custom.selected:hover svg {
    fill: ${OrangeLight};
}

.task-stage.custom.selected:active svg {
    fill: ${OrangeDark};
}

.task-stage.done.selected svg {
    fill: ${Green};
}

.task-stage.done.selected:hover svg {
    fill: ${GreenLight};
}

.task-stage.done.selected:active svg {
    fill: ${GreenDark};
}

.task-stage button {
    background: ${Gray};
    color: white;
    padding: 2px 8px 2px 8px;
    font-weight: normal;
    flex: 0 0 auto;

    &:hover {
        background: ${GrayLight};
    }

    &:active {
        background: ${GrayDark};
    }
}

.task-stage button .ms-Button-label {
    font-weight: normal !important;
}

.task-stage.todo.selected button {
    background: ${Blue};

    &:hover {
        background: ${BlueLight};
    }

    &:active {
        background: ${BlueDark};
    }
}

.task-stage.custom.selected button {
    background: ${Orange};

    &:hover {
        background: ${OrangeLight};
    }

    &:active {
        background: ${OrangeDark};
    }
}

.task-stage.done.selected button {
    background: ${Green};

    &:hover {
        background: ${GreenLight};
    }

    &:active {
        background: ${GreenDark};
    }
`;