import * as React from 'react';
import { ProjectModel, MilestoneModel } from 'models';
import Colors from 'common/Colors';
import FontIcons from 'common/FontIcons';
import { DefaultButton } from '@fluentui/react/lib/Button';
import SectionTitle from 'components/common/SectionTitle';

interface TaskPageMilestoneProps {
    project: ProjectModel | null;
    milestone: MilestoneModel | null;
}

export default (props: Readonly<TaskPageMilestoneProps>) => (
    props.project &&
    <React.Fragment>
        <SectionTitle>MILESTONE</SectionTitle>
        {props.milestone
            ?
            <DefaultButton className="btn-task-milestone" style={{ color: props.project.color || Colors.colorApp }}>
                <span className="task-milestone-icon" style={{ background: props.project.color || Colors.colorApp }}>{FontIcons.iconMilestone}</span>
                {props.milestone.name}
            </DefaultButton>
            :
            <DefaultButton className="btn-with-icon-item-page text-align-left">
                <span className="btn-with-icon-item-page-icon">{FontIcons.iconAdd}</span>
                Add to milestone
            </DefaultButton>
        }
    </React.Fragment>
)