import { injectable } from "inversify";
import { ServiceConstants } from './ServiceConstants';

@injectable()
export class vSoftLoginClient {
    private CACHE_KEY = "vSoftLogin.login";

    clientId: string;
    baseUrl: string;

    constructor() {
        this.baseUrl = ServiceConstants.VSOFTLOGIN_BASEURL;
        this.clientId = ServiceConstants.VSOFTLOGIN_CLIENTID;
    }

    async getUserInfo(): Promise<UserInfo> {
        var login = this.loginFromCache();
        if (login) {
            var headers = new Headers();
            headers.append('Authorization', 'Bearer ' + login.access_token);

            var response = await fetch(this.baseUrl + "/api/Account/UserInfo", {
                method: 'get',
                mode: 'cors',
                headers: headers
            });
            if (response.ok) {
                return await response.json();
            } else if (response.status === 401) {
                // Try to refresh
                login = await this.refresh(login);
                if (login && login.access_token) {
                    return await this.getUserInfo();
                } else {
                    throw new AuthenticationException('Login expired!');
                }
            }
        }
        throw new AuthenticationException("No cache information");
    }

    cachedUserInfo: UserInfo[] = [];

    async getUserInfoById(userId: string): Promise<UserInfo> {
        var existing = this.cachedUserInfo.find(u => u.Id === userId);
        if (existing) return existing;

        var response = await fetch(this.baseUrl + "/api/Account/UserInfo/" + userId, {
            method: 'get',
            mode: 'cors'
        });
        if (response.ok) {
            var userInfo = await response.json();
            this.cachedUserInfo.push(userInfo);
            return userInfo;
        } else {
            throw new Error(await response.text());
        }
    }

    async register(userName: string, displayName: string, password: string, confirmPassword: string, email: string): Promise<void> {
        var data = new URLSearchParams();
        data.append('DisplayName', displayName);
        data.append('UserName', userName);
        data.append('Password', password);
        data.append('ConfirmPassword', confirmPassword);
        data.append('EmailAddress', email);

        try {
            var headers = new Headers();
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/x-www-form-urlencoded');

            var response = await fetch(this.baseUrl + "/api/Account/Register", {
                method: 'post',
                headers: headers,
                body: data.toString(),
                mode: 'cors'
            });
            if (response.ok) {
                // Nothing
            } else {
                var error = await response.json();
                throw new Error(error.error_description);
            }
        }
        catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message);
            } else {
                throw new Error(e);
            }
        }
    }

    async login(username: string, password: string): Promise<LoginResult> {
        var data = new URLSearchParams();
        data.append('username', username);
        data.append('password', password);
        data.append('grant_type', 'password');
        data.append('client_id', this.clientId);

        return await this.loginCommon(data);
    }

    loginFromCache(): LoginResult | null {
        if (localStorage) {
            var loginString = localStorage.getItem(this.CACHE_KEY);
            if (loginString) {
                return JSON.parse(loginString) as LoginResult;
            }
        }
        return null;
    }

    private async loginCommon(data: URLSearchParams): Promise<LoginResult> {
        try {
            var headers = new Headers();
            headers.append('Accept', 'application/json');

            var response = await fetch(this.baseUrl + "/Token", {
                method: 'post',
                headers: headers,
                body: data.toString(),
                mode: 'cors'
            });
            if (response.ok) {
                var loginResult = await response.json() as LoginResult;
                // Save login result
                if (localStorage) {
                    var dataString = JSON.stringify(loginResult);
                    localStorage.setItem(this.CACHE_KEY, dataString);
                }
                return loginResult;
            } else {
                this.logout();
                var error = await response.json();
                return {
                    error: error.error_description
                };
            }
        }
        catch (e) {
            if (e instanceof Error) {
                return {
                    error: e.message
                };
            } else {
                return {
                    error: e
                };
            }
        }
    }

    async refresh(loginResult: LoginResult): Promise<LoginResult> {
        if (loginResult.refresh_token) {
            var data = new URLSearchParams();
            data.append('refresh_token', loginResult.refresh_token);
            data.append('grant_type', 'refresh_token');
            data.append('client_id', this.clientId);

            return await this.loginCommon(data);
        } else {
            return {
                error: 'No cached credential'
            };
        }
    }

    logout() {
        if (localStorage) {
            localStorage.removeItem(this.CACHE_KEY);
        }
    }
}

export interface UserInfo {
    Id: string;
    UserName: string;
    DisplayName: string;
    Email: string;
    EmailConfirmed: boolean;
    PhoneNumber: string;
    AvatarUrl: string;
    RegisteredUtcDateTime: Date;
    LastModifiedDateTime: Date;
}

export interface LoginResult {
    access_token?: string;
    expires_in?: number;
    refresh_token?: string;
    token_type?: string;
    error?: string;
}

export class AuthenticationException {
    message: string;

    constructor(message: string) {
        this.message = message;
    }
}

declare class URLSearchParams {
    append(key: string, value: string): void;
}