import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router'
import * as Store from 'store';
import StyledPageTitle from 'components/common/StyledPageTitle';

interface LinkPageRouteComponentProps {
    type: string;
    id: string;
}

type LinkPageProps = Store.ApplicationState
    & RouteComponentProps<LinkPageRouteComponentProps>;

class LinkPage extends React.Component<LinkPageProps> {
    public render() {
        return (
            <React.Fragment>
                <StyledPageTitle>Link</StyledPageTitle>
                {
                    (this.props.match.params.type === "task" || this.props.match.params.type === "tasks") ?
                        <div>
                            <Redirect to={'/tasks/' + this.props.match.params.id} />
                        </div>
                        : ((this.props.match.params.type === "project" || this.props.match.params.type === "projects") ?
                            <Redirect to={'/projects/' + this.props.match.params.id} />
                            :
                            <p>Invalid link!</p>
                        )
                }
            </React.Fragment>

        );
    }
}

export default connect(
    (state: Store.ApplicationState) => state
)(LinkPage);